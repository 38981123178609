/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CreateOrderDetailDto } from './CreateOrderDetailDto';
import type { CreatePaymentDto } from './CreatePaymentDto';
import type { CreateTaxPaymentDto } from './CreateTaxPaymentDto';
import type { CreateTransactionDto } from './CreateTransactionDto';

export type UpdateOrderDto = {
    orderDate?: string;
    requiredDate?: string;
    totalBill?: number;
    pendingPayment?: number;
    salesTax?: string;
    billDate?: string;
    shipDate?: string;
    shipCharge?: number;
    transactionStatus?: UpdateOrderDto.transactionStatus;
    numberOfInstallmentsApplicable?: number;
    paymentCompletionDate?: string;
    originatingCartId?: number;
    preferredShipperId?: number;
    customerId?: number;
    customerAddressId?: number;
    madePayments?: Array<CreatePaymentDto>;
    taxPayments?: Array<CreateTaxPaymentDto>;
    orderDetails?: Array<CreateOrderDetailDto>;
    transactionEntry?: CreateTransactionDto;
    madeDiscount?: Array<any>;
    /**
     * Array of coupon ids to use
     */
    couponUsedIds?: Array<number>;
    /**
     * Array of coupon codes to use
     */
    couponUsedCodes?: Array<string>;
    /**
     * Default is false
     * Only set to true if due payments have been made
     */
    shippingEnabled?: boolean;
};

export namespace UpdateOrderDto {

    export enum transactionStatus {
        UNPAID = 'Unpaid',
        PART_PAID = 'Part Paid',
        PAID = 'Paid',
        CANCELED = 'Canceled',
        FULFILLED = 'Fulfilled',
    }


}

