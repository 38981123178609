/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateShipperDto } from '../models/CreateShipperDto';
import type { Shipper } from '../models/Shipper';
import type { ShippersAndCount } from '../models/ShippersAndCount';
import type { UpdateShipperDto } from '../models/UpdateShipperDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

import { generalVersionUrlPrefix } from '../../../components/app.settings';

export class ShippersService {

    /**
     * Create a new shipper
     * @param accessToken
     * @param requestBody
     * @returns Shipper :Shipper created
     * @throws ApiError
     */
    public static shippersControllerCreate(
        accessToken: string | undefined = undefined,
        requestBody: CreateShipperDto,
    ): CancelablePromise<Shipper> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/shippers',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a shipper. Fully replaces all fields
     * @param accessToken
     * @param requestBody
     * @returns Shipper :Shipper saved
     * @throws ApiError
     */
    public static shippersControllerSave(
        accessToken: string | undefined = undefined,
        requestBody: Shipper,
    ): CancelablePromise<Shipper> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: generalVersionUrlPrefix + '/shippers',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all shippers that meet the criteria specified in query options, if any.
     * @param accessToken
     * @param findOptions encodeURI(JSON.stringify({select?: string[]; relations?: string[]; skip?: number; take?: number;cache?: boolean; where?: {}[] | {}; order?: {};))
     * @returns ShippersAndCount Get all shippers that meet the criteria specified in query options, if any. Returns array of items found, and count
     * @throws ApiError
     */
    public static shippersControllerFindAll(
        accessToken: string | undefined = undefined,
        findOptions?: any,
    ): CancelablePromise<ShippersAndCount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/shippers',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'findOptions': findOptions,
            },
            errors: {
                400: `Bad request: likely incorrect options sent`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create one or more new shippers in one go
     * @param accessToken
     * @param requestBody
     * @returns any Shippers have been successfully created.
     * @throws ApiError
     */
    public static shippersControllerInsert(
        accessToken: string | undefined = undefined,
        requestBody: Array<string>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/shippers/insert',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a shipper. Only the fields sent from client will be updated
     * @param id
     * @param accessToken
     * @param requestBody
     * @returns any Shipper has been successfully updated.
     * @throws ApiError
     */
    public static shippersControllerUpdate(
        id: string,
        accessToken: string | undefined = undefined,
        requestBody: UpdateShipperDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/shippers/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete a shipper.
     * @param id
     * @param accessToken
     * @returns any Shipper has been successfully deleted.
     * @throws ApiError
     */
    public static shippersControllerDelete(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/shippers/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                400: `Bad request: likely shipper does not exist`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a shipper with the id sent as param
     * @param id
     * @param accessToken
     * @returns Shipper :Shipper found
     * @throws ApiError
     */
    public static shippersControllerFindOne(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<Shipper> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/shippers/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * Add shipper preferring order to shipper
     * @param shipperId
     * @param orderId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static shippersControllerAddShipperPreferringOrderById(
        shipperId: string,
        orderId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/shippers/{shipperId}/shipper-preferring-order/{orderId}',
            path: {
                'shipperId': shipperId,
                'orderId': orderId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Remove shipper preferring order from shipper
     * @param shipperId
     * @param orderId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static shippersControllerRemoveShipperPreferringOrderById(
        shipperId: string,
        orderId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/shippers/{shipperId}/shipper-preferring-order/{orderId}',
            path: {
                'shipperId': shipperId,
                'orderId': orderId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Add shipment to shipper
     * @param shipperId
     * @param shipmentId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static shippersControllerAddShipmentById(
        shipperId: string,
        shipmentId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/shippers/{shipperId}/shipment/{shipmentId}',
            path: {
                'shipperId': shipperId,
                'shipmentId': shipmentId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Remove shipment from shipper
     * @param shipperId
     * @param shipmentId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static shippersControllerRemoveShipmentById(
        shipperId: string,
        shipmentId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/shippers/{shipperId}/shipment/{shipmentId}',
            path: {
                'shipperId': shipperId,
                'shipmentId': shipmentId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

}
