/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LocalAuthDto } from '../models/LocalAuthDto';
import type { RefreshAuthDto } from '../models/RefreshAuthDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

import { generalVersionUrlPrefix } from '../../../components/app.settings';

export class AuthService {

    /**
     * Below is a login form invoked when username and password need to be supplied in a Web from, for login from browser
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static authControllerLoginForm(
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/auth/login',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * This route is for ajax login Post call.
     * e.g. curl -X POST http://localhost:3006/v1/auth/login -d '{"username": "piusono@gmail.com", "password": "password"}' -H "Content-Type: application/json"
     * or from fetch() or axios(), etc.
     * On success here, redirect is handled by the client
     * @param accessToken
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static authControllerLogin(
        accessToken: string | undefined = undefined,
        requestBody: LocalAuthDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/auth/login',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * This route is for refresh calls only
     * @param accessToken
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static authControllerRefresh(
        accessToken: string | undefined = undefined,
        requestBody: RefreshAuthDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/auth/refresh',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param accessToken
     * @returns string
     * @throws ApiError
     */
    public static authControllerTest(
        accessToken: string | undefined = undefined,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/auth/test',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static authControllerGoogleLogin(
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/auth/google',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static authControllerGoogleLoginRedirect(
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/auth/google/redirect',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static authControllerFacebookLogin(
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/auth/facebook',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static authControllerFacebookLoginRedirect(
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/auth/facebook/redirect',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Ajax called. Return URL to redirect to
     * @param accessToken
     * @param body
     * @returns any
     * @throws ApiError
     */
    public static authControllerLogout(
        accessToken: string | undefined = undefined,
        body: { clientDeviceFingerPrint: string | null }
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/auth/logout',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body
            ,
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static authControllerLogoutGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/auth/logout',
        });
    }

}
