/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateCustomerAddressDto } from '../models/CreateCustomerAddressDto';
import type { CustomerAddress } from '../models/CustomerAddress';
import type { UpdateCustomerAddressDto } from '../models/UpdateCustomerAddressDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

import { generalVersionUrlPrefix } from '../../../components/app.settings';

export class CustomerAddressesService {

    /**
     * Create a new tax region
     * @param accessToken
     * @param requestBody
     * @returns CustomerAddress Customer address created
     * @returns any Address has been successfully created.
     * @throws ApiError
     */
    public static customerAddressesControllerCreate(
        accessToken: string | undefined = undefined,
        requestBody: CreateCustomerAddressDto,
    ): CancelablePromise<CustomerAddress | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/customer-addresses',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update an address. Fully replaces all fields
     * @param accessToken
     * @param requestBody
     * @returns any Address has been successfully updated.
     * @throws ApiError
     */
    public static customerAddressesControllerSave(
        accessToken: string | undefined = undefined,
        requestBody: CustomerAddress,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: generalVersionUrlPrefix + '/customer-addresses',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all addresses that meet the criteria specified in query options, if any.
     * @param accessToken
     * @param findOptions encodeURI(JSON.stringify({select?: string[]; relations?: string[]; skip?: number; take?: number;cache?: boolean; where?: {}[] | {}; order?: {};))
     * @returns any Addresses returned.
     * @throws ApiError
     */
    public static customerAddressesControllerFindAll(
        accessToken: string | undefined = undefined,
        findOptions?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/customer-addresses',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'findOptions': findOptions,
            },
            errors: {
                400: `Bad request: likely incorrect options sent`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create one or more new addresses in one go
     * @param accessToken
     * @param requestBody
     * @returns any Addresses have been successfully created.
     * @throws ApiError
     */
    public static customerAddressesControllerInsert(
        accessToken: string | undefined = undefined,
        requestBody: Array<string>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/customer-addresses/insert',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update an address. Only the fields sent from client will be updated
     * @param id
     * @param accessToken
     * @param requestBody
     * @returns any Address has been successfully updated.
     * @throws ApiError
     */
    public static customerAddressesControllerUpdate(
        id: string,
        accessToken: string | undefined = undefined,
        requestBody: UpdateCustomerAddressDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/customer-addresses/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete an address.
     * @param id
     * @param accessToken
     * @returns any Address has been successfully deleted.
     * @throws ApiError
     */
    public static customerAddressesControllerDelete(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/customer-addresses/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                400: `Bad request: likely address does not exist`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get an address with the id sent as param
     * @param id
     * @param accessToken
     * @returns any Address returned.
     * @throws ApiError
     */
    public static customerAddressesControllerFindOne(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/customer-addresses/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param customerAddressId
     * @param taxRegionId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static customerAddressesControllerSetTaxRegionById(
        customerAddressId: string,
        taxRegionId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/customer-addresses/{customerAddressId}/tax-region/{taxRegionId}',
            path: {
                'customerAddressId': customerAddressId,
                'taxRegionId': taxRegionId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * @param customerAddressId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static customerAddressesControllerUnsetTaxRegionById(
        customerAddressId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/customer-addresses/{customerAddressId}/tax-region',
            path: {
                'customerAddressId': customerAddressId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Get customer adddress along with tax region, by Id
     * @param customerId
     * @param accessToken
     * @returns CustomerAddress
     * @throws ApiError
     */
    public static customerAddressesControllerGetCustomerAddressesWithTaxRegionByCustomerId(
        customerId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<Array<CustomerAddress>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/customer-addresses/customer-addresses-with-tax-region-by-customer-id/{customerId}',
            path: {
                'customerId': customerId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Set customer address as default for a given customer
     * @param customerAddressId
     * @param customerId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static customerAddressesControllerSetCustomerAddressAsDefaultForCustomer(
        customerAddressId: string,
        customerId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/customer-addresses/{customerAddressId}/set-as-default-for-customer/{customerId}',
            path: {
                'customerAddressId': customerAddressId,
                'customerId': customerId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

}
