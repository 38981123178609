/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateLoyaltyStatusDto } from '../models/CreateLoyaltyStatusDto';
import type { LoyaltyStatus } from '../models/LoyaltyStatus';
import type { LoyaltyStatusesAndCount } from '../models/LoyaltyStatusesAndCount';
import type { UpdateLoyaltyStatusDto } from '../models/UpdateLoyaltyStatusDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

import { generalVersionUrlPrefix } from '../../../components/app.settings';

export class LoyaltyStatusesService {

    /**
     * Create a new loyaltyStatus
     * @param accessToken
     * @param requestBody
     * @returns LoyaltyStatus Loyalty status created
     * @throws ApiError
     */
    public static loyaltyStatusesControllerCreate(
        accessToken: string | undefined = undefined,
        requestBody: CreateLoyaltyStatusDto,
    ): CancelablePromise<LoyaltyStatus> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/loyalty-statuses',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a loyaltyStatus. Fully replaces all fields
     * @param accessToken
     * @param requestBody
     * @returns LoyaltyStatus Loyalty status saved
     * @throws ApiError
     */
    public static loyaltyStatusesControllerSave(
        accessToken: string | undefined = undefined,
        requestBody: LoyaltyStatus,
    ): CancelablePromise<LoyaltyStatus> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: generalVersionUrlPrefix + '/loyalty-statuses',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all loyaltyStatuses that meet the criteria specified in query options, if any.
     * @param accessToken
     * @param findOptions encodeURI(JSON.stringify({select?: string[]; relations?: string[]; skip?: number; take?: number;cache?: boolean; where?: {}[] | {}; order?: {};}))
     * @returns LoyaltyStatusesAndCount Get all loyalty statuses that meet the criteria specified in query options, if any. Returns array of items found, and count
     * @throws ApiError
     */
    public static loyaltyStatusesControllerFindAll(
        accessToken: string | undefined = undefined,
        findOptions?: any,
    ): CancelablePromise<LoyaltyStatusesAndCount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/loyalty-statuses',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'findOptions': findOptions,
            },
            errors: {
                400: `Bad request: likely incorrect options sent`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create one or more new loyaltyStatuses in one go
     * @param accessToken
     * @param requestBody
     * @returns any LoyaltyStatuss have been successfully created.
     * @throws ApiError
     */
    public static loyaltyStatusesControllerInsert(
        accessToken: string | undefined = undefined,
        requestBody: Array<string>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/loyalty-statuses/insert',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a loyaltyStatus. Only the fields sent from client will be updated
     * @param id
     * @param accessToken
     * @param requestBody
     * @returns any LoyaltyStatus has been successfully updated.
     * @throws ApiError
     */
    public static loyaltyStatusesControllerUpdate(
        id: string,
        accessToken: string | undefined = undefined,
        requestBody: UpdateLoyaltyStatusDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/loyalty-statuses/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete a loyaltyStatus.
     * @param id
     * @param accessToken
     * @returns any LoyaltyStatus has been successfully deleted.
     * @throws ApiError
     */
    public static loyaltyStatusesControllerDelete(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/loyalty-statuses/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                400: `Bad request: likely loyaltyStatus does not exist`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a loyaltyStatus with the id sent as param
     * @param id
     * @param accessToken
     * @returns LoyaltyStatus Loyalty status found
     * @throws ApiError
     */
    public static loyaltyStatusesControllerFindOne(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<LoyaltyStatus> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/loyalty-statuses/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * Add applicable bank credit facility to loyalty status
     * @param loyaltyStatusId
     * @param bankCreditFacilityId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static loyaltyStatusesControllerAddApplicableBankCreditFacilities(
        loyaltyStatusId: string,
        bankCreditFacilityId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/loyalty-statuses/{loyaltyStatusId}/applicable-bank-credit-facilities/{bankCreditFacilityId}',
            path: {
                'loyaltyStatusId': loyaltyStatusId,
                'bankCreditFacilityId': bankCreditFacilityId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Remove applicable bank credit facility from loyalty status
     * @param loyaltyStatusId
     * @param bankCreditFacilityId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static loyaltyStatusesControllerRemoveApplicableBankCreditFacilities(
        loyaltyStatusId: string,
        bankCreditFacilityId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/loyalty-statuses/{loyaltyStatusId}/applicable-bank-credit-facilities/{bankCreditFacilityId}',
            path: {
                'loyaltyStatusId': loyaltyStatusId,
                'bankCreditFacilityId': bankCreditFacilityId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Add loyalty benefits to which loyalty status is automatically applied
     * @param loyaltyStatusId
     * @param loyaltyBenefitId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static loyaltyStatusesControllerAddAutomaticallyAppliedToWhichLoyaltyBenefits(
        loyaltyStatusId: string,
        loyaltyBenefitId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/loyalty-statuses/{loyaltyStatusId}/automatically-applied-to-which-loyalty-benefits/{loyaltyBenefitId}',
            path: {
                'loyaltyStatusId': loyaltyStatusId,
                'loyaltyBenefitId': loyaltyBenefitId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Remove loyalty benefit from automatic application of loyalty status
     * @param loyaltyStatusId
     * @param loyaltyBenefitId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static loyaltyStatusesControllerRemoveAutomaticallyAppliedToWhichLoyaltyBenefits(
        loyaltyStatusId: string,
        loyaltyBenefitId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/loyalty-statuses/{loyaltyStatusId}/automatically-applied-to-which-loyalty-benefits/{loyaltyBenefitId}',
            path: {
                'loyaltyStatusId': loyaltyStatusId,
                'loyaltyBenefitId': loyaltyBenefitId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Add loyalty benefit to which loyalty status is manually applied by merchant
     * @param loyaltyStatusId
     * @param loyaltyBenefitId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static loyaltyStatusesControllerAddMerchantManuallyAppliedToWhichLoyaltyBenefits(
        loyaltyStatusId: string,
        loyaltyBenefitId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/loyalty-statuses/{loyaltyStatusId}/merchant-manually-applied-to-which-loyalty-benefits/{loyaltyBenefitId}',
            path: {
                'loyaltyStatusId': loyaltyStatusId,
                'loyaltyBenefitId': loyaltyBenefitId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Remove loyalty benefit from merchant manually applied loyalty status
     * @param loyaltyStatusId
     * @param loyaltyBenefitId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static loyaltyStatusesControllerRemoveMerchantManuallyAppliedToWhichLoyaltyBenefits(
        loyaltyStatusId: string,
        loyaltyBenefitId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/loyalty-statuses/{loyaltyStatusId}/merchant-manually-applied-to-which-loyalty-benefits/{loyaltyBenefitId}',
            path: {
                'loyaltyStatusId': loyaltyStatusId,
                'loyaltyBenefitId': loyaltyBenefitId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

}
