/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Payment } from './Payment';

export type PaymentMethod = {
    name: string;
    paymentMethodType: PaymentMethod.paymentMethodType;
    description: string;
    isActive: boolean;
    currenciesSupported: Array<string>;
    handler: string;
    isLocked: boolean;
    payments: Array<Payment>;
    id: number;
    dateCreated?: string;
    dateLastModified?: string;
};

export namespace PaymentMethod {

    export enum paymentMethodType {
        DEBIT_OR_CREDIT_CARD = 'debit or credit card',
        GIFT_CARD = 'gift card',
        CASH = 'cash',
        BANK_CASH_TRANSFER = 'bank cash transfer',
        CRYPTO_TRANSFER = 'crypto transfer',
        SECONDARY_PROCESSOR = 'secondary processor',
        OTHERS = 'others',
    }


}

