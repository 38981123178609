/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateRoleDto = {
    name: string;
    description?: string;
    FunctionalArea?: CreateRoleDto.FunctionalArea;
};

export namespace CreateRoleDto {

    export enum FunctionalArea {
        HUMAN_RESOURCE = 'Human Resource',
        E_COMMERCE = 'eCommerce',
        LOYALTY_MANAGEMENT_SYSTEM = 'Loyalty Management System',
    }


}

