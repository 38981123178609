import React from 'react';

const Loading: React.FC = () => {
    return (
        <div>
            <button className="button is-text is-loading">   </button>
        </div>
    )
}

export default Loading;