/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CreateBankDto } from './CreateBankDto';

export type CreateBankCreditFacilityDto = {
    creditFacilityName: string;
    facilityAccountNumber?: string;
    currency?: CreateBankCreditFacilityDto.currency;
    totalCreditAllocatable?: number;
    creditAvailableForAllocation?: number;
    startDate?: string;
    endDate?: string;
    creditIssueLimit?: number;
    isActive?: boolean;
    bank?: CreateBankDto;
    bankId?: number;
};

export namespace CreateBankCreditFacilityDto {

    export enum currency {
        NAIRA = 'Naira',
        US_DOLLAR = 'US Dollar',
        GREAT_BRITAIN_POUND = 'Great Britain Pound',
    }


}

