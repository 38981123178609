/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateProductCategoryDto } from '../models/CreateProductCategoryDto';
import type { FileUploadDto } from '../models/FileUploadDto';
import type { ProductCategory } from '../models/ProductCategory';
import type { UpdateProductCategoryDto } from '../models/UpdateProductCategoryDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

import { generalVersionUrlPrefix } from '../../../components/app.settings';

export class ProductCategoriesService {

    /**
     * Create a new product category
     * @param accessToken
     * @param requestBody
     * @returns ProductCategory :Product category created
     * @throws ApiError
     */
    public static productCategoriesControllerCreate(
        accessToken: string | undefined = undefined,
        requestBody: CreateProductCategoryDto,
    ): CancelablePromise<ProductCategory> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/product-categories',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a productCategory. Fully replaces all fields
     * @param accessToken
     * @param requestBody
     * @returns ProductCategory :Product category saved
     * @throws ApiError
     */
    public static productCategoriesControllerSave(
        accessToken: string | undefined = undefined,
        requestBody: ProductCategory,
    ): CancelablePromise<ProductCategory> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: generalVersionUrlPrefix + '/product-categories',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all productCategories that meet the criteria specified in query options, if any.
     * @param accessToken
     * @param findOptions encodeURI(JSON.stringify({select?: string[]; relations?: string[]; skip?: number; take?: number;cache?: boolean; where?: {}[] | {}; order?: {};))
     * @returns any ProductCategorys returned.
     * @throws ApiError
     */
    public static productCategoriesControllerFindAll(
        accessToken: string | undefined = undefined,
        findOptions?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/product-categories',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'findOptions': findOptions,
            },
            errors: {
                400: `Bad request: likely incorrect options sent`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create one or more new productCategorys in one go
     * @param accessToken
     * @param requestBody
     * @returns any ProductCategorys have been successfully created.
     * @throws ApiError
     */
    public static productCategoriesControllerInsert(
        accessToken: string | undefined = undefined,
        requestBody: Array<string>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/product-categories/insert',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a productCategory. Only the fields sent from client will be updated
     * @param id
     * @param accessToken
     * @param requestBody
     * @returns any ProductCategory has been successfully updated.
     * @throws ApiError
     */
    public static productCategoriesControllerUpdate(
        id: string,
        accessToken: string | undefined = undefined,
        requestBody: UpdateProductCategoryDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/product-categories/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete a productCategory.
     * @param id
     * @param accessToken
     * @returns any ProductCategory has been successfully deleted.
     * @throws ApiError
     */
    public static productCategoriesControllerDelete(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/product-categories/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                400: `Bad request: likely productCategory does not exist`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a productCategory with the id sent as param
     * @param id
     * @param accessToken
     * @returns ProductCategory :Product category found
     * @throws ApiError
     */
    public static productCategoriesControllerFindOne(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<ProductCategory> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/product-categories/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param productCategoryId
     * @param parentCategoryId
     * @param accessToken
     * @returns ProductCategory
     * @throws ApiError
     */
    public static productCategoriesControllerSetParentCategoryById(
        productCategoryId: string,
        parentCategoryId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<ProductCategory> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/product-categories/{productCategoryId}/parent-category/{parentCategoryId}',
            path: {
                'productCategoryId': productCategoryId,
                'parentCategoryId': parentCategoryId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * @param productCategoryId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static productCategoriesControllerUnsetParentCategoryById(
        productCategoryId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/product-categories/{productCategoryId}/parent-category',
            path: {
                'productCategoryId': productCategoryId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * @param productCategoryId
     * @param childCategoryId
     * @param accessToken
     * @returns ProductCategory
     * @throws ApiError
     */
    public static productCategoriesControllerAddChildCategoryToProductCategoryById(
        productCategoryId: string,
        childCategoryId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<ProductCategory> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/product-categories/{productCategoryId}/child-category/{childCategoryId}',
            path: {
                'productCategoryId': productCategoryId,
                'childCategoryId': childCategoryId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * @param productCategoryId
     * @param childCategoryId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static productCategoriesControllerRemoveChildCategoryFromProductCategoryById(
        productCategoryId: string,
        childCategoryId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/product-categories/{productCategoryId}/child-category/{childCategoryId}',
            path: {
                'productCategoryId': productCategoryId,
                'childCategoryId': childCategoryId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Upload photo of a productCategory by productCategoryId
     * @param productCategoryId
     * @param accessToken
     * @param formData Product photo
     * @returns any
     * @throws ApiError
     */
    public static productCategoriesControllerSetProductPhoto(
        productCategoryId: string,
        accessToken: string | undefined = undefined,
        formData: FileUploadDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/product-categories/{productCategoryId}/photo',
            path: {
                'productCategoryId': productCategoryId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * Get the photo of a product category. This link is used on client side for image URL
     * @param productCategoryId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static productCategoriesControllerGetProductCategoryPhoto(
        productCategoryId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/product-categories/{productCategoryId}/photo',
            path: {
                'productCategoryId': productCategoryId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Useful to know which categories are in the database, to use for display in categories filter
     * @param accessToken
     * @returns ProductCategory
     * @throws ApiError
     */
    public static productCategoriesControllerGetCategoryNamesAndNatureOfCategory(
        accessToken: string | undefined = undefined,
    ): CancelablePromise<Array<ProductCategory>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/product-categories/get-category-names-and-nature-of-category',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

}
