/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Customer } from './Customer';
import type { CustomerIssueComment } from './CustomerIssueComment';

export type CustomerIssue = {
    issueNumber: string;
    raisedBy?: string;
    title: string;
    description: string;
    category: CustomerIssue.category;
    priority: CustomerIssue.priority;
    assignedTo: string;
    status: CustomerIssue.status;
    customerId: number;
    customer: Customer;
    customerIssueComments: Array<CustomerIssueComment>;
    userType: CustomerIssue.userType;
    id: number;
    dateCreated?: string;
    dateLastModified?: string;
};

export namespace CustomerIssue {

    export enum category {
        GENERAL = 'general',
        PRODUCT = 'product',
        PAYMENT = 'payment',
    }

    export enum priority {
        HIGH = 'high',
        MEDIUM = 'medium',
        LOW = 'low',
    }

    export enum status {
        OPEN = 'open',
        CLOSED = 'closed',
    }

    export enum userType {
        CUSTOMER = 'customer',
        MERCHANT = 'merchant',
        BANK = 'bank',
        OTHERS = 'others',
    }


}

