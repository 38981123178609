/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateRoleDto = {
    name?: string;
    description?: string;
    FunctionalArea?: UpdateRoleDto.FunctionalArea;
};

export namespace UpdateRoleDto {

    export enum FunctionalArea {
        HUMAN_RESOURCE = 'Human Resource',
        E_COMMERCE = 'eCommerce',
        LOYALTY_MANAGEMENT_SYSTEM = 'Loyalty Management System',
    }


}

