/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateProductDto } from '../models/CreateProductDto';
import type { CreateProductPhotoDto } from '../models/CreateProductPhotoDto';
import type { Product } from '../models/Product';
import type { ProductsAndCount } from '../models/ProductsAndCount';
import type { ProductSearchResult } from '../models/ProductSearchResult';
import type { ProductSearchResultWithEntity } from '../models/ProductSearchResultWithEntity';
import type { Suggestion } from '../models/Suggestion';
import type { UpdateProductDto } from '../models/UpdateProductDto';
import type { UpdateProductPhotoDto } from '../models/UpdateProductPhotoDto';
import type { UpdateResult } from '../models/UpdateResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

import { generalVersionUrlPrefix } from '../../../components/app.settings';

export class ProductsService {

    /**
     * Merchant end point for creating a new product
     * @param accessToken
     * @param requestBody
     * @param categoryids categoryids=1,2,3,...
     * @returns Product :Product created
     * @throws ApiError
     */
    public static productsControllerCreateByMerchant(
        accessToken: string | undefined = undefined,
        requestBody: CreateProductDto,
        categoryids?: any,
    ): CancelablePromise<Product> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/products/create-by-merchant',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'categoryids': categoryids,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create a new product
     * @param accessToken
     * @param requestBody
     * @param categoryids categoryids=1,2,3,...
     * @returns Product :Product created
     * @throws ApiError
     */
    public static productsControllerCreate(
        accessToken: string | undefined = undefined,
        requestBody: CreateProductDto,
        categoryids?: any,
    ): CancelablePromise<Product> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/products',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'categoryids': categoryids,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a product. Fully replaces all fields
     * @param accessToken
     * @param requestBody
     * @returns Product :Product saved
     * @throws ApiError
     */
    public static productsControllerSave(
        accessToken: string | undefined = undefined,
        requestBody: Product,
    ): CancelablePromise<Product> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: generalVersionUrlPrefix + '/products',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all products that meet the criteria specified in query options, if any.
     * @param accessToken
     * @param findOptions encodeURI(JSON.stringify({select?: string[]; relations?: string[]; skip?: number; take?: number;cache?: boolean; where?: {}[] | {}; order?: {};))
     * @returns ProductsAndCount Get all products that meet the criteria specified in query options, if any. Returns array of items found, and count
     * @throws ApiError
     */
    public static productsControllerFindAll(
        accessToken: string | undefined = undefined,
        findOptions?: any,
    ): CancelablePromise<ProductsAndCount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/products',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'findOptions': findOptions,
            },
            errors: {
                400: `Bad request: likely incorrect options sent`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create one or more new products in one go
     * @param accessToken
     * @param requestBody
     * @returns any Products have been successfully created.
     * @throws ApiError
     */
    public static productsControllerInsert(
        accessToken: string | undefined = undefined,
        requestBody: Array<string>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/products/insert',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a product. Only the fields sent from client will be updated
     * @param id
     * @param accessToken
     * @param requestBody
     * @returns any Product has been successfully updated.
     * @throws ApiError
     */
    public static productsControllerUpdate(
        id: string,
        accessToken: string | undefined = undefined,
        requestBody: UpdateProductDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/products/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete a product.
     * @param id
     * @param accessToken
     * @returns any Product has been successfully deleted.
     * @throws ApiError
     */
    public static productsControllerDelete(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/products/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                400: `Bad request: likely product does not exist`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a product with the id sent as param
     * @param id
     * @param accessToken
     * @returns Product :Product found
     * @throws ApiError
     */
    public static productsControllerFindOne(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<Product> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/products/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * Add product category to product
     * @param productId
     * @param productCategoryId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static productsControllerAddProductCategoryById(
        productId: string,
        productCategoryId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/products/{productId}/product-category/{productCategoryId}',
            path: {
                'productId': productId,
                'productCategoryId': productCategoryId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Remove product category from product
     * @param productId
     * @param productCategoryId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static productsControllerRemoveProductCategoryById(
        productId: string,
        productCategoryId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/products/{productId}/product-category/{productCategoryId}',
            path: {
                'productId': productId,
                'productCategoryId': productCategoryId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Add multiple categories to product at a go
     * @param productId
     * @param accessToken
     * @param categoryids categoryids=1,2,3,...
     * @returns any
     * @throws ApiError
     */
    public static productsControllerAddProductCategoryByIds(
        productId: string,
        accessToken: string | undefined = undefined,
        categoryids?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/products/{productId}/product-categories',
            path: {
                'productId': productId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'categoryids': categoryids,
            },
        });
    }

    /**
     * Remove multiple product categories from product
     * @param productId
     * @param accessToken
     * @param productCategoryId product-category-id=1&product-category-id=2&...
     * @returns any
     * @throws ApiError
     */
    public static productsControllerRemoveProductCategoryByIds(
        productId: string,
        accessToken: string | undefined = undefined,
        productCategoryId?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/products/{productId}/product-categories',
            path: {
                'productId': productId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'product-category-id': productCategoryId,
            },
        });
    }

    /**
     * Set merchant for product
     * @param productId
     * @param merchantId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static productsControllerSetMerchantById(
        productId: string,
        merchantId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/products/{productId}/merchant/{merchantId}',
            path: {
                'productId': productId,
                'merchantId': merchantId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Unset merchant for product
     * @param productId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static productsControllerUnsetMerchantById(
        productId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/products/{productId}/merchant',
            path: {
                'productId': productId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Add shopping cart item to product
     * @param productId
     * @param shoppingCartItemId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static productsControllerAddShoppingCartItemById(
        productId: string,
        shoppingCartItemId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/products/{productId}/shopping-cart-item/{shoppingCartItemId}',
            path: {
                'productId': productId,
                'shoppingCartItemId': shoppingCartItemId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Remove shopping cart item from product
     * @param productId
     * @param shoppingCartItemId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static productsControllerRemoveShoppingCartItemById(
        productId: string,
        shoppingCartItemId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/products/{productId}/shopping-cart-item/{shoppingCartItemId}',
            path: {
                'productId': productId,
                'shoppingCartItemId': shoppingCartItemId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Add order detail to product
     * @param productId
     * @param orderDetailId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static productsControllerAddOrderDetailById(
        productId: string,
        orderDetailId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/products/{productId}/order-detail/{orderDetailId}',
            path: {
                'productId': productId,
                'orderDetailId': orderDetailId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Remove order detail from product
     * @param productId
     * @param orderDetailId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static productsControllerRemoveOrderDetailById(
        productId: string,
        orderDetailId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/products/{productId}/order-detail/{orderDetailId}',
            path: {
                'productId': productId,
                'orderDetailId': orderDetailId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Add transaction to product
     * @param productId
     * @param transactionId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static productsControllerAddTransactionEntryById(
        productId: string,
        transactionId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/products/{productId}/transaction/{transactionId}',
            path: {
                'productId': productId,
                'transactionId': transactionId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Remove transaction from product
     * @param productId
     * @param transactionId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static productsControllerRemoveTransactionEntryById(
        productId: string,
        transactionId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/products/{productId}/transaction/{transactionId}',
            path: {
                'productId': productId,
                'transactionId': transactionId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Upload a photo of a product by productId
     * @param productId
     * @param accessToken
     * @param formData Form fields
     * @returns any
     * @throws ApiError
     */
    public static productsControllerAddProductPhoto(
        productId: string,
        accessToken: string | undefined = undefined,
        formData: CreateProductPhotoDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/products/{productId}/photos',
            path: {
                'productId': productId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * Get the photos of a product. This link is used on client side for image URL of product photos
     * @param productId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static productsControllerGetProductPhotos(
        productId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/products/{productId}/photos',
            path: {
                'productId': productId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Update a photo of a product by productId and photoId
     * @param photoId
     * @param accessToken
     * @param formData Form fields
     * @returns UpdateResult :Product photo updated
     * @throws ApiError
     */
    public static productsControllerUpdateProductPhoto(
        photoId: string,
        accessToken: string | undefined = undefined,
        formData: UpdateProductPhotoDto,
    ): CancelablePromise<UpdateResult> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/products/photos/{photoId}',
            path: {
                'photoId': photoId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * Remove a photo from a product
     * @param productId
     * @param photoId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static productsControllerRemoveProductPhoto(
        productId: string,
        photoId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/products/{productId}/photos/{photoId}',
            path: {
                'productId': productId,
                'photoId': photoId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Stream a photo of a product
     * @param productId
     * @param photoId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static productsControllerGetProductPhotoByPhotoId(
        productId: string,
        photoId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/products/{productId}/photos/{photoId}/download',
            path: {
                'productId': productId,
                'photoId': photoId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * work on search
     * Search for products and return found product objects
     * @param accessToken
     * @param searchString
     * @param unitPriceLessThanOrEqualTo Number value expected. Defaults to undefined
     * @param unitPriceGreaterThanOrEqualTo Number value expected. Defaults to undefined
     * @param searchAfter search-after=34242&search-after=4
     * @param sortOrder asc or desc
     * @param sortBy Sort by relevance, date, etc. Select from option
     * @param resultsPerPage Number of results per page
     * @returns ProductSearchResultWithEntity :Products found
     * @throws ApiError
     */
    public static productsControllerSearchAndGetProducts(
        accessToken: string | undefined = undefined,
        searchString: any,
        unitPriceLessThanOrEqualTo?: any,
        unitPriceGreaterThanOrEqualTo?: any,
        searchAfter?: any,
        sortOrder?: 'asc' | 'desc',
        sortBy?: '_score' | 'date',
        resultsPerPage?: any,
    ): CancelablePromise<ProductSearchResultWithEntity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/products/search-and-get-products',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'unit-price-less-than-or-equal-to': unitPriceLessThanOrEqualTo,
                'unit-price-greater-than-or-equal-to': unitPriceGreaterThanOrEqualTo,
                'search-after': searchAfter,
                'sort-order': sortOrder,
                'sort-by': sortBy,
                'results-per-page': resultsPerPage,
                'search-string': searchString,
            },
        });
    }

    /**
     * Search for products and return the search hits with minimal info sufficient to differentiate the search results. The client can fetch more details afterwards leveraging on the id field.
     * @param accessToken
     * @param searchString
     * @param unitPriceLessThanOrEqualTo number value expected. Defaults to undefined
     * @param unitPriceGreaterThanOrEqualTo number value expected. Defaults to undefined
     * @param searchAfter search-after=34242&search-after=4
     * @param sortOrder asc or desc
     * @param sortBy Sort by relevance, date, etc. Select from option
     * @param resultsPerPage Number of results per page
     * @returns ProductSearchResult :Products found
     * @throws ApiError
     */
    public static productsControllerSearch(
        accessToken: string | undefined = undefined,
        searchString: any,
        unitPriceLessThanOrEqualTo?: any,
        unitPriceGreaterThanOrEqualTo?: any,
        searchAfter?: any,
        sortOrder?: 'asc' | 'desc',
        sortBy?: '_score' | 'date',
        resultsPerPage?: any,
    ): CancelablePromise<ProductSearchResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/products/search',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'unit-price-less-than-or-equal-to': unitPriceLessThanOrEqualTo,
                'unit-price-greater-than-or-equal-to': unitPriceGreaterThanOrEqualTo,
                'search-after': searchAfter,
                'sort-order': sortOrder,
                'sort-by': sortBy,
                'results-per-page': resultsPerPage,
                'search-string': searchString,
            },
        });
    }

    /**
     * Search for products and return found product objects
     * @param operator and | or. Defaults to and
     * @param accessToken
     * @param categoryName
     * @param searchString
     * @param unitPriceLessThanOrEqualTo number value expected. Defaults to undefined
     * @param unitPriceGreaterThanOrEqualTo number value expected. Defaults to undefined
     * @param searchAfter search-after=34242&search-after=4
     * @param sortOrder asc or desc
     * @param sortBy Sort by relevance, date, etc. Select from option
     * @param resultsPerPage Number of results per page
     * @returns ProductSearchResultWithEntity :Products found
     * @throws ApiError
     */
    public static productsControllerSearchByCategoryAndGetProducts(
        operator: any,
        accessToken: string | undefined = undefined,
        categoryName: any,
        searchString: any,
        unitPriceLessThanOrEqualTo?: any,
        unitPriceGreaterThanOrEqualTo?: any,
        searchAfter?: any,
        sortOrder?: 'asc' | 'desc',
        sortBy?: '_score' | 'date',
        resultsPerPage?: any,
    ): CancelablePromise<ProductSearchResultWithEntity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/products/search-by-category-and-get-products',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'operator': operator,
                'unit-price-less-than-or-equal-to': unitPriceLessThanOrEqualTo,
                'unit-price-greater-than-or-equal-to': unitPriceGreaterThanOrEqualTo,
                'search-after': searchAfter,
                'sort-order': sortOrder,
                'sort-by': sortBy,
                'results-per-page': resultsPerPage,
                'category-name': categoryName,
                'search-string': searchString,
            },
        });
    }

    /**
     * Search for products by category and return the search hits with minimal info sufficient to differentiate the search results. The client can fetch more details afterwards leveraging on the id field.
     * @param accessToken
     * @param operator and | or. Defaults to and
     * @param categoryNames One or more category names separated by space
     * @param searchString
     * @param unitPriceLessThanOrEqualTo number value expected. Defaults to undefined
     * @param unitPriceGreaterThanOrEqualTo number value expected. Defaults to undefined
     * @param searchAfter search-after=34242&search-after=4
     * @param sortOrder asc or desc
     * @param sortBy Sort by relevance, date, etc. Select from option
     * @param resultsPerPage Number of results per page
     * @returns ProductSearchResult :Products found
     * @throws ApiError
     */
    public static productsControllerSearchByCategory(
        accessToken: string | undefined = undefined,
        operator: any,
        categoryNames: any,
        searchString: any,
        unitPriceLessThanOrEqualTo?: any,
        unitPriceGreaterThanOrEqualTo?: any,
        searchAfter?: any,
        sortOrder?: 'asc' | 'desc',
        sortBy?: '_score' | 'date',
        resultsPerPage?: any,
    ): CancelablePromise<ProductSearchResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/products/search-by-category',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'unit-price-less-than-or-equal-to': unitPriceLessThanOrEqualTo,
                'unit-price-greater-than-or-equal-to': unitPriceGreaterThanOrEqualTo,
                'operator': operator,
                'search-after': searchAfter,
                'sort-order': sortOrder,
                'sort-by': sortBy,
                'results-per-page': resultsPerPage,
                'category-names': categoryNames,
                'search-string': searchString,
            },
        });
    }

    /**
     * Run query against index optimised for autosuggest.
     * @param accessToken
     * @param searchString
     * @param unitPriceLessThanOrEqualTo number value expected. Defaults to undefined
     * @param unitPriceGreaterThanOrEqualTo number value expected. Defaults to undefined
     * @returns Suggestion :Product suggestions
     * @throws ApiError
     */
    public static productsControllerSuggest(
        accessToken: string | undefined = undefined,
        searchString: any,
        unitPriceLessThanOrEqualTo?: any,
        unitPriceGreaterThanOrEqualTo?: any,
    ): CancelablePromise<Array<Suggestion>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/products/suggest',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'unit-price-less-than-or-equal-to': unitPriceLessThanOrEqualTo,
                'unit-price-greater-than-or-equal-to': unitPriceGreaterThanOrEqualTo,
                'search-string': searchString,
            },
        });
    }

    /**
     * Run query by category against index optimised for autosuggest.
     * @param accessToken
     * @param operator and | or. Defaults to and
     * @param categoryNames One or more category names separated by space
     * @param searchString
     * @param unitPriceLessThanOrEqualTo number value expected. Defaults to undefined
     * @param unitPriceGreaterThanOrEqualTo number value expected. Defaults to undefined
     * @returns Suggestion :Product suggestions by category
     * @throws ApiError
     */
    public static productsControllerSuggestByCategory(
        accessToken: string | undefined = undefined,
        operator: any,
        categoryNames: any,
        searchString: any,
        unitPriceLessThanOrEqualTo?: any,
        unitPriceGreaterThanOrEqualTo?: any,
    ): CancelablePromise<Array<Suggestion>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/products/suggest-by-category',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'unit-price-less-than-or-equal-to': unitPriceLessThanOrEqualTo,
                'unit-price-greater-than-or-equal-to': unitPriceGreaterThanOrEqualTo,
                'operator': operator,
                'category-names': categoryNames,
                'search-string': searchString,
            },
        });
    }

    /**
     * Get specific fields of a product (unitsInStock, unitPrice, currentGeneralDiscount, loyaltyApplicable, installmentApplicable) with the id sent as param
     * @param id
     * @param accessToken
     * @returns Product :Product found
     * @throws ApiError
     */
    public static productsControllerGetInfoForCheckout(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<Product> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/products/get-info-for-checkout/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get specific fields of products (unitsInStock, unitPrice, currentGeneralDiscount, loyaltyApplicable, installmentApplicable) with the ids sent as query product-ids=1,2,3,...
     * @param accessToken
     * @param productIds product-ids=1,2,3,...
     * @returns Product Products found
     * @throws ApiError
     */
    public static productsControllerGetProductsInfoForCheckout(
        accessToken: string | undefined = undefined,
        productIds?: any,
    ): CancelablePromise<Array<Product>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/products/get-products-info-for-checkout',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'product-ids': productIds,
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

}
