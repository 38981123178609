/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateGeneralUploadDto = {
    file?: Blob;
    name: string;
    cat?: CreateGeneralUploadDto.cat;
    descr: string;
    tags?: Array<string>;
    isImage?: boolean;
    isAV?: boolean;
    isPub?: boolean;
};

export namespace CreateGeneralUploadDto {

    export enum cat {
        HOME_PAGE = 'home page',
        PRODUCT_CATEGORY = 'product category',
        GENERIC = 'generic',
        MISCELLANEOUS = 'miscellaneous',
    }


}

