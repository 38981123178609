/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { LoyaltyBenefit } from './LoyaltyBenefit';
import type { Payment } from './Payment';

export type FacilityCreditAndDebit = {
    transactionDate: string;
    type: FacilityCreditAndDebit.type;
    description: string;
    loyaltyBenefitId: number;
    loyaltyBenefit: LoyaltyBenefit;
    payment: Payment;
    id: number;
    dateCreated?: string;
    dateLastModified?: string;
};

export namespace FacilityCreditAndDebit {

    export enum type {
        CREDIT = 'credit',
        DEBIT = 'debit',
    }


}

