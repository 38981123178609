/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateShoppingCartDto } from '../models/CreateShoppingCartDto';
import type { ShoppingCart } from '../models/ShoppingCart';
import type { ShoppingCartsAndCount } from '../models/ShoppingCartsAndCount';
import type { UpdateShoppingCartDto } from '../models/UpdateShoppingCartDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

import { generalVersionUrlPrefix } from '../../../components/app.settings';

export class ShoppingCartsService {

    /**
     * Create a new shoppingCart
     * @param accessToken
     * @param requestBody
     * @returns ShoppingCart :Shopping cart created
     * @throws ApiError
     */
    public static shoppingCartsControllerCreate(
        accessToken: string | undefined = undefined,
        requestBody: CreateShoppingCartDto,
    ): CancelablePromise<ShoppingCart> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/shopping-carts',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a shoppingCart. Fully replaces all fields
     * @param accessToken
     * @param requestBody
     * @returns ShoppingCart :Shopping cart saved
     * @throws ApiError
     */
    public static shoppingCartsControllerSave(
        accessToken: string | undefined = undefined,
        requestBody: ShoppingCart,
    ): CancelablePromise<ShoppingCart> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: generalVersionUrlPrefix + '/shopping-carts',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all shoppingCarts that meet the criteria specified in query options, if any.
     * @param accessToken
     * @param findOptions encodeURI(JSON.stringify({select?: string[]; relations?: string[]; skip?: number; take?: number;cache?: boolean; where?: {}[] | {}; order?: {};))
     * @returns ShoppingCartsAndCount Get all shopping carts that meet the criteria specified in query options, if any. Returns array of items found, and count
     * @throws ApiError
     */
    public static shoppingCartsControllerFindAll(
        accessToken: string | undefined = undefined,
        findOptions?: any,
    ): CancelablePromise<ShoppingCartsAndCount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/shopping-carts',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'findOptions': findOptions,
            },
            errors: {
                400: `Bad request: likely incorrect options sent`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create one or more new shoppingCarts in one go
     * @param accessToken
     * @param requestBody
     * @returns any ShoppingCarts have been successfully created.
     * @throws ApiError
     */
    public static shoppingCartsControllerInsert(
        accessToken: string | undefined = undefined,
        requestBody: Array<string>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/shopping-carts/insert',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a shoppingCart. Only the fields sent from client will be updated
     * @param id
     * @param accessToken
     * @param requestBody
     * @returns any ShoppingCart has been successfully updated.
     * @throws ApiError
     */
    public static shoppingCartsControllerUpdate(
        id: string,
        accessToken: string | undefined = undefined,
        requestBody: UpdateShoppingCartDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/shopping-carts/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete a shoppingCart.
     * @param id
     * @param accessToken
     * @returns any ShoppingCart has been successfully deleted.
     * @throws ApiError
     */
    public static shoppingCartsControllerDelete(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/shopping-carts/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                400: `Bad request: likely shoppingCart does not exist`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a shoppingCart with the id sent as param
     * @param id
     * @param accessToken
     * @returns ShoppingCart :Shopping cart found
     * @throws ApiError
     */
    public static shoppingCartsControllerFindOne(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<ShoppingCart> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/shopping-carts/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a shoppingCart with the userId sent as param
     * @param userId
     * @param accessToken
     * @returns ShoppingCart :Shopping cart found
     * @throws ApiError
     */
    public static shoppingCartsControllerFindByUserId(
        userId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<ShoppingCart> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/shopping-carts/user/{userId}',
            path: {
                'userId': userId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a shoppingCart with the customerId sent as param
     * @param customerId
     * @param accessToken
     * @returns ShoppingCart :Shopping cart found
     * @throws ApiError
     */
    public static shoppingCartsControllerFindByCustomerId(
        customerId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<ShoppingCart> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/shopping-carts/customer/{customerId}',
            path: {
                'customerId': customerId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * Set user of shopping cart
     * @param shoppingCartId
     * @param userId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static shoppingCartsControllerSetUserById(
        shoppingCartId: string,
        userId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/shopping-carts/{shoppingCartId}/user/{userId}',
            path: {
                'shoppingCartId': shoppingCartId,
                'userId': userId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Unset user of shopping cart
     * @param shoppingCartId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static shoppingCartsControllerUnsetUserById(
        shoppingCartId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/shopping-carts/{shoppingCartId}/user',
            path: {
                'shoppingCartId': shoppingCartId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Set order for shopping cart
     * @param shoppingCartId
     * @param orderId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static shoppingCartsControllerSetOrderById(
        shoppingCartId: string,
        orderId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/shopping-carts/{shoppingCartId}/order/{orderId}',
            path: {
                'shoppingCartId': shoppingCartId,
                'orderId': orderId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Unset order for shopping cart
     * @param shoppingCartId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static shoppingCartsControllerUnsetOrderById(
        shoppingCartId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/shopping-carts/{shoppingCartId}/order',
            path: {
                'shoppingCartId': shoppingCartId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Add shopping cart item to shopping cart
     * @param shoppingCartId
     * @param shoppingCartItemId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static shoppingCartsControllerAddShoppingCartItemById(
        shoppingCartId: string,
        shoppingCartItemId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/shopping-carts/{shoppingCartId}/shopping-cart/{shoppingCartItemId}',
            path: {
                'shoppingCartId': shoppingCartId,
                'shoppingCartItemId': shoppingCartItemId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Remove shopping cart item from shopping cart
     * @param shoppingCartId
     * @param shoppingCartItemId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static shoppingCartsControllerRemoveShoppingCartItemById(
        shoppingCartId: string,
        shoppingCartItemId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/shopping-carts/{shoppingCartId}/shopping-cart-item/{shoppingCartItemId}',
            path: {
                'shoppingCartId': shoppingCartId,
                'shoppingCartItemId': shoppingCartItemId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * @param shoppingCartId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static shoppingCartsControllerClearAllShoppingCartItems(
        shoppingCartId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/shopping-carts/{shoppingCartId}/clear-all-shopping-cart-items',
            path: {
                'shoppingCartId': shoppingCartId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

}
