/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateOrderDto } from '../models/CreateOrderDto';
import type { Order } from '../models/Order';
import type { OrdersAndCount } from '../models/OrdersAndCount';
import type { UpdateOrderDto } from '../models/UpdateOrderDto';
import type { UpdatePaymentDto } from '../models/UpdatePaymentDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

import { generalVersionUrlPrefix } from '../../../components/app.settings';

export class OrdersService {

    /**
     * Create a new order
     * @param accessToken
     * @param requestBody
     * @returns Order :Order created
     * @throws ApiError
     */
    public static ordersControllerCreate(
        accessToken: string | undefined = undefined,
        requestBody: CreateOrderDto,
    ): CancelablePromise<Order> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/orders',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a order. Fully replaces all fields
     * @param accessToken
     * @param requestBody
     * @returns Order :Order saved
     * @throws ApiError
     */
    public static ordersControllerSave(
        accessToken: string | undefined = undefined,
        requestBody: Order,
    ): CancelablePromise<Order> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: generalVersionUrlPrefix + '/orders',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all orders that meet the criteria specified in query options, if any.
     * @param accessToken
     * @param findOptions encodeURI(JSON.stringify({select?: string[]; relations?: string[]; skip?: number; take?: number;cache?: boolean; where?: {}[] | {}; order?: {};))
     * @returns OrdersAndCount Get all orders that meet the criteria specified in query options, if any. Returns array of items found, and count
     * @throws ApiError
     */
    public static ordersControllerFindAll(
        accessToken: string | undefined = undefined,
        findOptions?: any,
    ): CancelablePromise<OrdersAndCount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/orders',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'findOptions': findOptions,
            },
            errors: {
                400: `Bad request: likely incorrect options sent`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create one or more new orders in one go
     * @param accessToken
     * @param requestBody
     * @returns any Orders have been successfully created.
     * @throws ApiError
     */
    public static ordersControllerInsert(
        accessToken: string | undefined = undefined,
        requestBody: Array<string>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/orders/insert',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a order. Only the fields sent from client will be updated
     * @param id
     * @param accessToken
     * @param requestBody
     * @returns any Order has been successfully updated.
     * @throws ApiError
     */
    public static ordersControllerUpdate(
        id: string,
        accessToken: string | undefined = undefined,
        requestBody: UpdateOrderDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/orders/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete a order.
     * @param id
     * @param accessToken
     * @returns any Order has been successfully deleted.
     * @throws ApiError
     */
    public static ordersControllerDelete(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/orders/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                400: `Bad request: likely order does not exist`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a order with the id sent as param
     * @param id
     * @param accessToken
     * @returns Order :Order found
     * @throws ApiError
     */
    public static ordersControllerFindOne(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<Order> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/orders/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * Set customer for a given order
     * @param orderId
     * @param customerId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static ordersControllerSetCustomerById(
        orderId: string,
        customerId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/orders/{orderId}/customer/{customerId}',
            path: {
                'orderId': orderId,
                'customerId': customerId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Unset customer for a given order
     * @param orderId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static ordersControllerUnsetCustomerById(
        orderId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/orders/{orderId}/customer',
            path: {
                'orderId': orderId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Set preferred shipper for a given order
     * @param orderId
     * @param shipperId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static ordersControllerSetPreferredShipperById(
        orderId: string,
        shipperId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/orders/{orderId}/shipper/{shipperId}',
            path: {
                'orderId': orderId,
                'shipperId': shipperId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Unset preferred shipper for a given order
     * @param orderId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static ordersControllerUnsetPreferredShipperById(
        orderId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/orders/{orderId}/shipper',
            path: {
                'orderId': orderId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Set originating shopping cart for a given order
     * @param orderId
     * @param shoppingCartId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static ordersControllerSetOriginatingCartById(
        orderId: string,
        shoppingCartId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/orders/{orderId}/shopping-cart/{shoppingCartId}',
            path: {
                'orderId': orderId,
                'shoppingCartId': shoppingCartId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Unset originating shopping cart for a give order
     * @param orderId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static ordersControllerUnsetOriginatingCartById(
        orderId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/orders/{orderId}/shopping-cart',
            path: {
                'orderId': orderId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Add an order detail to a given order
     * @param orderId
     * @param orderDetailId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static ordersControllerAddOrderDetailById(
        orderId: string,
        orderDetailId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/orders/{orderId}/order-detail/{orderDetailId}',
            path: {
                'orderId': orderId,
                'orderDetailId': orderDetailId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Remove an order detail from a given order
     * @param orderId
     * @param orderDetailId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static ordersControllerRemoveOrderDetailById(
        orderId: string,
        orderDetailId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/orders/{orderId}/order-detail/{orderDetailId}',
            path: {
                'orderId': orderId,
                'orderDetailId': orderDetailId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Set transaction entry for a given order
     * @param orderId
     * @param transactionId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static ordersControllerSetTransactionEntryById(
        orderId: string,
        transactionId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/orders/{orderId}/transaction/{transactionId}',
            path: {
                'orderId': orderId,
                'transactionId': transactionId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Unset transaction entry for a given order
     * @param orderId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static ordersControllerUnsetTransactionEntryById(
        orderId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/orders/{orderId}/transaction',
            path: {
                'orderId': orderId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Set customerId preferredShipperId, originatingCartId ids simultaneously
     * @param orderId
     * @param accessToken
     * @param originatingCartId id of originating cart
     * @param preferredShipperId id of preferred shipper
     * @param customerId id of customer
     * @returns any
     * @throws ApiError
     */
    public static ordersControllerSetOrAddMultipleRelationsById(
        orderId: string,
        accessToken: string | undefined = undefined,
        originatingCartId?: any,
        preferredShipperId?: any,
        customerId?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/orders/{orderId}/set-multiple-relations-by-id',
            path: {
                'orderId': orderId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'originatingCartId': originatingCartId,
                'preferredShipperId': preferredShipperId,
                'customerId': customerId,
            },
        });
    }

    /**
     * @param invoiceNumber
     * @param accessToken
     * @param requestBody
     * @returns Order
     * @throws ApiError
     */
    public static ordersControllerUpdatePaymentByInvoiceNumber(
        invoiceNumber: string,
        accessToken: string | undefined = undefined,
        requestBody: UpdatePaymentDto,
    ): CancelablePromise<Order> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/orders/update-payment-by-invoice-number/{invoiceNumber}',
            path: {
                'invoiceNumber': invoiceNumber,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param customerId
     * @returns Order
     * @throws ApiError
     */
    public static ordersControllerFindByCustomerId(
        customerId: string,
    ): CancelablePromise<Array<Order>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/orders/find-by-customer-id/{customerId}',
            path: {
                'customerId': customerId,
            },
        });
    }

}
