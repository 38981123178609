/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateProductCategoryDto = {
    name: string;
    description?: string;
    photo?: string;
    photoMimeType?: string;
    isApproved?: boolean;
    approvedBy?: number;
    natureOfCategory: CreateProductCategoryDto.natureOfCategory;
    isLocked?: boolean;
    parentCategory?: CreateProductCategoryDto;
    childCategories?: Array<CreateProductCategoryDto>;
};

export namespace CreateProductCategoryDto {

    export enum natureOfCategory {
        SIZE = 'size',
        BRAND = 'brand',
        GENDER = 'gender',
        AGE_GROUP = 'age group',
        MACHINE = 'machine',
        PARTS = 'parts',
        ORGANIC = 'organic',
        DIGITAL = 'digital',
        MEDICAL = 'medical',
        GENERIC = 'generic',
    }


}

