/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateEmployeeDto } from '../models/CreateEmployeeDto';
import type { CreateEmployeeDtos } from '../models/CreateEmployeeDtos';
import type { CreateUserDto } from '../models/CreateUserDto';
import type { Employee } from '../models/Employee';
import type { EmployeesAndCount } from '../models/EmployeesAndCount';
import type { EmployeeSearchResult } from '../models/EmployeeSearchResult';
import type { EmployeeSearchResultWithEntity } from '../models/EmployeeSearchResultWithEntity';
import type { Suggestion } from '../models/Suggestion';
import type { UpdateEmployeeDto } from '../models/UpdateEmployeeDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

import { generalVersionUrlPrefix } from '../../../components/app.settings';

export class EmployeesService {

    /**
     * Create a new employee. Optionally create a user if create-user query string is set to 1
     * @param accessToken
     * @param createUser
     * @param requestBody
     * @returns Employee :Employee created
     * @throws ApiError
     */
    public static employeesControllerCreate(
        accessToken: string | undefined = undefined,
        createUser: any,
        requestBody: CreateEmployeeDto,
    ): CancelablePromise<Employee> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/employees',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'create-user': createUser,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update an employee. Fully replaces all fields
     * @param accessToken
     * @param requestBody
     * @returns Employee :Employee saved
     * @throws ApiError
     */
    public static employeesControllerSave(
        accessToken: string | undefined = undefined,
        requestBody: Employee,
    ): CancelablePromise<Employee> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: generalVersionUrlPrefix + '/employees',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all employees that meet the criteria specified in query options, if any.
     * @param accessToken
     * @param findOptions encodeURI(JSON.stringify({select?: string[]; relations?: string[]; skip?: number; take?: number;cache?: boolean; where?: {}[] | {}; order?: {};))
     * @returns EmployeesAndCount Get all employees that meet the criteria specified in query options, if any. Returns array of items found, and count
     * @throws ApiError
     */
    public static employeesControllerFindAll(
        accessToken: string | undefined = undefined,
        findOptions?: any,
    ): CancelablePromise<EmployeesAndCount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/employees',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'findOptions': findOptions,
            },
            errors: {
                400: `Bad request: likely incorrect options sent`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create one or more new employees in one go
     * @param accessToken
     * @param requestBody
     * @returns any Employees have been successfully created.
     * @throws ApiError
     */
    public static employeesControllerInsert(
        accessToken: string | undefined = undefined,
        requestBody: CreateEmployeeDtos,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/employees/insert',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update an employee. Only the fields sent from client will be updated
     * @param id
     * @param accessToken
     * @param requestBody
     * @returns any Employee has been successfully updated.
     * @throws ApiError
     */
    public static employeesControllerUpdate(
        id: string,
        accessToken: string | undefined = undefined,
        requestBody: UpdateEmployeeDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/employees/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete an employee.
     * @param id
     * @param accessToken
     * @returns any Employee has been successfully deleted.
     * @throws ApiError
     */
    public static employeesControllerDelete(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/employees/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                400: `Bad request: likely employee does not exist`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a employee with the id sent as param
     * @param id
     * @param accessToken
     * @returns Employee :Employee found
     * @throws ApiError
     */
    public static employeesControllerFindOne(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<Employee> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/employees/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * This url is for web client involving both server-side and client-side rendering
     * @param accessToken
     * @returns any Rendered web page is returned.
     * @throws ApiError
     */
    public static employeesControllerWeb(
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/employees/web*',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * Set the user for a given employee
     * @param employeeId
     * @param userId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static employeesControllerSetUserById(
        employeeId: string,
        userId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/employees/{employeeId}/user/{userId}',
            path: {
                'employeeId': employeeId,
                'userId': userId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * @param employeeId
     * @param accessToken
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static employeesControllerCreateAndSetUser(
        employeeId: string,
        accessToken: string | undefined = undefined,
        requestBody: CreateUserDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/employees/{employeeId}/user',
            path: {
                'employeeId': employeeId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param employeeId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static employeesControllerUnsetUserById(
        employeeId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/employees/{employeeId}/user',
            path: {
                'employeeId': employeeId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * work on search
     * @param accessToken
     * @param searchAfter search-after=34242&search-after=4
     * @param sortOrder asc or desc
     * @param sortBy Sort by relevance, date, etc. Select from option
     * @param resultsPerPage Number of results per page
     * @returns EmployeeSearchResultWithEntity :Employees found
     * @throws ApiError
     */
    public static employeesControllerSearchAndGetEmployees(
        accessToken: string | undefined = undefined,
        searchAfter?: any,
        sortOrder?: 'asc' | 'desc',
        sortBy?: '_score' | 'date',
        resultsPerPage?: any,
    ): CancelablePromise<EmployeeSearchResultWithEntity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/employees/search-and-get-employees',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'search-after': searchAfter,
                'sort-order': sortOrder,
                'sort-by': sortBy,
                'results-per-page': resultsPerPage,
            },
        });
    }

    /**
     * @param accessToken
     * @param searchAfter search-after=34242&search-after=4
     * @param sortOrder asc or desc
     * @param sortBy Sort by relevance, date, etc. Select from option
     * @param resultsPerPage Number of results per page
     * @returns EmployeeSearchResult :Employees found
     * @throws ApiError
     */
    public static employeesControllerSearch(
        accessToken: string | undefined = undefined,
        searchAfter?: any,
        sortOrder?: 'asc' | 'desc',
        sortBy?: '_score' | 'date',
        resultsPerPage?: any,
    ): CancelablePromise<EmployeeSearchResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/employees/search',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'search-after': searchAfter,
                'sort-order': sortOrder,
                'sort-by': sortBy,
                'results-per-page': resultsPerPage,
            },
        });
    }

    /**
     * @param accessToken
     * @returns Suggestion :Employee suggestions
     * @throws ApiError
     */
    public static employeesControllerSuggest(
        accessToken: string | undefined = undefined,
    ): CancelablePromise<Array<Suggestion>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/employees/suggest',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Create self as a new employee. Optionally create a user if create-user query string is set to 1
     * @param accessToken
     * @param createUser
     * @param requestBody
     * @returns Employee :Employee self-created
     * @returns any Employee has been successfully created.
     * @throws ApiError
     */
    public static employeesControllerCreateSelf(
        accessToken: string | undefined = undefined,
        createUser: any,
        requestBody: CreateEmployeeDto,
    ): CancelablePromise<Employee | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/employees/create-self',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'create-user': createUser,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Employee officer create a new employee. Optionally create a new user is create-user query string is set to 1
     * @param accessToken
     * @param createUser
     * @param requestBody
     * @returns Employee :Employee created by officer
     * @returns any Employee has been successfully created.
     * @throws ApiError
     */
    public static employeesControllerCreateEmployeeByOfficer(
        accessToken: string | undefined = undefined,
        createUser: any,
        requestBody: CreateEmployeeDto,
    ): CancelablePromise<Employee | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/employees/create-by-employee-officer',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'create-user': createUser,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get desired relations for a given employee. List of relations to fetch as query, separated by commas e.g. orders,user]. Returns the employee id along with the relations only
     * @param employeeId
     * @param sendAllEmployeeFieldsAlong Set to true, if you want the whole employee fields to be carried along i.e. send-all-employee-fields-along=true. Else, don't add the query at all
     * @param relations List of relations to fetch separated by commas e.g. managees,coachees, etc]. Returns the employee id along with the relations only
     * @param accessToken
     * @returns any Relations successfully gotten.
     * @throws ApiError
     */
    public static employeesControllerGetRelationsByName(
        employeeId: string,
        sendAllEmployeeFieldsAlong: any,
        relations: any,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/employees/{employeeId}/get-relations-by-name',
            path: {
                'employeeId': employeeId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'send-all-employee-fields-along': sendAllEmployeeFieldsAlong,
                'relations': relations,
            },
            errors: {
                400: `Bad request: likely, employee does not exist`,
                500: `Internal server error`,
            },
        });
    }

}
