/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CreateBankDto } from './CreateBankDto';

export type UpdateBankCreditFacilityDto = {
    creditFacilityName?: string;
    facilityAccountNumber?: string;
    currency?: UpdateBankCreditFacilityDto.currency;
    totalCreditAllocatable?: number;
    creditAvailableForAllocation?: number;
    startDate?: string;
    endDate?: string;
    creditIssueLimit?: number;
    isActive?: boolean;
    bank?: CreateBankDto;
    bankId?: number;
};

export namespace UpdateBankCreditFacilityDto {

    export enum currency {
        NAIRA = 'Naira',
        US_DOLLAR = 'US Dollar',
        GREAT_BRITAIN_POUND = 'Great Britain Pound',
    }


}

