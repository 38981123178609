/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FacilityCreditAndDebit } from './FacilityCreditAndDebit';
import type { Order } from './Order';
import type { PaymentMethod } from './PaymentMethod';
import type { PointCreditAndDebit } from './PointCreditAndDebit';
import type { TokenCreditAndDebit } from './TokenCreditAndDebit';

export type Payment = {
    invoiceNumber: string;
    installment: boolean;
    facility: boolean;
    /**
     * @Column({nullable: true})
     * facilityCreditAndDebitId: number;
     */
    facilityCreditAndDebit: FacilityCreditAndDebit;
    token: boolean;
    point: boolean;
    /**
     * @Column({nullable: true})
     * tokenCreditAndDebitId: number;
     */
    tokenCreditAndDebit: TokenCreditAndDebit;
    pointCreditAndDebit: PointCreditAndDebit;
    amountPaid: number;
    defaultCurrencyEquivalentOfAmountPaid: number;
    currency: string;
    paymentMethodId: number;
    paymentMethod: PaymentMethod;
    orderId: number;
    order: Order;
    invoiceState: Payment.invoiceState;
    lastPaymentAttemptFeedback: string;
    payLaterPermitted: boolean;
    id: number;
    dateCreated?: string;
    dateLastModified?: string;
};

export namespace Payment {

    export enum invoiceState {
        PAID = 'Paid',
        UNPAID = 'Unpaid',
        CANCELLED = 'Cancelled',
    }


}

