/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateGeneralUploadDto } from '../models/CreateGeneralUploadDto';
import type { GeneralUpload } from '../models/GeneralUpload';
import type { GeneralUploadsAndCount } from '../models/GeneralUploadsAndCount';
import type { GeneralUploadSearchResult } from '../models/GeneralUploadSearchResult';
import type { GeneralUploadSearchResultWithEntity } from '../models/GeneralUploadSearchResultWithEntity';
import type { Suggestion } from '../models/Suggestion';
import type { UpdateGeneralUploadDto } from '../models/UpdateGeneralUploadDto';
import type { UpdateResult } from '../models/UpdateResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

import { generalVersionUrlPrefix } from '../../../components/app.settings';

export class GeneralUploadsService {

    /**
     * Upload a generic file along with other fields
     * @param accessToken
     * @param formData Form fields
     * @returns GeneralUpload :General upload created
     * @throws ApiError
     */
    public static generalUploadsControllerCreate(
        accessToken: string | undefined = undefined,
        formData: CreateGeneralUploadDto,
    ): CancelablePromise<GeneralUpload> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/general-uploads',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * Find all with options
     * @param accessToken
     * @param findOptions encodeURI(JSON.stringify({select?: string[]; relations?: string[]; skip?: number; take?: number;cache?: boolean; where?: {}[] | {}; order?: {};))
     * @returns GeneralUploadsAndCount Get all general uploads that meet the criteria specified in query options, if any. Returns array of items found, and count
     * @throws ApiError
     */
    public static generalUploadsControllerFindAll(
        accessToken: string | undefined = undefined,
        findOptions?: any,
    ): CancelablePromise<GeneralUploadsAndCount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/general-uploads',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'findOptions': findOptions,
            },
        });
    }

    /**
     * Find one upload by id
     * @param id
     * @param accessToken
     * @returns GeneralUpload :General upload found
     * @throws ApiError
     */
    public static generalUploadsControllerFindOne(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<GeneralUpload> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/general-uploads/{id}',
            path: {
                'id': id,
            },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Update an upload by id
     * @param id
     * @param accessToken
     * @param formData Form fields
     * @returns UpdateResult :General upload updated
     * @throws ApiError
     */
    public static generalUploadsControllerUpdate(
        id: string,
        accessToken: string | undefined = undefined,
        //formData: UpdateGeneralUploadDto,
        formData: UpdateGeneralUploadDto | FormData,
    ): CancelablePromise<UpdateResult> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/general-uploads/{id}',
            path: {
                'id': id,
            },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            //formData: formData,
            //mediaType: 'multipart/form-data',
            body: formData

        });
    }

    /**
     * Delete an upload by id
     * @param id
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static generalUploadsControllerDelete(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/general-uploads/{id}',
            path: {
                'id': id,
            },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Download an upload by id
     * @param generalUploadId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static generalUploadsControllerGetFileByIdAndPub(
        generalUploadId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/general-uploads/id/{generalUploadId}/download',
            path: {
                'generalUploadId': generalUploadId,
            },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Download an upload by id
     * @param generalUploadId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static generalUploadsControllerGetFileById(
        generalUploadId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/general-uploads/id/{generalUploadId}/download-edit',
            path: {
                'generalUploadId': generalUploadId,
            },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Download an upload by filename uuid
     * @param fileName
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static generalUploadsControllerGetFileByFileNameUuidAndPub(
        fileName: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/general-uploads/file-name/{fileName}/download',
            path: {
                'fileName': fileName,
            },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * work on search
     * Search for generalUploads and return found generalUpload objects
     * @param accessToken
     * @param searchString
     * @param searchAfter search-after=34242&search-after=4
     * @param sortOrder asc or desc
     * @param sortBy Sort by relevance, date, etc. Select from option
     * @param resultsPerPage Number of results per page
     * @returns GeneralUpload :General uploads found
     * @throws ApiError
     */
    public static generalUploadsControllerSearchAndGetGeneralUploads(
        accessToken: string | undefined = undefined,
        searchString: any,
        searchAfter?: any,
        sortOrder?: 'asc' | 'desc',
        sortBy?: '_score' | 'date',
        resultsPerPage?: any,
    ): CancelablePromise<Array<GeneralUpload>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/general-uploads/search-and-get-general-uploads',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'search-after': searchAfter,
                'sort-order': sortOrder,
                'sort-by': sortBy,
                'results-per-page': resultsPerPage,
                'search-string': searchString,
            },
        });
    }

    /**
     * Search for generalUploads and return the search hits with minimal info sufficient to differentiate the search results. The client can fetch more details afterwards leveraging on the id field.
     * @param accessToken
     * @param searchString
     * @param searchAfter search-after=34242&search-after=4
     * @param sortOrder asc or desc
     * @param sortBy Sort by relevance, date, etc. Select from option
     * @param resultsPerPage Number of results per page
     * @returns GeneralUploadSearchResult :General uploads found
     * @throws ApiError
     */
    public static generalUploadsControllerSearch(
        accessToken: string | undefined = undefined,
        searchString: any,
        searchAfter?: any,
        sortOrder?: 'asc' | 'desc',
        sortBy?: '_score' | 'date',
        resultsPerPage?: any,
    ): CancelablePromise<GeneralUploadSearchResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/general-uploads/search',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'search-string': searchString,
                'search-after': searchAfter,
                'sort-order': sortOrder,
                'sort-by': sortBy,
                'results-per-page': resultsPerPage,
            },
        });
    }

    /**
     * Search for generalUploads and return found generalUpload objects
     * @param accessToken
     * @param categoryName
     * @param searchString
     * @param searchAfter search-after=34242&search-after=4
     * @param sortOrder asc or desc
     * @param sortBy Sort by relevance, date, etc. Select from option
     * @param resultsPerPage Number of results per page
     * @returns GeneralUploadSearchResultWithEntity :General uploads found
     * @throws ApiError
     */
    public static generalUploadsControllerSearchByCategoryAndGetGeneralUploads(
        accessToken: string | undefined = undefined,
        categoryName: any,
        searchString: any,
        searchAfter?: any,
        sortOrder?: 'asc' | 'desc',
        sortBy?: '_score' | 'date',
        resultsPerPage?: any,
    ): CancelablePromise<GeneralUploadSearchResultWithEntity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/general-uploads/search-by-category-and-get-general-uploads',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'search-after': searchAfter,
                'sort-order': sortOrder,
                'sort-by': sortBy,
                'results-per-page': resultsPerPage,
                'category-name': categoryName,
                'search-string': searchString,
            },
        });
    }

    /**
     * Search for generalUploads by category and return the search hits with minimal info sufficient to differentiate the search results. The client can fetch more details afterwards leveraging on the id field.
     * @param accessToken
     * @param categoryName
     * @param searchString
     * @param searchAfter search-after=34242&search-after=4
     * @param sortOrder asc or desc
     * @param sortBy Sort by relevance, date, etc. Select from option
     * @param resultsPerPage Number of results per page
     * @returns GeneralUploadSearchResult :General uploads found
     * @throws ApiError
     */
    public static generalUploadsControllerSearchByCategory(
        accessToken: string | undefined = undefined,
        categoryName: any,
        searchString: any,
        searchAfter?: any,
        sortOrder?: 'asc' | 'desc',
        sortBy?: '_score' | 'date',
        resultsPerPage?: any,
    ): CancelablePromise<GeneralUploadSearchResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/general-uploads/search-by-category',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'search-after': searchAfter,
                'sort-order': sortOrder,
                'sort-by': sortBy,
                'results-per-page': resultsPerPage,
                'category-name': categoryName,
                'search-string': searchString,
            },
        });
    }

    /**
     * Run query against index optimised for autosuggest.
     * @param accessToken
     * @param searchString
     * @returns Suggestion :General uploads suggested
     * @throws ApiError
     */
    public static generalUploadsControllerSuggest(
        accessToken: string | undefined = undefined,
        searchString: any,
    ): CancelablePromise<Array<Suggestion>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/general-uploads/suggest',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'search-string': searchString,
            },
        });
    }

}
