/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CreateFacilityCreditAndDebitDto } from './CreateFacilityCreditAndDebitDto';
import type { CreatePaymentMethodDto } from './CreatePaymentMethodDto';
import type { CreateTokenCreditAndDebitDto } from './CreateTokenCreditAndDebitDto';

export type CreatePaymentDto = {
    installment?: boolean;
    facility?: boolean;
    facilityCreditAndDebit?: CreateFacilityCreditAndDebitDto;
    token?: boolean;
    point?: boolean;
    tokenCreditAndDebit?: CreateTokenCreditAndDebitDto;
    amountPaid: number;
    defaultCurrencyEquivalentOfAmountPaid?: number;
    currency: string;
    paymentMethodId?: number;
    paymentMethod?: CreatePaymentMethodDto;
    orderId?: number;
    /**
     * InvoiceState may be Paid, Unpaid or Cancelled. Set by server when payment succeeds.
     * Defaults to Unpaid
     */
    invoiceState?: CreatePaymentDto.invoiceState;
    /**
     * Feedback from payment processing attempt e.g. Successful
     */
    lastPaymentAttemptFeedback?: string;
    /**
     * If InvoiceState is unpaid, only proceed to shipping if payLaterIsPermitted
     * Defaults to false
     */
    payLaterPermitted?: boolean;
};

export namespace CreatePaymentDto {

    /**
     * InvoiceState may be Paid, Unpaid or Cancelled. Set by server when payment succeeds.
     * Defaults to Unpaid
     */
    export enum invoiceState {
        PAID = 'Paid',
        UNPAID = 'Unpaid',
        CANCELLED = 'Cancelled',
    }


}

