/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdatePaymentMethodDto = {
    name?: string;
    description?: string;
    paymentMethodType?: UpdatePaymentMethodDto.paymentMethodType;
    isActive?: boolean;
    currenciesSupported?: Array<string>;
    handler?: string;
    isLocked?: boolean;
};

export namespace UpdatePaymentMethodDto {

    export enum paymentMethodType {
        DEBIT_OR_CREDIT_CARD = 'debit or credit card',
        GIFT_CARD = 'gift card',
        CASH = 'cash',
        BANK_CASH_TRANSFER = 'bank cash transfer',
        CRYPTO_TRANSFER = 'crypto transfer',
        SECONDARY_PROCESSOR = 'secondary processor',
        OTHERS = 'others',
    }


}

