/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GeneralUpload = {
    name: string;
    category: GeneralUpload.category;
    description: string;
    fileName: string;
    originalFileName: string;
    fileEncoding: string;
    fileMimeType: string;
    tags: Array<string>;
    isImage: boolean;
    isAudioVisual: boolean;
    isPublished: boolean;
    id: number;
    dateCreated?: string;
    dateLastModified?: string;
};

export namespace GeneralUpload {

    export enum category {
        HOME_PAGE = 'home page',
        PRODUCT_CATEGORY = 'product category',
        GENERIC = 'generic',
        MISCELLANEOUS = 'miscellaneous',
    }


}

