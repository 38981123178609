/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Bank } from './Bank';
import type { LoyaltyStatus } from './LoyaltyStatus';

export type BankCreditFacility = {
    creditFacilityName: string;
    facilityAccountNumber: string;
    currency: BankCreditFacility.currency;
    totalCreditAllocatable: number;
    creditAvailableForAllocation: number;
    startDate: string;
    endDate: string;
    creditIssueLimit: number;
    isActive: boolean;
    bankId: number;
    bank: Bank;
    loyaltyStatuses: Array<LoyaltyStatus>;
    id: number;
    dateCreated?: string;
    dateLastModified?: string;
};

export namespace BankCreditFacility {

    export enum currency {
        NAIRA = 'Naira',
        US_DOLLAR = 'US Dollar',
        GREAT_BRITAIN_POUND = 'Great Britain Pound',
    }


}

