/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateShoppingCartItemDto } from '../models/CreateShoppingCartItemDto';
import type { ShoppingCartItem } from '../models/ShoppingCartItem';
import type { ShoppingCartItemsAndCount } from '../models/ShoppingCartItemsAndCount';
import type { UpdateShoppingCartItemDto } from '../models/UpdateShoppingCartItemDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

import { generalVersionUrlPrefix } from '../../../components/app.settings';

export class ShoppingCartItemsService {

    /**
     * Create a new shoppingCartItem
     * @param accessToken
     * @param requestBody
     * @returns ShoppingCartItem :Shopping cart item created
     * @throws ApiError
     */
    public static shoppingCartItemsControllerCreate(
        accessToken: string | undefined = undefined,
        requestBody: CreateShoppingCartItemDto,
    ): CancelablePromise<ShoppingCartItem> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/shopping-cart-items',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a shoppingCartItem. Fully replaces all fields
     * @param accessToken
     * @param requestBody
     * @returns ShoppingCartItem :Shopping cart item saved
     * @throws ApiError
     */
    public static shoppingCartItemsControllerSave(
        accessToken: string | undefined = undefined,
        requestBody: ShoppingCartItem,
    ): CancelablePromise<ShoppingCartItem> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: generalVersionUrlPrefix + '/shopping-cart-items',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all shoppingCartItems that meet the criteria specified in query options, if any.
     * @param accessToken
     * @param findOptions encodeURI(JSON.stringify({select?: string[]; relations?: string[]; skip?: number; take?: number;cache?: boolean; where?: {}[] | {}; order?: {};))
     * @returns ShoppingCartItemsAndCount Get all shopping cart items that meet the criteria specified in query options, if any. Returns array of items found, and count
     * @throws ApiError
     */
    public static shoppingCartItemsControllerFindAll(
        accessToken: string | undefined = undefined,
        findOptions?: any,
    ): CancelablePromise<ShoppingCartItemsAndCount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/shopping-cart-items',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'findOptions': findOptions,
            },
            errors: {
                400: `Bad request: likely incorrect options sent`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create one or more new shoppingCartItems in one go
     * @param accessToken
     * @param requestBody
     * @returns any ShoppingCartItems have been successfully created.
     * @throws ApiError
     */
    public static shoppingCartItemsControllerInsert(
        accessToken: string | undefined = undefined,
        requestBody: Array<string>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/shopping-cart-items/insert',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a shoppingCartItem. Only the fields sent from client will be updated
     * @param id
     * @param accessToken
     * @param requestBody
     * @returns any ShoppingCartItem has been successfully updated.
     * @throws ApiError
     */
    public static shoppingCartItemsControllerUpdate(
        id: string,
        accessToken: string | undefined = undefined,
        requestBody: UpdateShoppingCartItemDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/shopping-cart-items/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete a shoppingCartItem.
     * @param id
     * @param accessToken
     * @returns any ShoppingCartItem has been successfully deleted.
     * @throws ApiError
     */
    public static shoppingCartItemsControllerDelete(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/shopping-cart-items/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                400: `Bad request: likely shoppingCartItem does not exist`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a shoppingCartItem with the id sent as param
     * @param id
     * @param accessToken
     * @returns ShoppingCartItem :Shopping cart item found
     * @throws ApiError
     */
    public static shoppingCartItemsControllerFindOne(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<ShoppingCartItem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/shopping-cart-items/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * Set shopping cart for shopping cart item
     * @param shoppingCartItemId
     * @param shoppingCartId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static shoppingCartItemsControllerSetShoppingCartById(
        shoppingCartItemId: string,
        shoppingCartId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/shopping-cart-items/{shoppingCartItemId}/shopping-cart/{shoppingCartId}',
            path: {
                'shoppingCartItemId': shoppingCartItemId,
                'shoppingCartId': shoppingCartId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Unset shopping cart for shopping cart item
     * @param shoppingCartItemId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static shoppingCartItemsControllerUnsetShoppingCartById(
        shoppingCartItemId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/shopping-cart-items/{shoppingCartItemId}/shopping-cart',
            path: {
                'shoppingCartItemId': shoppingCartItemId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Set product for shopping cart item
     * @param shoppingCartItemId
     * @param productId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static shoppingCartItemsControllerSetProductById(
        shoppingCartItemId: string,
        productId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/shopping-cart-items/{shoppingCartItemId}/product/{productId}',
            path: {
                'shoppingCartItemId': shoppingCartItemId,
                'productId': productId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Unset product for shopping cart item
     * @param shoppingCartItemId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static shoppingCartItemsControllerUnsetProductById(
        shoppingCartItemId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/shopping-cart-items/{shoppingCartItemId}/product',
            path: {
                'shoppingCartItemId': shoppingCartItemId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

}
