/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Coupon } from './Coupon';
import type { Customer } from './Customer';
import type { CustomerAddress } from './CustomerAddress';
import type { OrderDetail } from './OrderDetail';
import type { Payment } from './Payment';
import type { Shipper } from './Shipper';
import type { TaxPayment } from './TaxPayment';
import type { Transaction } from './Transaction';

export type Order = {
    orderNumber: string;
    orderDate: string;
    requiredDate: string;
    totalBill: number;
    pendingPayment: number;
    salesTax: string;
    billDate: string;
    shipDate: string;
    shipCharge: number;
    transactionStatus: Order.transactionStatus;
    numberOfInstallmentsApplicable: number;
    paymentCompletionDate: string;
    madePayments: Array<Payment>;
    madeDiscount: Array<string>;
    customerId: number;
    customer: Customer;
    preferredShipperId: number;
    preferredShipper: Shipper;
    originatingCartId: number;
    /**
     * originatingCart: ShoppingCart;
     */
    orderDetails: Array<OrderDetail>;
    taxPayments: Array<TaxPayment>;
    transactionEntry: Transaction;
    customerAddressId: number;
    shipToAddress: CustomerAddress;
    usedCoupons: Array<Coupon>;
    shippingEnabled: boolean;
    id: number;
    dateCreated?: string;
    dateLastModified?: string;
};

export namespace Order {

    export enum transactionStatus {
        UNPAID = 'Unpaid',
        PART_PAID = 'Part Paid',
        PAID = 'Paid',
        CANCELED = 'Canceled',
        FULFILLED = 'Fulfilled',
    }


}

