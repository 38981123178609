/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateMerchantAccountOfficerDto } from '../models/CreateMerchantAccountOfficerDto';
import type { CreateMerchantDto } from '../models/CreateMerchantDto';
import type { CreateMerchantDtos } from '../models/CreateMerchantDtos';
import type { CreateMerchantTeamDto } from '../models/CreateMerchantTeamDto';
import type { CreateMerchantTeamRolesDto } from '../models/CreateMerchantTeamRolesDto';
import type { CreateUserDto } from '../models/CreateUserDto';
import type { Merchant } from '../models/Merchant';
import type { MerchantsAndCount } from '../models/MerchantsAndCount';
import type { MerchantSearchResult } from '../models/MerchantSearchResult';
import type { MerchantSearchResultWithEntity } from '../models/MerchantSearchResultWithEntity';
import type { Suggestion } from '../models/Suggestion';
import type { UpdateMerchantDto } from '../models/UpdateMerchantDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

import { generalVersionUrlPrefix } from '../../../components/app.settings';

export class MerchantsService {

    /**
     * Create a new merchant along with the primary contact. Primary contact information must be sent as CreateUserDto
     * @param accessToken
     * @param requestBody
     * @returns Merchant Merchant has been successfully created
     * @throws ApiError
     */
    public static merchantsControllerSelfCreate(
        accessToken: string | undefined = undefined,
        requestBody: CreateMerchantDto,
    ): CancelablePromise<Merchant> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/merchants/self-create',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create a new merchant. Optionally create primary contact as user if create-primary-contact query string is set to 1
     * @param accessToken
     * @param createPrimaryContact
     * @param requestBody
     * @returns Merchant Merchant has been successfully created
     * @throws ApiError
     */
    public static merchantsControllerCreate(
        accessToken: string | undefined = undefined,
        createPrimaryContact: any,
        requestBody: CreateMerchantDto,
    ): CancelablePromise<Merchant> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/merchants',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'create-primary-contact': createPrimaryContact,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a merchant. Fully replaces all fields
     * @param accessToken
     * @param requestBody
     * @returns Merchant Merchant successfully saved
     * @throws ApiError
     */
    public static merchantsControllerSave(
        accessToken: string | undefined = undefined,
        requestBody: Merchant,
    ): CancelablePromise<Merchant> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: generalVersionUrlPrefix + '/merchants',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all merchants that meet the criteria specified in query options, if any.
     * @param accessToken
     * @param findOptions encodeURI(JSON.stringify({select?: string[]; relations?: string[]; skip?: number; take?: number;cache?: boolean; where?: {}[] | {}; order?: {};}))
     * @returns MerchantsAndCount Get all merchants that meet the criteria specified in query options, if any. Returns array of merchants found, and count
     * @throws ApiError
     */
    public static merchantsControllerFindAll(
        accessToken: string | undefined = undefined,
        findOptions?: any,
    ): CancelablePromise<MerchantsAndCount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/merchants',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'findOptions': findOptions,
            },
            errors: {
                400: `Bad request: likely incorrect options sent`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create one or more new merchants in one go
     * @param accessToken
     * @param requestBody
     * @returns any Merchants have been successfully created.
     * @throws ApiError
     */
    public static merchantsControllerInsert(
        accessToken: string | undefined = undefined,
        requestBody: CreateMerchantDtos,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/merchants/insert',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a merchant. Only the fields sent from client will be updated
     * @param id
     * @param accessToken
     * @param requestBody
     * @returns any Merchant has been successfully updated.
     * @throws ApiError
     */
    public static merchantsControllerUpdate(
        id: string,
        accessToken: string | undefined = undefined,
        requestBody: UpdateMerchantDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/merchants/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete a merchant.
     * @param id
     * @param accessToken
     * @returns any Merchant has been successfully deleted.
     * @throws ApiError
     */
    public static merchantsControllerDelete(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/merchants/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                400: `Bad request: likely merchant does not exist`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a merchant with the id sent as param
     * @param id
     * @param accessToken
     * @returns Merchant Merchant found
     * @throws ApiError
     */
    public static merchantsControllerFindOne(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<Merchant> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/merchants/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * This url is for web client involving both server-side and client-side rendering
     * @param accessToken
     * @returns any Rendered web page is returned.
     * @throws ApiError
     */
    public static merchantsControllerWeb(
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/merchants/web*',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * Set the user for a given merchant
     * @param merchantId
     * @param userId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static merchantsControllerSetUserById(
        merchantId: string,
        userId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/merchants/{merchantId}/primary-contact/{userId}',
            path: {
                'merchantId': merchantId,
                'userId': userId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * @param merchantId
     * @param accessToken
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static merchantsControllerCreateAndSetPrimaryContact(
        merchantId: string,
        accessToken: string | undefined = undefined,
        requestBody: CreateUserDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/merchants/{merchantId}/primary-contact',
            path: {
                'merchantId': merchantId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param merchantId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static merchantsControllerUnsetPrimaryContactById(
        merchantId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/merchants/{merchantId}/primary-contact',
            path: {
                'merchantId': merchantId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * @param merchantId
     * @param userId
     * @param accessToken
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static merchantsControllerSetTeamMemberById(
        merchantId: string,
        userId: string,
        accessToken: string | undefined = undefined,
        requestBody: CreateMerchantTeamRolesDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/merchants/{merchantId}/team-member/{userId}',
            path: {
                'merchantId': merchantId,
                'userId': userId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param merchantId
     * @param userId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static merchantsControllerDeleteTeamMemberById(
        merchantId: string,
        userId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/merchants/{merchantId}/team-member/{userId}',
            path: {
                'merchantId': merchantId,
                'userId': userId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * @param merchantId
     * @param accessToken
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static merchantsControllerCreateAndSetTeamMember(
        merchantId: string,
        accessToken: string | undefined = undefined,
        requestBody: CreateMerchantTeamDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/merchants/{merchantId}/team-member',
            path: {
                'merchantId': merchantId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param merchantId
     * @param userId
     * @param accessToken
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static merchantsControllerSetMerchantAccountOfficerById(
        merchantId: string,
        userId: string,
        accessToken: string | undefined = undefined,
        requestBody: CreateMerchantAccountOfficerDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/merchants/{merchantId}/account-officer/{userId}',
            path: {
                'merchantId': merchantId,
                'userId': userId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param merchantId
     * @param userId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static merchantsControllerDeleteMerchantAccountOfficerById(
        merchantId: string,
        userId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/merchants/{merchantId}/account-officer/{userId}',
            path: {
                'merchantId': merchantId,
                'userId': userId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * @param merchantId
     * @param accessToken
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static merchantsControllerCreateAndSetAccountOfficer(
        merchantId: string,
        accessToken: string | undefined = undefined,
        requestBody: CreateMerchantAccountOfficerDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/merchants/{merchantId}/account-officer',
            path: {
                'merchantId': merchantId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * work on search
     * @param accessToken
     * @param searchString search string
     * @param searchAfter search-after=34242&search-after=4
     * @param sortOrder asc or desc
     * @param sortBy Sort by relevance, date, etc. Select from option
     * @param resultsPerPage Number of results per page
     * @returns MerchantSearchResultWithEntity :Merchants found
     * @throws ApiError
     */
    public static merchantsControllerSearchAndGetMerchants(
        accessToken: string | undefined = undefined,
        searchString: any,
        searchAfter?: any,
        sortOrder?: 'asc' | 'desc',
        sortBy?: '_score' | 'date',
        resultsPerPage?: any,
    ): CancelablePromise<MerchantSearchResultWithEntity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/merchants/search-and-get-merchants',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'search-string': searchString,
                'search-after': searchAfter,
                'sort-order': sortOrder,
                'sort-by': sortBy,
                'results-per-page': resultsPerPage,
            },
        });
    }

    /**
     * @param accessToken
     * @param searchString search string
     * @param searchAfter search-after=34242&search-after=4
     * @param sortOrder asc or desc
     * @param sortBy Sort by relevance, date, etc. Select from option
     * @param resultsPerPage Number of results per page
     * @returns MerchantSearchResult :Merchants found
     * @throws ApiError
     */
    public static merchantsControllerSearch(
        accessToken: string | undefined = undefined,
        searchString: any,
        searchAfter?: any,
        sortOrder?: 'asc' | 'desc',
        sortBy?: '_score' | 'date',
        resultsPerPage?: any,
    ): CancelablePromise<MerchantSearchResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/merchants/search',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'search-string': searchString,
                'search-after': searchAfter,
                'sort-order': sortOrder,
                'sort-by': sortBy,
                'results-per-page': resultsPerPage,
            },
        });
    }

    /**
     * @param accessToken
     * @param searchString search string
     * @returns Suggestion Merchant suggestions found
     * @throws ApiError
     */
    public static merchantsControllerSuggest(
        accessToken: string | undefined = undefined,
        searchString: any,
    ): CancelablePromise<Array<Suggestion>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/merchants/suggest',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'search-string': searchString,
            },
        });
    }

    /**
     * Create self as a new merchant's primary contact. Optionally create the user if create-primary-contact query string is set to 1
     * @param accessToken
     * @param createPrimaryContact 1 or 0. Defaults to 0
     * @param requestBody
     * @returns Merchant Merchant created
     * @throws ApiError
     */
    public static merchantsControllerCreateSelf(
        accessToken: string | undefined = undefined,
        createPrimaryContact: any,
        requestBody: CreateMerchantDto,
    ): CancelablePromise<Merchant> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/merchants/create-self',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'create-primary-contact': createPrimaryContact,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Merchant officer create a new merchant. Optionally create the user if create-primary-contact query string is set to 1
     * @param accessToken
     * @param createPrimaryContact 1 or 0. Defaults to 0
     * @param requestBody
     * @returns Merchant Merchant created
     * @throws ApiError
     */
    public static merchantsControllerCreateMerchantByOfficer(
        accessToken: string | undefined = undefined,
        createPrimaryContact: any,
        requestBody: CreateMerchantDto,
    ): CancelablePromise<Merchant> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/merchants/create-by-merchant-officer',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'create-primary-contact': createPrimaryContact,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Customer create a new merchant. Optionally create new user is create-primary-contact is set to 1
     * @param accessToken
     * @param createPrimaryContact 1 or 0. Defaults to 0
     * @param requestBody
     * @returns Merchant Merchant created
     * @throws ApiError
     */
    public static merchantsControllerCreateMerchantByCustomer(
        accessToken: string | undefined = undefined,
        createPrimaryContact: any,
        requestBody: CreateMerchantDto,
    ): CancelablePromise<Merchant> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/merchants/create-by-customer',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'create-primary-contact': createPrimaryContact,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get merchants by the user id of the primary contact
     * @param userId
     * @param accessToken
     * @returns Merchant
     * @throws ApiError
     */
    public static merchantsControllerGetMerchantsByPrimaryContactUserId(
        userId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<Array<Merchant>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/merchants/get-merchants-by-primary-contact-user-id/{userId}',
            path: {
                'userId': userId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Get merchants by user id of team member
     * @param userId
     * @param accessToken
     * @returns Merchant
     * @throws ApiError
     */
    public static merchantsControllerGetMerchantsByTeamMemberUserId(
        userId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<Array<Merchant>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/merchants/get-merchants-by-team-member-user-id',
            path: {
                'userId': userId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Get desired relations for a given merchant. List of relations to fetch as query, separated by commas e.g. orders,user]. Returns the merchant id along with the relations only
     * @param merchantId
     * @param sendAllMerchantFieldsAlong Set to true, if you want the whole merchant fields to be carried along i.e. send-all-merchant-fields-along=true. Else, don't add the query at all
     * @param relations List of relations to fetch separated by commas e.g. createdCoupons,primaryContact]. Returns the merchant id along with the relations only
     * @param accessToken
     * @returns any Relations successfully gotten.
     * @throws ApiError
     */
    public static merchantsControllerGetRelationsByName(
        merchantId: string,
        sendAllMerchantFieldsAlong: any,
        relations: any,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/merchants/{merchantId}/get-relations-by-name',
            path: {
                'merchantId': merchantId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'send-all-merchant-fields-along': sendAllMerchantFieldsAlong,
                'relations': relations,
            },
            errors: {
                400: `Bad request: likely, merchant does not exist`,
                500: `Internal server error`,
            },
        });
    }

}
