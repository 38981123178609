import { AuthService } from "../../../services/openapi"
import { History } from 'history';
import { RefreshAuthDto } from "../../../services/openapi/models/RefreshAuthDto";
import { LocalAuthDto } from "../../../services/openapi/models/LocalAuthDto";
import { AuthTokenPayload } from "./interfaces";
import { ErrorBody } from "./Login";
import jwt_decode from "jwt-decode";
import { generalVersionUrlPrefix } from "../../app.settings";

/* Login/Logout handlers */
export const loginHandler = async (localAuthDto: LocalAuthDto,
    returnUrl: string, history: History,
    setTokens: React.Dispatch<React.SetStateAction<{ access_token: string; refresh_token: string } | null>>,
    setState: React.Dispatch<React.SetStateAction<{ submitButtonState: string, showAlert: boolean, errorBody: ErrorBody | null }>>,
    setAuthTokenPayload: React.Dispatch<React.SetStateAction<{ payload: AuthTokenPayload | null, authComplete: boolean }>>,
    saveTokens: boolean) => {

    try {
        const tokens: { access_token: string, refresh_token: string } = await AuthService.authControllerLogin(undefined, localAuthDto);
        //success
        setTokens(tokens);
        console.log(tokens.access_token)

        if (tokens) {
            const decodedAccessToken: AuthTokenPayload = jwt_decode(tokens!.access_token);//I ran npm install jwt-decode
            localStorage.setItem("first_name", decodedAccessToken.sub.firstName); //Useful to mention welcome back. If you are not ... click here which will log the person out
            if (saveTokens) {
                //put the refresh token in localstorage. Beware of XSS
                localStorage.setItem("refresh_token", tokens.refresh_token);
                localStorage.setItem("user_id", `${decodedAccessToken.sub.id}`)
            }
            setAuthTokenPayload({ payload: decodedAccessToken, authComplete: true }); //store in state
        }
        if (returnUrl.includes('/logout') || returnUrl.includes('/login')) { //just in case login was called from the logout Url or a relogin request due to inadequate access rights
            if (returnUrl.includes('/merchant/admin')) {
                returnUrl = `${generalVersionUrlPrefix}/merchant/admin`;
            } else if (returnUrl.includes('/bank/admin')) {
                returnUrl = `${generalVersionUrlPrefix}/bank/admin`
            }
            else if (returnUrl.includes('/admin')) {
                returnUrl = `${generalVersionUrlPrefix}/admin`
            } else {
                returnUrl = `${generalVersionUrlPrefix}/`
            }
        }

        history.replace(returnUrl);
        //window.location.replace(returnUrl); //need to reload to test permission to show
    } catch (error: any) {
        setState({ submitButtonState: "", showAlert: true, errorBody: error.body })
    }
}

export const refreshHandler = async (refreshAuthDto: RefreshAuthDto, returnUrl: string, history: History, setTokens: React.Dispatch<React.SetStateAction<{ access_token: string; refresh_token: string } | null>>,
    setAuthTokenPayload: React.Dispatch<React.SetStateAction<{ payload: AuthTokenPayload | null, authComplete: boolean }>>,
    saveTokens: boolean, baseUrl: string,
    setModalLoadingIsActive: React.Dispatch<React.SetStateAction<"is-active" | null>>) => {

    try {

        setModalLoadingIsActive("is-active");
        localStorage.setItem('refresh_in_progress', 'true');

        const response = await AuthService.authControllerRefresh(undefined, refreshAuthDto);
        //const refreshToken = localStorage.getItem("refresh_token"); //using a newly generated one from server for the sake of rotation
        //success
        response && setTokens({ refresh_token: response.refresh_token, access_token: response.access_token });
        //TODO: 
        if (response) {
            const decodedAccessToken: AuthTokenPayload = jwt_decode(response.access_token);//I ran npm install jwt-decode
            localStorage.setItem("first_name", decodedAccessToken.sub.firstName); //Useful to mention welcome back. If you are not ... click here which will log the person out
            if (saveTokens) {
                //WARNING: put the refresh token in localstorage. Beware of XSS
                localStorage.setItem("refresh_token", response.refresh_token);
                localStorage.setItem("user_id", `${decodedAccessToken.sub.id}`)
            }

            setAuthTokenPayload({ payload: decodedAccessToken, authComplete: true }); //store in state
        }
        localStorage.removeItem('refresh_in_progress');
        if (history.location.pathname.includes('/list') || history.location.pathname.includes('/detail') || history.location.pathname.includes('/checkout') || history.location.pathname.includes('/cart'))
            history.replace(`${baseUrl}/search`)

        if (history.location.pathname.includes('/login'))
            history.replace(`${baseUrl}/`);
        /*   else
              history.replace(history.location.pathname.replace("/login", "")); //Added by Joseph. This fixes the issue of the login page being displayed after a refresh
           */
        //history.replace(returnUrl);

        setModalLoadingIsActive(null);
    } catch (error: any) {
        localStorage.removeItem('refresh_in_progress');
        //clear localStorage of failed refresh_token and redirect to login page
        localStorage.removeItem('refresh_token');

        if (returnUrl.includes('/admin')) {
            history.replace(`${generalVersionUrlPrefix}/admin/login`, JSON.stringify({ next: history.location.pathname, headerInfo: 'Please login again' }));
        } else {
            history.replace(`${generalVersionUrlPrefix}/login`, JSON.stringify({ next: history.location.pathname, headerInfo: 'Please login again' }));

        }
        setModalLoadingIsActive(null);
    }
}

export const logoutHandler = async (completely: boolean, returnUrl: string, history: History,
    setTokens: React.Dispatch<React.SetStateAction<{ access_token: string; refresh_token: string } | null>>,
    setAuthTokenPayload: React.Dispatch<React.SetStateAction<{ payload: AuthTokenPayload | null, authComplete: boolean }>>,
    clientDeviceFingerPrint: string | null, adminMode: boolean) => {
    try {
        await AuthService.authControllerLogout(undefined, { clientDeviceFingerPrint });
        //success
        localStorage.removeItem('refresh_token');
        if (completely) {
            localStorage.removeItem('first_name');
            localStorage.removeItem('cart');
        }
        setTokens(null);
        setAuthTokenPayload({ payload: null, authComplete: false });
        if (adminMode) {
            if (returnUrl.includes('/bank/admin')) {
                history.replace(`${generalVersionUrlPrefix}/bank`);
            } else {
                history.replace(`${generalVersionUrlPrefix}/merchant`);
            }
        } else {
            history.replace(`${generalVersionUrlPrefix}/`); //Go to the public home page
        }

    } catch (error: any) {
    }
}