/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateTokenCreditAndDebitDto = {
    transactionDate?: string;
    type?: CreateTokenCreditAndDebitDto.type;
    description?: string;
    loyaltyBenefitId?: number;
};

export namespace CreateTokenCreditAndDebitDto {

    export enum type {
        CREDIT = 'credit',
        DEBIT = 'debit',
    }


}

