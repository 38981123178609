/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatePickupLocationDto } from '../models/CreatePickupLocationDto';
import type { PickupLocation } from '../models/PickupLocation';
import type { UpdatePickupLocationDto } from '../models/UpdatePickupLocationDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

import { generalVersionUrlPrefix } from '../../../components/app.settings';

export class PickupLocationsService {

    /**
     * Create a new pickup location
     * @param accessToken
     * @param requestBody
     * @returns PickupLocation Customer pickup location created
     * @returns any Pickup Location has been successfully created.
     * @throws ApiError
     */
    public static pickupLocationsControllerCreate(
        accessToken: string | undefined = undefined,
        requestBody: CreatePickupLocationDto,
    ): CancelablePromise<PickupLocation | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/pickup-locations',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update an pickup location. Fully replaces all fields
     * @param accessToken
     * @param requestBody
     * @returns any Pickup Location has been successfully updated.
     * @throws ApiError
     */
    public static pickupLocationsControllerSave(
        accessToken: string | undefined = undefined,
        requestBody: PickupLocation,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: generalVersionUrlPrefix + '/pickup-locations',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all pickup locations that meet the criteria specified in query options, if any.
     * @param accessToken
     * @param findOptions encodeURI(JSON.stringify({select?: string[]; relations?: string[]; skip?: number; take?: number;cache?: boolean; where?: {}[] | {}; order?: {};))
     * @returns any Pickup Locations returned.
     * @throws ApiError
     */
    public static pickupLocationsControllerFindAll(
        accessToken: string | undefined = undefined,
        findOptions?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/pickup-locations',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'findOptions': findOptions,
            },
            errors: {
                400: `Bad request: likely incorrect options sent`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create one or more new pickup locations in one go
     * @param accessToken
     * @param requestBody
     * @returns any Pickup Locations have been successfully created.
     * @throws ApiError
     */
    public static pickupLocationsControllerInsert(
        accessToken: string | undefined = undefined,
        requestBody: Array<string>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/pickup-locations/insert',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update an pickup location. Only the fields sent from client will be updated
     * @param id
     * @param accessToken
     * @param requestBody
     * @returns any Pickup Location has been successfully updated.
     * @throws ApiError
     */
    public static pickupLocationsControllerUpdate(
        id: string,
        accessToken: string | undefined = undefined,
        requestBody: UpdatePickupLocationDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/pickup-locations/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete an pickup location.
     * @param id
     * @param accessToken
     * @returns any Pickup Location has been successfully deleted.
     * @throws ApiError
     */
    public static pickupLocationsControllerDelete(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/pickup-locations/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                400: `Bad request: likely pickup location does not exist`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get an pickup location with the id sent as param
     * @param id
     * @param accessToken
     * @returns any Pickup Location returned.
     * @throws ApiError
     */
    public static pickupLocationsControllerFindOne(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/pickup-locations/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param pickupLocationId
     * @param taxRegionId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static pickupLocationsControllerSetTaxRegionById(
        pickupLocationId: string,
        taxRegionId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/pickup-locations/{pickupLocationId}/tax-region/{taxRegionId}',
            path: {
                'pickupLocationId': pickupLocationId,
                'taxRegionId': taxRegionId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * @param pickupLocationId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static pickupLocationsControllerUnsetTaxRegionById(
        pickupLocationId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/pickup-locations/{pickupLocationId}/tax-region',
            path: {
                'pickupLocationId': pickupLocationId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Optionally pass latitude and longitude; zip; countyOrLga. But state and country must be passed by name.
     * latitude and longitude takes precedence over zip and zip in turn takes precedence over countyOrLga. When none of these are passed, state and country alone are used.
     * @param accessToken
     * @param country country name is required.
     * @param state state name is required.
     * @param countyOrLga countyOrLga name, if available. Not used if latitude and longitude or zip, are provided
     * @param zip Zip, if available. Not used if latitude and longitude are provided
     * @param longitude longitude, if available. Must be provided with latitude
     * @param latitude latitude, if available. Must be provided with longitude
     * @returns PickupLocation Pickup Location suggestions
     * @throws ApiError
     */
    public static pickupLocationsControllerGetPickupLocationSuggestions(
        accessToken: string | undefined = undefined,
        country: any,
        state: any,
        countyOrLga?: any,
        zip?: any,
        longitude?: any,
        latitude?: any,
    ): CancelablePromise<Array<PickupLocation>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/pickup-locations/get-pickup-location-suggestions',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'country': country,
                'state': state,
                'countyOrLga': countyOrLga,
                'zip': zip,
                'longitude': longitude,
                'latitude': latitude,
            },
        });
    }

}
