/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateLoyaltyStatusDto = {
    /**
     * e.g. 1, 2, 3, 4, …
     */
    loyaltyLevel?: number;
    /**
     * General information on criteria for assigning level
     * If not set, default is used
     */
    criteriaForAssigningLevel?: string;
    /**
     * e.g. bronze-token, silver-token, gold-token, platinum-token, platinum-all, ...
     */
    statusName?: string;
    /**
     * Is token issue applicable to this loyalty status?
     * Defaults to false;
     */
    tokenEnabled?: boolean;
    /**
     * If token, how much each unit is worth in store default currency.
     * Defaults to 0
     */
    tokenUnitWorthInStoreDefaultCurrency?: number;
    /**
     * Is point issue applicable to this loyalty status?
     * Defaults to false;
     */
    pointEnabled?: boolean;
    /**
     * If token, how much each unit is worth in store default currency.
     * Defaults to 0
     */
    pointUnitWorthInStoreDefaultCurrency?: number;
    /**
     * Is discount applicable to this loyalty status?
     * Defaults to false
     */
    discountEnabled?: boolean;
    /**
     * Is credit applicable to this loyalty status?
     * Defaults to false
     */
    creditEnabled?: boolean;
    /**
     * Is installmental payment applicable to this loyalty status?
     * Defaults to false
     */
    installmentEnabled?: boolean;
    /**
     * Is this loyalty status triggered for customer benefit due to transaction volue?
     * Defaults to true;
     */
    autoTriggeredByCustomerTransactionVolume?: boolean;
    /**
     * What minimum volume of transaction in default currency is required to trigger status?
     * Leaving this at default 0 means that transaction volume is not necessary. Repeat transaction is sufficient.
     */
    transactionVolumeLevelForAutoTrigger?: number;
    /**
     * Over what maximum period will transaction volume trigger be accummulatively assessed?
     * Defaults to TransactionVolumeLevelForAutoTriggerPeriod.O (i.e. not accummulated over any period).
     */
    transactionVolumeLevelForAutoTriggerPeriod?: UpdateLoyaltyStatusDto.transactionVolumeLevelForAutoTriggerPeriod;
    /**
     * How many repeats of the transaction volume is required to trigger status?
     * Defaults to 1, which means one repeat is required by default.
     */
    transactionRepeatCountForAutoTrigger?: number;
    /**
     * What is the default lifespan of the status from the day of application to customer as benefit
     * Defaults to -1 which means that it never expires. As long as the benefit is active, it can be used by customer
     */
    defaultLifeSpanOfLoyaltyStatusInDays?: number;
    /**
     * Enumerated discount formula types applicable only in tenant-wide loyalty status
     * If not set, default is used
     */
    discountingFormula?: UpdateLoyaltyStatusDto.discountingFormula;
    /**
     * Enumerated credit formula types applicable
     * If not set, default is used
     */
    creditFormula?: UpdateLoyaltyStatusDto.creditFormula;
    /**
     * Enumerated installmental formula types applicable
     * If not set, default is used
     */
    installmentPaymentFormula?: UpdateLoyaltyStatusDto.installmentPaymentFormula;
    /**
     * Defaults to false
     */
    isApproved?: boolean;
    merchantSpecific?: boolean;
    merchantId?: number;
    merchantSpecificDiscountRate?: number;
    merchantSpecificMinimumSpendingForDiscount?: number;
    merchantSpecificInstallmentPaymentCount?: number;
    merchantSpecificMinimumSpendingForInstallmentPayment?: number;
    merchantSpecificCredit?: number;
    merchantSpecificMinimumSpendingForCredit?: number;
};

export namespace UpdateLoyaltyStatusDto {

    /**
     * Over what maximum period will transaction volume trigger be accummulatively assessed?
     * Defaults to TransactionVolumeLevelForAutoTriggerPeriod.O (i.e. not accummulated over any period).
     */
    export enum transactionVolumeLevelForAutoTriggerPeriod {
        PER_DAY = 'Per Day',
        PER_WEEK = 'Per Week',
        PER_MONTH = 'Per Month',
        PER_YEAR = 'Per Year',
        ONCE = 'Once',
    }

    /**
     * Enumerated discount formula types applicable only in tenant-wide loyalty status
     * If not set, default is used
     */
    export enum discountingFormula {
        _5_OF_PURCHASE = '5% of purchase',
        _10_OF_PURCHASE = '10% of purchase',
        _5_OF_PURCHASE_WITH_MINIMUM_OF_10_000_NAIRA_SPENT = '5% of purchase with minimum of 10,000 naira spent',
    }

    /**
     * Enumerated credit formula types applicable
     * If not set, default is used
     */
    export enum creditFormula {
        _5_OF_PURCHASE = '5% of purchase',
        _10_OF_PURCHASE = '10% of purchase',
        _5_OF_PURCHASE_WITH_MINIMUM_OF_10_000_NAIRA_SPENT = '5% of purchase with minimum of 10,000 naira spent',
    }

    /**
     * Enumerated installmental formula types applicable
     * If not set, default is used
     */
    export enum installmentPaymentFormula {
        TWO_INSTALLMENTS = 'Two installments',
        THREE_INSTALLMENT = 'Three installment',
    }


}

