/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateCustomerFeedbackDto } from '../models/CreateCustomerFeedbackDto';
import type { CustomerFeedback } from '../models/CustomerFeedback';
import type { CustomerFeedbacksAndCount } from '../models/CustomerFeedbacksAndCount';
import type { UpdateCustomerFeedbackDto } from '../models/UpdateCustomerFeedbackDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

import { generalVersionUrlPrefix } from '../../../components/app.settings';

export class CustomerFeedbackService {

    /**
     * Create a new customer feedback. Feedback will immediately be associated with customer or product if their respective ids and included in the dto
     * @param accessToken
     * @param requestBody
     * @returns CustomerFeedback :Customer feedback created
     * @throws ApiError
     */
    public static customerFeedbackControllerCreate(
        accessToken: string | undefined = undefined,
        requestBody: CreateCustomerFeedbackDto,
    ): CancelablePromise<CustomerFeedback> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/customer-feedback',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get all customer feedbacks that meet the criteria specified in query options, if any.
     * @param accessToken
     * @param findOptions encodeURI(JSON.stringify({select?: string[]; relations?: string[]; skip?: number; take?: number;cache?: boolean; where?: {}[] | {}; order?: {};))
     * @returns CustomerFeedbacksAndCount Get all customer feedback that meet the criteria specified in query options, if any. Returns array of customer feedbacks found, and count
     * @throws ApiError
     */
    public static customerFeedbackControllerFindAll(
        accessToken: string | undefined = undefined,
        findOptions?: any,
    ): CancelablePromise<CustomerFeedbacksAndCount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/customer-feedback',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'findOptions': findOptions,
            },
            errors: {
                400: `Bad request: likely incorrect options sent`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Find a customer feedback by id
     * @param id
     * @param accessToken
     * @returns CustomerFeedback :Customer feedback found
     * @throws ApiError
     */
    public static customerFeedbackControllerFindOne(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<CustomerFeedback> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/customer-feedback/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Update a customer feedback by id
     * @param id
     * @param accessToken
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static customerFeedbackControllerUpdate(
        id: string,
        accessToken: string | undefined = undefined,
        requestBody: UpdateCustomerFeedbackDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: generalVersionUrlPrefix + '/customer-feedback/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete a customer feedback by id
     * @param id
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static customerFeedbackControllerDelete(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/customer-feedback/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Set the customer for a customer feedback
     * @param customerFeedbackId
     * @param customerId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static customerFeedbackControllerSetCustomerById(
        customerFeedbackId: string,
        customerId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/customer-feedback/{customerFeedbackId}/customer/{customerId}',
            path: {
                'customerFeedbackId': customerFeedbackId,
                'customerId': customerId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Unset a customer for a customer feedback
     * @param customerFeedbackId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static customerFeedbackControllerUnsetCustomerById(
        customerFeedbackId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/customer-feedback/{customerFeedbackId}/customer',
            path: {
                'customerFeedbackId': customerFeedbackId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Set a product for a customer feedback
     * @param customerFeedbackId
     * @param productId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static customerFeedbackControllerSetProductById(
        customerFeedbackId: string,
        productId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/customer-feedback/{customerFeedbackId}/product/{productId}',
            path: {
                'customerFeedbackId': customerFeedbackId,
                'productId': productId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Unset a product for a customer feedback
     * @param customerFeedbackId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static customerFeedbackControllerUnsetProductById(
        customerFeedbackId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/customer-feedback/{customerFeedbackId}/product',
            path: {
                'customerFeedbackId': customerFeedbackId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * @param productId
     * @param accessToken
     * @param sortOrder 'ASC' | 'DESC'. Defaults to 'DESC'
     * @param orderBy 'date' | 'rating' | 'helpfulness' | 'likes'. Defaults to ''date
     * @param skip offset of results to return
     * @param take Number of results per page
     * @returns CustomerFeedbacksAndCount :Customer Feedbacks found
     * @throws ApiError
     */
    public static customerFeedbackControllerGetCustomerFeedbacksByProductId(
        productId: string,
        accessToken: string | undefined = undefined,
        sortOrder?: any,
        orderBy?: any,
        skip?: any,
        take?: any,
    ): CancelablePromise<CustomerFeedbacksAndCount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/customer-feedback/get-customer-feedbacks-by-product/{productId}',
            path: {
                'productId': productId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'sort-order': sortOrder,
                'order-by': orderBy,
                'skip': skip,
                'take': take,
            },
        });
    }

}
