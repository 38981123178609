/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateUserDto } from '../models/CreateUserDto';
import type { CreateUserDtos } from '../models/CreateUserDtos';
import type { FileUploadDto } from '../models/FileUploadDto';
import type { GenericBulmaNotificationResponseDto } from '../models/GenericBulmaNotificationResponseDto';
import type { Suggestion } from '../models/Suggestion';
import type { UpdateUserDto } from '../models/UpdateUserDto';
import type { User } from '../models/User';
import type { UsersAndCount } from '../models/UsersAndCount';
import type { UserSearchResult } from '../models/UserSearchResult';
import type { UserSearchResultWithEntity } from '../models/UserSearchResultWithEntity';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

import { generalVersionUrlPrefix } from '../../../components/app.settings';

export class UsersService {

    /**
     * Create a new user
     * @param accessToken
     * @param requestBody
     * @returns User User successfully created
     * @throws ApiError
     */
    public static usersControllerCreate(
        accessToken: string | undefined = undefined,
        requestBody: CreateUserDto,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/users',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a user. Fully replaces all fields
     * @param accessToken
     * @param requestBody
     * @returns User User successfully saved
     * @throws ApiError
     */
    public static usersControllerSave(
        accessToken: string | undefined = undefined,
        requestBody: User,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: generalVersionUrlPrefix + '/users',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all users that meet the criteria specified in query options, if any.
     * @param accessToken
     * @param findOptions encodeURI(JSON.stringify({select?: string[]; relations?: string[]; skip?: number; take?: number;cache?: boolean; where?: {}[] | {}; order?: {};))
     * @returns UsersAndCount Get all users that meet the criteria specified in query options, if any. Returns array of items found, and count
     * @throws ApiError
     */
    public static usersControllerFindAll(
        accessToken: string | undefined = undefined,
        findOptions?: any,
    ): CancelablePromise<UsersAndCount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/users',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'findOptions': findOptions,
            },
            errors: {
                400: `Bad request: likely incorrect options sent`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create one or more new users in one go
     * @param accessToken
     * @param requestBody
     * @returns any Users have been successfully created.
     * @throws ApiError
     */
    public static usersControllerInsert(
        accessToken: string | undefined = undefined,
        requestBody: CreateUserDtos,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/users/insert',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a user. Only the fields sent from client will be updated
     * @param id
     * @param accessToken
     * @param requestBody
     * @returns any User has been successfully updated.
     * @throws ApiError
     */
    public static usersControllerUpdate(
        id: string,
        accessToken: string | undefined = undefined,
        requestBody: UpdateUserDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/users/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete a user.
     * @param id
     * @param accessToken
     * @returns any User has been successfully deleted.
     * @throws ApiError
     */
    public static usersControllerDelete(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/users/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                400: `Bad request: likely user does not exist`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a user with the id sent as param
     * @param id
     * @param accessToken
     * @returns User User found
     * @throws ApiError
     */
    public static usersControllerFindOne(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/users/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a user with the sent primary email address as param
     * @param primaryEmailAddress
     * @param accessToken
     * @returns User User found
     * @throws ApiError
     */
    public static usersControllerGetByPrimaryEmailAddress(
        primaryEmailAddress: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/users/get-user-by-primary-email/{primaryEmailAddress}',
            path: {
                'primaryEmailAddress': primaryEmailAddress,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * This url is for web client involving both server-side and client-side rendering
     * @param accessToken
     * @returns any Rendered web page is returned.
     * @throws ApiError
     */
    public static usersControllerWeb(
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/users/web*',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * Some user perculiarities
     * Set the password of a user with userId
     * @param userId
     * @param accessToken
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static usersControllerSetUserPassword(
        userId: string,
        accessToken: string | undefined = undefined,
        requestBody: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/users/{userId}/set-password',
            path: {
                'userId': userId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Upload photo of a user with userId
     * @param userId
     * @param accessToken
     * @param formData User photo
     * @returns any
     * @throws ApiError
     */
    public static usersControllerSetUserPhoto(
        userId: string,
        accessToken: string | undefined = undefined,
        formData: FileUploadDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/users/{userId}/photo',
            path: {
                'userId': userId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * Get the photo of a user. This link is used on client side for image URL
     * @param userId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static usersControllerGetUserPhoto(
        userId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/users/{userId}/photo',
            path: {
                'userId': userId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Receive reset password request. Calls service to generate and send request token
     * @param accessToken
     * @param requestBody
     * @returns GenericBulmaNotificationResponseDto
     * @throws ApiError
     */
    public static usersControllerResetPasswordRequest(
        accessToken: string | undefined = undefined,
        requestBody: string,
    ): CancelablePromise<GenericBulmaNotificationResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/users/reset-password-request',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Reset password. Calls service to validate the token received and reset password. The Get here
     * receives request from browser, no password sent, thus a form will be sent
     * @param token
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static usersControllerResetPassword1(
        token: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/users/reset-password/{token}',
            path: {
                'token': token,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * This receives request from form. Password is sent in body
     * @param token
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static usersControllerResetPassword2(
        token: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/users/reset-password/{token}',
            path: {
                'token': token,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Receives request to confirm primary email address of user with userId. Calls service to send a token.
     * @param userId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static usersControllerConfirmPrimaryEmailRequest(
        userId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/users/{userId}/confirm-primary-email-request',
            path: {
                'userId': userId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Receives request to confirm backup email address of user with userId. Calls service to send a token.
     * @param userId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static usersControllerConfirmBackupEmailRequest(
        userId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/users/{userId}/confirm-backup-email-request',
            path: {
                'userId': userId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Called to confirm primary email address. Passes token to service to validate and then confirm email
     * @param token
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static usersControllerConfirmPrimaryEmail(
        token: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/users/confirm-primary-email/{token}',
            path: {
                'token': token,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Called to confirm primary email address. Passes token to service to validate and then confirm email
     * @param token
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static usersControllerConfirmBackupEmail(
        token: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/users/confirm-backup-email/{token}',
            path: {
                'token': token,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Search users and check users found in database and return full user objects
     * @param accessToken
     * @param searchString
     * @param searchAfter search-after=34242&search-after=4
     * @param sortOrder asc or desc
     * @param sortBy Sort by relevance, date, etc. Select from option
     * @param resultsPerPage Number of results per page
     * @returns UserSearchResultWithEntity :Users found
     * @throws ApiError
     */
    public static usersControllerSearchAndGetUsers(
        accessToken: string | undefined = undefined,
        searchString: any,
        searchAfter?: any,
        sortOrder?: 'asc' | 'desc',
        sortBy?: '_score' | 'date',
        resultsPerPage?: any,
    ): CancelablePromise<UserSearchResultWithEntity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/users/search-and-get-users',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'search-after': searchAfter,
                'sort-order': sortOrder,
                'sort-by': sortBy,
                'results-per-page': resultsPerPage,
                'search-string': searchString,
            },
        });
    }

    /**
     * Search users and return elastic search hits. Client takes responsibility for the rest. Useful for listing search results with links to fetch details afterwards
     * @param accessToken
     * @param searchString
     * @param searchAfter search-after=34242&search-after=4
     * @param sortOrder asc or desc
     * @param sortBy Sort by relevance, date, etc. Select from option
     * @param resultsPerPage Number of results per page
     * @returns UserSearchResult :Users found
     * @throws ApiError
     */
    public static usersControllerSearch(
        accessToken: string | undefined = undefined,
        searchString: any,
        searchAfter?: any,
        sortOrder?: 'asc' | 'desc',
        sortBy?: '_score' | 'date',
        resultsPerPage?: any,
    ): CancelablePromise<UserSearchResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/users/search',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'search-after': searchAfter,
                'sort-order': sortOrder,
                'sort-by': sortBy,
                'results-per-page': resultsPerPage,
                'search-string': searchString,
            },
        });
    }

    /**
     * Suggest users that match search string. Algorithm optimized for autocomplete fields
     * @param accessToken
     * @param searchString
     * @returns Suggestion User suggestions
     * @throws ApiError
     */
    public static usersControllerSuggest(
        accessToken: string | undefined = undefined,
        searchString: any,
    ): CancelablePromise<Array<Suggestion>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/users/suggest',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'search-string': searchString,
            },
        });
    }

    /**
     * Assign role with roleId to user with userId.
     * @param userId
     * @param roleId
     * @param accessToken
     * @returns any Role assigned to user successfully.
     * @throws ApiError
     */
    public static usersControllerAddRoleById(
        userId: string,
        roleId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/users/{userId}/roles/{roleId}',
            path: {
                'userId': userId,
                'roleId': roleId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                400: `Bad request: likely, user or role does not exist`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Remove role with roleId from user with userId
     * @param userId
     * @param roleId
     * @param accessToken
     * @returns any Role removed from user successfully.
     * @throws ApiError
     */
    public static usersControllerRemoveRoleById(
        userId: string,
        roleId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/users/{userId}/roles/{roleId}',
            path: {
                'userId': userId,
                'roleId': roleId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                400: `Bad request: likely, user or role does not exist`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Assign multiple roles to user with userId. The query should contain an array of roleIds in query key named roleid e.g. ?roleid=1&roleid=2&roleid=3...
     * @param userId
     * @param accessToken
     * @returns any Roles assigned to user successfully.
     * @throws ApiError
     */
    public static usersControllerAddRolesById(
        userId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/users/{userId}/roles',
            path: {
                'userId': userId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                400: `Bad request: likely, user or role(s) does not exist`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Remove multiple roles from user with userId. The query should contain an array of roleIds in query key named roleid e.g. ?roleid=1&roleid=2&roleid=3...
     * @param userId
     * @param accessToken
     * @returns any Roles removed from user successfully.
     * @throws ApiError
     */
    public static usersControllerRemoveRolesById(
        userId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/users/{userId}/roles',
            path: {
                'userId': userId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                400: `Bad request: likely, user or role(s) does not exist`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get desired relations for a given user. List of relations to fetch as query, separated by commas e.g. primaryContactForWhichMerchants,customer]. Returns the user id along with the relations only
     * @param userId
     * @param sendAllUserFieldsAlong Set to true, if you want the whole user fields to be carried along i.e. send-all-user-fields-along=true. Else, don't add the query at all
     * @param relations List of relations to fetch separated by commas e.g. orders,user]. Returns the user id along with the relations only
     * @param accessToken
     * @returns any Relations successfully gotten.
     * @throws ApiError
     */
    public static usersControllerGetRelationsByName(
        userId: string,
        sendAllUserFieldsAlong: any,
        relations: any,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/users/{userId}/get-relations-by-name',
            path: {
                'userId': userId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'send-all-user-fields-along': sendAllUserFieldsAlong,
                'relations': relations,
            },
            errors: {
                400: `Bad request: likely, user does not exist`,
                500: `Internal server error`,
            },
        });
    }

}
