import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import Home from './components/apps/ecommerce-home/home/Home';
import './components/global/i18n/i18n';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { generalVersionUrlPrefix } from './components/app.settings';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      {/* Comment: The extra styled div here below is to force footer to always be at the bottom 
      even if content is not much. See https://philipwalton.github.io/solved-by-flexbox/demos/sticky-footer/
      In addition, remember to put a section always for main contain between navigation and footer with flex: 1
      */}
      <div style={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
        <Home baseUrl={`${generalVersionUrlPrefix}`}/>
      </div>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
