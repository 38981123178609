/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateTaxRegionDto } from '../models/CreateTaxRegionDto';
import type { TaxRegion } from '../models/TaxRegion';
import type { TaxRegionsAndCount } from '../models/TaxRegionsAndCount';
import type { UpdateTaxRegionDto } from '../models/UpdateTaxRegionDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

import { generalVersionUrlPrefix } from '../../../components/app.settings';

export class TaxRegionsService {

    /**
     * Create a new tax region
     * @param accessToken
     * @param requestBody
     * @returns TaxRegion :Tax region created
     * @throws ApiError
     */
    public static taxRegionsControllerCreate(
        accessToken: string | undefined = undefined,
        requestBody: CreateTaxRegionDto,
    ): CancelablePromise<TaxRegion> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/tax-regions',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a taxRegion. Fully replaces all fields
     * @param accessToken
     * @param requestBody
     * @returns TaxRegion :Tax region saved
     * @throws ApiError
     */
    public static taxRegionsControllerSave(
        accessToken: string | undefined = undefined,
        requestBody: TaxRegion,
    ): CancelablePromise<TaxRegion> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: generalVersionUrlPrefix + '/tax-regions',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all taxRegions that meet the criteria specified in query options, if any.
     * @param accessToken
     * @param findOptions encodeURI(JSON.stringify({select?: string[]; relations?: string[]; skip?: number; take?: number;cache?: boolean; where?: {}[] | {}; order?: {};))
     * @returns TaxRegionsAndCount Get all tax regions that meet the criteria specified in query options, if any. Returns array of items found, and count
     * @throws ApiError
     */
    public static taxRegionsControllerFindAll(
        accessToken: string | undefined = undefined,
        findOptions?: any,
    ): CancelablePromise<TaxRegionsAndCount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/tax-regions',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'findOptions': findOptions,
            },
            errors: {
                400: `Bad request: likely incorrect options sent`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create one or more new taxRegions in one go
     * @param accessToken
     * @param requestBody
     * @returns any TaxRegions have been successfully created.
     * @throws ApiError
     */
    public static taxRegionsControllerInsert(
        accessToken: string | undefined = undefined,
        requestBody: Array<string>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/tax-regions/insert',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a taxRegion. Only the fields sent from client will be updated
     * @param id
     * @param accessToken
     * @param requestBody
     * @returns any TaxRegion has been successfully updated.
     * @throws ApiError
     */
    public static taxRegionsControllerUpdate(
        id: string,
        accessToken: string | undefined = undefined,
        requestBody: UpdateTaxRegionDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/tax-regions/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete a taxRegion.
     * @param id
     * @param accessToken
     * @returns any TaxRegion has been successfully deleted.
     * @throws ApiError
     */
    public static taxRegionsControllerDelete(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/tax-regions/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                400: `Bad request: likely taxRegion does not exist`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a taxRegion with the id sent as param
     * @param id
     * @param accessToken
     * @returns TaxRegion :Tax region found
     * @throws ApiError
     */
    public static taxRegionsControllerFindOne(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<TaxRegion> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/tax-regions/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get tax regions by country and state names.
     * @param state
     * @param country
     * @param accessToken
     * @returns TaxRegion
     * @throws ApiError
     */
    public static taxRegionsControllerGetTaxRegionsByCountryAndState(
        state: string,
        country: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<Array<TaxRegion>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/tax-regions/get-tax-regions-by-country-and-state/{country}/{state}',
            path: {
                'state': state,
                'country': country,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

}
