/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateGeneralUploadDto = {
    file?: Blob;
    fileName: string;
    name?: string;
    cat?: UpdateGeneralUploadDto.cat;
    descr?: string;
    tags?: Array<string>;
    isImage?: boolean;
    isAV?: boolean;
    isPub?: boolean;
};

export namespace UpdateGeneralUploadDto {

    export enum cat {
        HOME_PAGE = 'home page',
        PRODUCT_CATEGORY = 'product category',
        GENERIC = 'generic',
        MISCELLANEOUS = 'miscellaneous',
    }


}

