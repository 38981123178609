/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Product } from './Product';

export type ProductLike = {
    natureOfLike: ProductLike.natureOfLike;
    customerId: number;
    customerFirstName: string;
    customerLastName: string;
    product: Product;
    id: number;
    dateCreated?: string;
    dateLastModified?: string;
};

export namespace ProductLike {

    export enum natureOfLike {
        LIKE = 'like',
        LOVE = 'love',
        WOW = 'wow',
    }


}

