/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MultipleSearchResult } from '../models/MultipleSearchResult';
import type { Suggestion } from '../models/Suggestion';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

import { generalVersionUrlPrefix } from '../../../components/app.settings';

export class DefaultService {

    /**
     * Below is used to send requests to built statically served Web frontend built with create-react-app
     * @returns any
     * @throws ApiError
     */
    public static appControllerSendWebFrontEnd(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static appControllerGetHead(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'HEAD',
            url: '/v1',
        });
    }

    /**
     * Below is used to send requests to built statically served Web frontend built with create-react-app
     * @returns any
     * @throws ApiError
     */
    public static appControllerSendWebFrontEnd1(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/*',
        });
    }

    /**
     * @ApiParam({name: 'accessToken', type: String, allowEmptyValue: true })
     *
     * async home(@Req() req: Request, @Res() reply: Reply) {
         *
         *
         *
         *
         * const tenantThemeProperties = await this.appService.getTenantThemeProperties(req);
         *
         * const searchBar = renderToString(<this.SearchBar />);
         *
         *
         * await reply.view('guest-website/ecommerce-home.html',
         * {
             * apiVersion: API_VERSION !== null ? `${API_VERSION}` : '',
             * title: APP_NAME, homeActive: 'true', currentUrlSlug: API_VERSION !== null ? `/${API_VERSION}` : '/',
             * tenantCssUrl: tenantThemeProperties.cssUrl,
             * tenantJsUrl: tenantThemeProperties.jsUrl,
             * searchBar
             * });
             *
             * }
             * @param accessToken
             * @returns any
             * @throws ApiError
             */
            public static appControllerEcommerceHome(
                accessToken: string | undefined = undefined,
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: generalVersionUrlPrefix + '/ecommerce-home',
                    path: {
                        'accessToken': accessToken,
                    },
                });
            }

            /**
             * @param accessToken
             * @returns any
             * @throws ApiError
             */
            public static appControllerGetLogo(
                accessToken: string | undefined = undefined,
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: generalVersionUrlPrefix + '/logo',
                    path: {
                        'accessToken': accessToken,
                    },
                });
            }

            /**
             * @param accessToken
             * @param searchString
             * @param searchAfter search-after=34242&search-after=4
             * @param sortOrder asc or desc
             * @param sortBy Sort by relevance, date, etc. Select from option
             * @param resultsPerPage Number of results per page
             * @param indexesToSearch
             * @returns MultipleSearchResult Items found
             * @throws ApiError
             */
            public static appControllerSearch(
                accessToken: string | undefined = undefined,
                searchString: any,
                searchAfter?: any,
                sortOrder?: 'asc' | 'desc',
                sortBy?: '_score' | 'date',
                resultsPerPage?: any,
                indexesToSearch?: Array<'users' | 'products' | 'merchants' | 'customers' | 'employees' | 'general_uploads' | 'customer_issues'>,
            ): CancelablePromise<Array<MultipleSearchResult>> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: generalVersionUrlPrefix + '/search',
                    path: {
                        'accessToken': accessToken,
                    },
                    query: {
                        'search-after': searchAfter,
                        'sort-order': sortOrder,
                        'sort-by': sortBy,
                        'results-per-page': resultsPerPage,
                        'indexes-to-search': indexesToSearch,
                        'search-string': searchString,
                    },
                });
            }

            /**
             * @param accessToken
             * @param searchString
             * @param indexesToSearch
             * @returns Suggestion Suggestions found
             * @throws ApiError
             */
            public static appControllerSuggest(
                accessToken: string | undefined = undefined,
                searchString: any,
                indexesToSearch?: Array<'users' | 'products' | 'merchants' | 'customers' | 'employees' | 'general_uploads' | 'customer_issues'>,
            ): CancelablePromise<Array<Suggestion>> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: generalVersionUrlPrefix + '/suggest',
                    path: {
                        'accessToken': accessToken,
                    },
                    query: {
                        'indexes-to-search': indexesToSearch,
                        'search-string': searchString,
                    },
                });
            }

            /**
             * @param accessToken
             * @param indexesToDelete NOTE: Irreversible, only available at development time! INDEXES DELETED WILL ONLY BE REGENERATED ON SERVICE RESTART. NOTIFY PIUS IF YOU DELETE ANY INDEX, FOR SERVICE RESTART. AFTER RESTART, REINDEXING OF EXISTING ENTITY DATA IN DB CAN BE DONE BY EDITING ANY OF THE DATA FIELD. ONE FIELD IS ENOUGH AND NO NEED TO CHANGE THE VALUE OF THE DATA. SIMPLY SAVE. Optionally indicate one or more indexes to delete, separated by commas. Deletes all when no index is indicated.
             * @returns any
             * @throws ApiError
             */
            public static appControllerDeleteIndices(
                accessToken: string | undefined = undefined,
                indexesToDelete?: Array<'users' | 'products' | 'merchants' | 'customers' | 'employees' | 'general_uploads' | 'customer_issues'>,
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'DELETE',
                    url: generalVersionUrlPrefix + '/delete-indexes',
                    path: {
                        'accessToken': accessToken,
                    },
                    query: {
                        'indexes-to-delete': indexesToDelete,
                    },
                });
            }

            /**
             * @param accessToken
             * @returns any
             * @throws ApiError
             */
            public static appControllerGetCombinedLogFile(
                accessToken: string | undefined = undefined,
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: generalVersionUrlPrefix + '/download-combined-log',
                    path: {
                        'accessToken': accessToken,
                    },
                });
            }

            /**
             * @param accessToken
             * @returns any
             * @throws ApiError
             */
            public static appControllerGetErrorLogFile(
                accessToken: string | undefined = undefined,
            ): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: generalVersionUrlPrefix + '/download-error-log',
                    path: {
                        'accessToken': accessToken,
                    },
                });
            }

            /**
             * @returns any
             * @throws ApiError
             */
            public static appControllerNotificationSubscription(): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'POST',
                    url: generalVersionUrlPrefix + '/notifications/subscribe',
                });
            }

            /**
             * @returns any
             * @throws ApiError
             */
            public static appControllerGetEchoHead(): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'HEAD',
                    url: generalVersionUrlPrefix + '/echo',
                });
            }

            /**
             * @returns any
             * @throws ApiError
             */
            public static appControllerGetEcho(): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: generalVersionUrlPrefix + '/echo',
                });
            }

            /**
             * @returns any
             * @throws ApiError
             */
            public static appControllerGetTenantInfoAndStatus(): CancelablePromise<any> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: generalVersionUrlPrefix + '/tenant-info-and-status',
                });
            }

        }
