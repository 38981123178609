/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BankCreditFacility } from './BankCreditFacility';
import type { LoyaltyBenefit } from './LoyaltyBenefit';
import type { Merchant } from './Merchant';

export type LoyaltyStatus = {
    uuid: string;
    loyaltyLevel: number;
    criteriaForAssigningLevel: string;
    statusName: string;
    tokenEnabled: boolean;
    /**
     * If token, how much each unit is worth in store's default currency.
     */
    tokenUnitWorthInStoreDefaultCurrency: number;
    pointEnabled: boolean;
    /**
     * If point, how much each unit is worth in store default currency.
     */
    pointUnitWorthInStoreDefaultCurrency: number;
    discountEnabled: boolean;
    creditEnabled: boolean;
    installmentEnabled: boolean;
    autoTriggeredByCustomerTransactionVolume: boolean;
    /**
     * What minimum volume of transaction in default currency is required to trigger status?
     * TransactionVolumeLevelForAutoTriggerPeriod.O means that transaction volume is not necessary. Repeat is sufficient
     */
    transactionVolumeLevelForAutoTrigger: number;
    /**
     * Over what maximum period will transaction volume trigger be accummulated?
     * Defaults to once (i.e. not accummulated over any period).
     */
    transactionVolumeLevelForAutoTriggerPeriod: LoyaltyStatus.transactionVolumeLevelForAutoTriggerPeriod;
    /**
     * How many repeats of the transaction volume is required to trigger status?
     * Defaults to 1, which means one repeat is required by default.
     */
    transactionRepeatCountForAutoTrigger: number;
    defaultLifeSpanOfLoyaltyStatusInDays: number;
    discountingFormula: LoyaltyStatus.discountingFormula;
    creditFormula: LoyaltyStatus.creditFormula;
    installmentPaymentFormula: LoyaltyStatus.installmentPaymentFormula;
    isApproved: boolean;
    approvedBy: string;
    merchantSpecific: boolean;
    merchantId: number;
    merchant: Merchant;
    merchantSpecificNumberOfTokensAllocatable: number;
    merchantSpecificMinimumSpendingForTokensAllocation: number;
    merchantSpecificDiscountRate: number;
    merchantSpecificMinimumSpendingForDiscount: number;
    merchantSpecificInstallmentPaymentCount: number;
    merchantSpecificMinimumSpendingForInstallmentPayment: number;
    merchantSpecificCredit: number;
    merchantSpecificMinimumSpendingForCredit: number;
    applicableBankCreditFacilities: Array<BankCreditFacility>;
    automaticallyAppliedToWhichLoyaltyBenefits: Array<LoyaltyBenefit>;
    merchantManuallyAppliedToWhichLoyaltyBenefits: Array<LoyaltyBenefit>;
    id: number;
    dateCreated?: string;
    dateLastModified?: string;
};

export namespace LoyaltyStatus {

    /**
     * Over what maximum period will transaction volume trigger be accummulated?
     * Defaults to once (i.e. not accummulated over any period).
     */
    export enum transactionVolumeLevelForAutoTriggerPeriod {
        PER_DAY = 'Per Day',
        PER_WEEK = 'Per Week',
        PER_MONTH = 'Per Month',
        PER_YEAR = 'Per Year',
        ONCE = 'Once',
    }

    export enum discountingFormula {
        _5_OF_PURCHASE = '5% of purchase',
        _10_OF_PURCHASE = '10% of purchase',
        _5_OF_PURCHASE_WITH_MINIMUM_OF_10_000_NAIRA_SPENT = '5% of purchase with minimum of 10,000 naira spent',
    }

    export enum creditFormula {
        _5_OF_PURCHASE = '5% of purchase',
        _10_OF_PURCHASE = '10% of purchase',
        _5_OF_PURCHASE_WITH_MINIMUM_OF_10_000_NAIRA_SPENT = '5% of purchase with minimum of 10,000 naira spent',
    }

    export enum installmentPaymentFormula {
        TWO_INSTALLMENTS = 'Two installments',
        THREE_INSTALLMENT = 'Three installment',
    }


}

