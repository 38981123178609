/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateHomePageDto } from '../models/CreateHomePageDto';
import type { HomePage } from '../models/HomePage';
import type { UpdateHomePageDto } from '../models/UpdateHomePageDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

import { generalVersionUrlPrefix } from '../../../components/app.settings';

export class HomePagesService {

    /**
     * Create home page. Carousel, Hotbox, HotBox by categories can also be created if passed in dto
     * @param accessToken
     * @param requestBody
     * @returns HomePage
     * @throws ApiError
     */
    public static homePagesControllerCreate(
        accessToken: string | undefined = undefined,
        requestBody: CreateHomePageDto,
    ): CancelablePromise<HomePage> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/home-pages',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get all home pages that meet the criteria specified in query options, if any.
     * @param accessToken
     * @param findOptions encodeURI(JSON.stringify({select?: string[]; relations?: string[]; skip?: number; take?: number;cache?: boolean; where?: {}[] | {}; order?: {};))
     * @returns any Home pages returned.
     * @throws ApiError
     */
    public static homePagesControllerFindAll(
        accessToken: string | undefined = undefined,
        findOptions?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/home-pages',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'findOptions': findOptions,
            },
            errors: {
                400: `Bad request: likely incorrect options sent`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Find a home page by id
     * @param id
     * @param accessToken
     * @returns HomePage
     * @throws ApiError
     */
    public static homePagesControllerFindOne(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<HomePage> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/home-pages/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Update a home page by id
     * @param id
     * @param accessToken
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static homePagesControllerUpdate(
        id: string,
        accessToken: string | undefined = undefined,
        requestBody: UpdateHomePageDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/home-pages/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete a home page by id
     * @param id
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static homePagesControllerRemove(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/home-pages/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Relationships
     * Add carousel item to home page by id
     * @param homePageId
     * @param carouselItemId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static homePagesControllerAddCarouselById(
        homePageId: string,
        carouselItemId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/home-pages/{homePageId}/carousel-item/{carouselItemId}',
            path: {
                'homePageId': homePageId,
                'carouselItemId': carouselItemId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Remove carousel item from home page by id
     * @param homePageId
     * @param carouselItemId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static homePagesControllerRemoveCarouselById(
        homePageId: string,
        carouselItemId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/home-pages/{homePageId}/carousel-item/{carouselItemId}',
            path: {
                'homePageId': homePageId,
                'carouselItemId': carouselItemId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Add hot box to home page by id
     * @param homePageId
     * @param hotBoxId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static homePagesControllerAddHotBoxById(
        homePageId: string,
        hotBoxId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/home-pages/{homePageId}/hot-box/{hotBoxId}',
            path: {
                'homePageId': homePageId,
                'hotBoxId': hotBoxId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Remove hot box from home page by id
     * @param homePageId
     * @param hotBoxId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static homePagesControllerRemoveHotBoxById(
        homePageId: string,
        hotBoxId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/home-pages/{homePageId}/hot-box/{hotBoxId}',
            path: {
                'homePageId': homePageId,
                'hotBoxId': hotBoxId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Add hot box by categories to home page by id
     * @param homePageId
     * @param hotBoxByCategoryId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static homePagesControllerAddHotBoxByCategoryById(
        homePageId: string,
        hotBoxByCategoryId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/home-pages/{homePageId}/hot-box-by-category/{hotBoxByCategoryId}',
            path: {
                'homePageId': homePageId,
                'hotBoxByCategoryId': hotBoxByCategoryId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Remove hot box by category from home page by id
     * @param homePageId
     * @param hotBoxByCategoryId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static homePagesControllerRemoveHotBoxByCategoryById(
        homePageId: string,
        hotBoxByCategoryId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/home-pages/{homePageId}/hot-box-by-category/{hotBoxByCategoryId}',
            path: {
                'homePageId': homePageId,
                'hotBoxByCategoryId': hotBoxByCategoryId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * @param accessToken
     * @param ageRangeMatch E.g. ageRangeMatch=adult. Options include *, etc
     * @param genderMatch E.g. genderMatch=male. Options include male | female | *
     * @returns HomePage
     * @throws ApiError
     */
    public static homePagesControllerGetDefaultHomePage(
        accessToken: string | undefined = undefined,
        ageRangeMatch?: any,
        genderMatch?: any,
    ): CancelablePromise<HomePage> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/home-pages/get-default-home-page',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'ageRangeMatch': ageRangeMatch,
                'genderMatch': genderMatch,
            },
        });
    }

}
