/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Order } from './Order';
import type { TaxRegion } from './TaxRegion';

export type TaxPayment = {
    amount: number;
    currency: TaxPayment.currency;
    orderId: number;
    order: Order;
    taxRegionId: number;
    taxRegion: TaxRegion;
    remitted: boolean;
    dateRemitted: string;
    remittanceReference: string;
    id: number;
    dateCreated?: string;
    dateLastModified?: string;
};

export namespace TaxPayment {

    export enum currency {
        NAIRA = 'Naira',
        US_DOLLAR = 'US Dollar',
        GREAT_BRITAIN_POUND = 'Great Britain Pound',
    }


}

