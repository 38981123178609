/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdatePointCreditAndDebitDto = {
    transactionDate?: string;
    type?: UpdatePointCreditAndDebitDto.type;
    description?: string;
    loyaltyBenefitId?: number;
};

export namespace UpdatePointCreditAndDebitDto {

    export enum type {
        CREDIT = 'credit',
        DEBIT = 'debit',
    }


}

