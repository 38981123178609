/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Bank } from '../models/Bank';
import type { BanksAndCount } from '../models/BanksAndCount';
import type { CreateBankAccountOfficerDto } from '../models/CreateBankAccountOfficerDto';
import type { CreateBankDto } from '../models/CreateBankDto';
import type { CreateUserDto } from '../models/CreateUserDto';
import type { UpdateBankAccountOfficerDto } from '../models/UpdateBankAccountOfficerDto';
import type { UpdateBankDto } from '../models/UpdateBankDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

import { generalVersionUrlPrefix } from '../../../components/app.settings';

export class BanksService {

    /**
     * Create a new bank along with create primary contact as user. createUserDto must be sent. This endpoint is designed for a user to signup a bank as the bank's primary contact
     * @param accessToken
     * @param requestBody
     * @returns Bank Bank has been successfully created
     * @throws ApiError
     */
    public static banksControllerSelfCreate(
        accessToken: string | undefined = undefined,
        requestBody: CreateBankDto,
    ): CancelablePromise<Bank> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/banks/self-create',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create a new bank. Optionally create primary contact as user if create-primary-contact query string is set to 1
     * @param accessToken
     * @param createPrimaryContact
     * @param requestBody
     * @returns Bank Bank has been successfully created
     * @throws ApiError
     */
    public static banksControllerCreate(
        accessToken: string | undefined = undefined,
        createPrimaryContact: any,
        requestBody: CreateBankDto,
    ): CancelablePromise<Bank> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/banks',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'create-primary-contact': createPrimaryContact,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a bank. Fully replaces all fields
     * @param accessToken
     * @param requestBody
     * @returns Bank Bank saved
     * @throws ApiError
     */
    public static banksControllerSave(
        accessToken: string | undefined = undefined,
        requestBody: Bank,
    ): CancelablePromise<Bank> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: generalVersionUrlPrefix + '/banks',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all banks that meet the criteria specified in query options, if any.
     * @param accessToken
     * @param findOptions encodeURI(JSON.stringify({select?: string[]; relations?: string[]; skip?: number; take?: number;cache?: boolean; where?: {}[] | {}; order?: {};}))
     * @returns BanksAndCount Get all banks that meet the criteria specified in query options, if any. Returns array of items found, and count
     * @throws ApiError
     */
    public static banksControllerFindAll(
        accessToken: string | undefined = undefined,
        findOptions?: any,
    ): CancelablePromise<BanksAndCount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/banks',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'findOptions': findOptions,
            },
            errors: {
                400: `Bad request: likely incorrect options sent`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create one or more new banks in one go
     * @param accessToken
     * @param requestBody
     * @returns any Banks have been successfully created.
     * @throws ApiError
     */
    public static banksControllerInsert(
        accessToken: string | undefined = undefined,
        requestBody: Array<string>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/banks/insert',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a bank. Only the fields sent from client will be updated
     * @param id
     * @param accessToken
     * @param requestBody
     * @returns any Bank has been successfully updated.
     * @throws ApiError
     */
    public static banksControllerUpdate(
        id: string,
        accessToken: string | undefined = undefined,
        requestBody: UpdateBankDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/banks/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete a bank.
     * @param id
     * @param accessToken
     * @returns any Bank has been successfully deleted.
     * @throws ApiError
     */
    public static banksControllerDelete(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/banks/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                400: `Bad request: likely bank does not exist`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a bank with the id sent as param
     * @param id
     * @param accessToken
     * @returns Bank Bank found
     * @throws ApiError
     */
    public static banksControllerFindOne(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<Bank> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/banks/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param bankId
     * @param accessToken
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static banksControllerCreateAndSetPrimaryContact(
        bankId: string,
        accessToken: string | undefined = undefined,
        requestBody: CreateUserDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/banks/{bankId}/primary-contact',
            path: {
                'bankId': bankId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Unset primary contact of bank
     * @param bankId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static banksControllerUnsetPrimaryContactById(
        bankId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/banks/{bankId}/primary-contact',
            path: {
                'bankId': bankId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Set primary contact of bank
     * @param bankId
     * @param userId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static banksControllerSetPrimaryContactById(
        bankId: string,
        userId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/banks/{bankId}/primary-contact/{userId}',
            path: {
                'bankId': bankId,
                'userId': userId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Create a new user and add to bank as team member with specific role(s)
     * @param bankId
     * @param accessToken
     * @param teamMemberRole team member roles should be comma separated strings that align with values in BankTeamRole enum
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static banksControllerCreateAndAddTeamMember(
        bankId: string,
        accessToken: string | undefined = undefined,
        teamMemberRole: any,
        requestBody: CreateUserDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/banks/{bankId}/team-member',
            path: {
                'bankId': bankId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'team-member-role': teamMemberRole,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Add team member to bank
     * @param bankId
     * @param userId
     * @param accessToken
     * @param teamMemberRole team member roles should be comma separated strings that align with values in BankTeamRole enum
     * @returns any
     * @throws ApiError
     */
    public static banksControllerAddTeamMemberById(
        bankId: string,
        userId: string,
        accessToken: string | undefined = undefined,
        teamMemberRole: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/banks/{bankId}/team-member/{userId}',
            path: {
                'bankId': bankId,
                'userId': userId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'team-member-role': teamMemberRole,
            },
        });
    }

    /**
     * Remove team member from bank
     * @param bankId
     * @param userId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static banksControllerRemoveTeamMemberById(
        bankId: string,
        userId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/banks/{bankId}/team-member/{userId}',
            path: {
                'bankId': bankId,
                'userId': userId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Remove team member from bank
     * @param bankId
     * @param userId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static banksControllerRemoveTeamMemberByIdAndDeleteUser(
        bankId: string,
        userId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/banks/{bankId}/team-member/{userId}/plus-delete-user',
            path: {
                'bankId': bankId,
                'userId': userId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Update team member role of bank
     * @param userId
     * @param accessToken
     * @param teamMemberRole team member roles should be comma separated strings that align with values in BankTeamRole enum
     * @returns any
     * @throws ApiError
     */
    public static banksControllerUpdateBankTeamMemberRoleById(
        userId: string,
        accessToken: string | undefined = undefined,
        teamMemberRole: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/banks/{userId}/bank-team-member-role',
            path: {
                'userId': userId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'team-member-role': teamMemberRole,
            },
        });
    }

    /**
     * Create a new user and set as bank account officer for bank
     * @param bankId
     * @param accessToken
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static banksControllerCreateAndSetBankAccountOfficer(
        bankId: string,
        accessToken: string | undefined = undefined,
        requestBody: CreateBankAccountOfficerDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/banks/{bankId}/account-officer',
            path: {
                'bankId': bankId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Set bank account officer for a given bank
     * @param bankId
     * @param userId
     * @param accessToken
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static banksControllerSetBankAccountOfficerById(
        bankId: string,
        userId: string,
        accessToken: string | undefined = undefined,
        requestBody: CreateBankAccountOfficerDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/banks/{bankId}/account-officer/{userId}',
            path: {
                'bankId': bankId,
                'userId': userId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete bank account officer entry. Removes association between a user and bank as account officer
     * @param bankId
     * @param userId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static banksControllerDeleteBankAccountOfficerEntryById(
        bankId: string,
        userId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/banks/{bankId}/account-officer/{userId}',
            path: {
                'bankId': bankId,
                'userId': userId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Updates bank account officer of a bank. Roles should be included in the updateBankAccountOfficerDto in body
     * @param bankId
     * @param userId
     * @param accessToken
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static banksControllerUpdateBankAccountOfficerById(
        bankId: string,
        userId: string,
        accessToken: string | undefined = undefined,
        requestBody: UpdateBankAccountOfficerDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/banks/{bankId}/account-officer-update/{userId}',
            path: {
                'bankId': bankId,
                'userId': userId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Add bank credit facility to bank
     * @param bankId
     * @param bankCreditFacilityId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static banksControllerAddBankCreditFacilityById(
        bankId: string,
        bankCreditFacilityId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/banks/{bankId}/credit-facility/{bankCreditFacilityId}',
            path: {
                'bankId': bankId,
                'bankCreditFacilityId': bankCreditFacilityId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Remove bank credit facility from bank
     * @param bankId
     * @param bankCreditFacilityId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static banksControllerRemoveBankCreditFacilityById(
        bankId: string,
        bankCreditFacilityId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/banks/{bankId}/credit-facility/{bankCreditFacilityId}',
            path: {
                'bankId': bankId,
                'bankCreditFacilityId': bankCreditFacilityId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Get desired relations for a given bank. List of relations to fetch as query, separated by commas e.g. orders,user]. Returns the bank id along with the relations only
     * @param bankId
     * @param sendAllBankFieldsAlong Set to true, if you want the whole bank fields to be carried along i.e. send-all-bank-fields-along=true. Else, don't add the query at all
     * @param relations List of relations to fetch separated by commas e.g. bankTeamMembers,primaryContact, etc]. Returns the bank id along with the relations only
     * @param accessToken
     * @returns any Relations successfully gotten.
     * @throws ApiError
     */
    public static banksControllerGetRelationsByName(
        bankId: string,
        sendAllBankFieldsAlong: any,
        relations: any,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/banks/{bankId}/get-relations-by-name',
            path: {
                'bankId': bankId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'send-all-bank-fields-along': sendAllBankFieldsAlong,
                'relations': relations,
            },
            errors: {
                400: `Bad request: likely, bank does not exist`,
                500: `Internal server error`,
            },
        });
    }

}
