import { Container } from "react-bulma-components";

type Props = {
    isActive: "is-active" | null;
}

const ModalLoading: React.FC<Props> = ({ isActive }) => {
    return (
        <Container className={`modal ${isActive} `}>
            <div className="modal-background"></div>
            <div className="modal-content has-text-centered">
                <button className="button is-text is-loading"></button>
            </div>
        </Container>

    )
}

export default ModalLoading;