/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateCustomerDto } from '../models/CreateCustomerDto';
import type { CreateCustomerDtos } from '../models/CreateCustomerDtos';
import type { CreateUserDto } from '../models/CreateUserDto';
import type { Customer } from '../models/Customer';
import type { CustomersAndCount } from '../models/CustomersAndCount';
import type { CustomerSearchResult } from '../models/CustomerSearchResult';
import type { CustomerSearchResultWithEntity } from '../models/CustomerSearchResultWithEntity';
import type { Suggestion } from '../models/Suggestion';
import type { UpdateCustomerDto } from '../models/UpdateCustomerDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

import { generalVersionUrlPrefix } from '../../../components/app.settings';

export class CustomersService {

    /**
     * This endpoint creates a single new customer. If query string create-user is set to 1, it creates a new user that is related to the customer.
     * @param accessToken
     * @param createUser 1 or 0. Defaults to 0
     * @param requestBody
     * @returns Customer :Customer created
     * @throws ApiError
     */
    public static customersControllerCreate(
        accessToken: string | undefined = undefined,
        createUser: any,
        requestBody: CreateCustomerDto,
    ): CancelablePromise<Customer> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/customers',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'create-user': createUser,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a customer. Fully replaces all fields
     * @param accessToken
     * @param requestBody
     * @returns Customer :Customer saved
     * @throws ApiError
     */
    public static customersControllerSave(
        accessToken: string | undefined = undefined,
        requestBody: Customer,
    ): CancelablePromise<Customer> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: generalVersionUrlPrefix + '/customers',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all customers that meet the criteria specified in query options, if any.
     * @param accessToken
     * @param findOptions encodeURI(JSON.stringify({select?: string[]; relations?: string[]; skip?: number; take?: number;cache?: boolean; where?: {}[] | {}; order?: {};))
     * @returns CustomersAndCount :Gets all customers that meet the criteria specified in query options, if any. Returns array of customers found, and count
     * @throws ApiError
     */
    public static customersControllerFindAll(
        accessToken: string | undefined = undefined,
        findOptions?: any,
    ): CancelablePromise<CustomersAndCount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/customers',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'findOptions': findOptions,
            },
            errors: {
                400: `Bad request: likely incorrect options sent`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create one or more new customers in one go
     * @param accessToken
     * @param requestBody
     * @returns any Customers have been successfully created.
     * @throws ApiError
     */
    public static customersControllerInsert(
        accessToken: string | undefined = undefined,
        requestBody: CreateCustomerDtos,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/customers/insert',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a customer. Only the fields sent from client will be updated
     * @param id
     * @param accessToken
     * @param requestBody
     * @returns any Customer has been successfully updated.
     * @throws ApiError
     */
    public static customersControllerUpdate(
        id: string,
        accessToken: string | undefined = undefined,
        requestBody: UpdateCustomerDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/customers/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete a customer.
     * @param id
     * @param accessToken
     * @returns any Customer has been successfully deleted.
     * @throws ApiError
     */
    public static customersControllerDelete(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/customers/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                400: `Bad request: likely customer does not exist`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a customer with the id sent as param
     * @param id
     * @param accessToken
     * @returns Customer Customer found
     * @throws ApiError
     */
    public static customersControllerFindOne(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<Customer> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/customers/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * This url is for web client involving both server-side and client-side rendering
     * @param accessToken
     * @returns any Rendered web page is returned.
     * @throws ApiError
     */
    public static customersControllerWeb(
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/customers/web*',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * Set the user for a given customer
     * @param customerId
     * @param userId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static customersControllerSetUserById(
        customerId: string,
        userId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/customers/{customerId}/user/{userId}',
            path: {
                'customerId': customerId,
                'userId': userId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Create a user account for a given customer
     * @param customerId
     * @param accessToken
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static customersControllerCreateAndSetUser(
        customerId: string,
        accessToken: string | undefined = undefined,
        requestBody: CreateUserDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/customers/{customerId}/user',
            path: {
                'customerId': customerId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Disassociate a user from a given customer
     * @param customerId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static customersControllerUnsetUserById(
        customerId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/customers/{customerId}/user',
            path: {
                'customerId': customerId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * work on search
     * Search for customers and return found customer objects
     * @param accessToken
     * @param searchString search string
     * @param searchAfter search-after=34242&search-after=4
     * @param sortOrder asc or desc
     * @param sortBy Sort by relevance, date, etc. Select from option
     * @param resultsPerPage Number of results per page
     * @returns CustomerSearchResultWithEntity :Customer Issues found
     * @throws ApiError
     */
    public static customersControllerSearchAndGetCustomers(
        accessToken: string | undefined = undefined,
        searchString: any,
        searchAfter?: any,
        sortOrder?: 'asc' | 'desc',
        sortBy?: '_score' | 'date',
        resultsPerPage?: any,
    ): CancelablePromise<CustomerSearchResultWithEntity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/customers/search-and-get-customers',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'search-after': searchAfter,
                'sort-order': sortOrder,
                'sort-by': sortBy,
                'results-per-page': resultsPerPage,
                'search-string': searchString,
            },
        });
    }

    /**
     * Search for customers and return the search hits with minimal info sufficient to differentiate the search results. The client can fetch more details afterwards leveraging on the id field.
     * @param accessToken
     * @param searchString search string
     * @param searchAfter search-after=34242&search-after=4
     * @param sortOrder asc or desc
     * @param sortBy Sort by relevance, date, etc. Select from option
     * @param resultsPerPage Number of results per page
     * @returns CustomerSearchResult :Customer Issues found
     * @throws ApiError
     */
    public static customersControllerSearch(
        accessToken: string | undefined = undefined,
        searchString: any,
        searchAfter?: any,
        sortOrder?: 'asc' | 'desc',
        sortBy?: '_score' | 'date',
        resultsPerPage?: any,
    ): CancelablePromise<CustomerSearchResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/customers/search',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'search-after': searchAfter,
                'sort-order': sortOrder,
                'sort-by': sortBy,
                'results-per-page': resultsPerPage,
                'search-string': searchString,
            },
        });
    }

    /**
     * Run query against index optimised for autosuggest.
     * @param accessToken
     * @param searchString search string
     * @returns Suggestion :Customer suggestions found
     * @throws ApiError
     */
    public static customersControllerSuggest(
        accessToken: string | undefined = undefined,
        searchString: any,
    ): CancelablePromise<Array<Suggestion>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/customers/suggest',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'search-string': searchString,
            },
        });
    }

    /**
     * Create self as a new customer. Creates a user as well if create-user query string is set to 1
     * @param accessToken
     * @param createUser 1 or 0. Defaults to 0
     * @param requestBody
     * @returns Customer :Customer created
     * @throws ApiError
     */
    public static customersControllerCreateSelf(
        accessToken: string | undefined = undefined,
        createUser: any,
        requestBody: CreateCustomerDto,
    ): CancelablePromise<Customer> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/customers/create-self',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'create-user': createUser,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Self create a new customer as family member. Optionally creates a user is create-user query string is set to 1
     * @param accessToken
     * @param createUser 1 or 0. Defaults to 0
     * @param requestBody
     * @returns Customer :Customer created as family member
     * @throws ApiError
     */
    public static customersControllerCreateFamilyMemberBySelf(
        accessToken: string | undefined = undefined,
        createUser: any,
        requestBody: CreateCustomerDto,
    ): CancelablePromise<Customer> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/customers/create-family-member-by-self',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'create-user': createUser,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Customer officer create a new customer
     * @param accessToken
     * @param createUser 1 or 0. Defaults to 0
     * @param requestBody
     * @returns Customer :Customer created by customer officer
     * @throws ApiError
     */
    public static customersControllerCreateCustomerByOfficer(
        accessToken: string | undefined = undefined,
        createUser: any,
        requestBody: CreateCustomerDto,
    ): CancelablePromise<Customer> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/customers/create-by-customer-officer',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'create-user': createUser,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Customer officer create a new customer as family member. Optionally creates a user is create-user query string is set to 1
     * @param createUser 1 or 0. Defaults to 0
     * @param accessToken
     * @param requestBody
     * @returns Customer :Customer created as family member by officer
     * @throws ApiError
     */
    public static customersControllerCreateFamilyMemberByOfficer(
        createUser: any,
        accessToken: string | undefined = undefined,
        requestBody: CreateCustomerDto,
    ): CancelablePromise<Customer> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/customers/create-family-member-by-customer-officer',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'create-user': createUser,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Merchant create a new customer. Optionally creates a user if create-user query string is set to 1
     * @param accessToken
     * @param createUser 1 or 0. Defaults to 0
     * @param requestBody
     * @returns Customer :Customer created by merchant
     * @throws ApiError
     */
    public static customersControllerCreateCustomerByMerchant(
        accessToken: string | undefined = undefined,
        createUser: any,
        requestBody: CreateCustomerDto,
    ): CancelablePromise<Customer> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/customers/create-by-merchant',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'create-user': createUser,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Merchant create a new customer as family member. Optionally creates a user if create-user query string is set to 1
     * @param accessToken
     * @param createUser 1 or 0. Defaults to 0
     * @param requestBody
     * @returns Customer :Customer created as family member by merchant
     * @throws ApiError
     */
    public static customersControllerCreateFamilyMemberByMerchant(
        accessToken: string | undefined = undefined,
        createUser: any,
        requestBody: CreateCustomerDto,
    ): CancelablePromise<Customer> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/customers/create-family-member-by-merchant',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'create-user': createUser,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Add family member to a customer who is thus a family head
     * @param customerId
     * @param familyMemberId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static customersControllerAddFamilyMemberToCustomerById(
        customerId: string,
        familyMemberId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/customers/{customerId}/add-family-member/{familyMemberId}',
            path: {
                'customerId': customerId,
                'familyMemberId': familyMemberId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Remove family member from a customer who is a family head
     * @param customerId
     * @param familyMemberId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static customersControllerRemoveFamilyMemberFromCustomerById(
        customerId: string,
        familyMemberId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/customers/{customerId}/remove-family-member/{familyMemberId}',
            path: {
                'customerId': customerId,
                'familyMemberId': familyMemberId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Set family head for a customer
     * @param customerId
     * @param familyHeadId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static customersControllerSetFamilyHeadOfCustomerById(
        customerId: string,
        familyHeadId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/customers/{customerId}/set-family-head/{familyHeadId}',
            path: {
                'customerId': customerId,
                'familyHeadId': familyHeadId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Unset family head for a customer
     * @param customerId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static customersControllerUnsetFamilyHeadOfCustomerById(
        customerId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/customers/{customerId}/unset-family-head',
            path: {
                'customerId': customerId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Get Customer by User Id
     * @param userId
     * @param accessToken
     * @returns Customer
     * @throws ApiError
     */
    public static customersControllerGetCustomerByUserId(
        userId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<Customer> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/customers/get-customer-by-user-id/{userId}',
            path: {
                'userId': userId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Assign multiple coupons to customer with customerId. The query should contain an array of couponids in query key named couponid e.g. ?couponid=1&couponid=2&couponid=3...
     * @param customerId
     * @param accessToken
     * @returns any Coupons assigned to customer successfully.
     * @throws ApiError
     */
    public static customersControllerAddIssuedCouponsById(
        customerId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/customers/{customerId}/add-coupons',
            path: {
                'customerId': customerId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                400: `Bad request: likely, customer or coupon does not exist`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Remove multiple coupons from customer with customerId. The query should contain an array of couponIds in query key named couponid e.g. ?couponid=1&couponid=2&couponid=3...
     * @param customerId
     * @param accessToken
     * @returns any Coupons removed from customer successfully.
     * @throws ApiError
     */
    public static customersControllerRemoveIssuedCouponsById(
        customerId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/customers/{customerId}/remove-coupons',
            path: {
                'customerId': customerId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                400: `Bad request: likely, customer or coupon does not exist`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Assign multiple loyalty benefits to customer with customerId. The query should contain an array of loyaltybenefitids in query key named loyaltybenefitid e.g. ?loyaltybenefitid=1&loyaltybenefitid=2&loyaltybenefitid=3...
     * @param customerId
     * @param accessToken
     * @returns any Loyalty benefits assigned to customer successfully.
     * @throws ApiError
     */
    public static customersControllerAddLoyaltyBenefitsById(
        customerId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/customers/{customerId}/add-loyalty-benefits',
            path: {
                'customerId': customerId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                400: `Bad request: likely, customer or loyalty benefits do not exist`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Remove multiple loyalty benefits from customer with customerId. The query should contain an array of loyaltybenefitid in query key named loyaltybenefitid e.g. ?loyaltybenefitid=1&loyaltybenefitid=2&loyaltybenefitid=3...
     * @param customerId
     * @param accessToken
     * @returns any Loyalty benefits removed from customer successfully.
     * @throws ApiError
     */
    public static customersControllerRemoveLoyaltyBenefitsById(
        customerId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/customers/{customerId}/remove-loyalty-benefits',
            path: {
                'customerId': customerId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                400: `Bad request: likely, customer or loyalty benefits do not exist`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get desired relations for a given customer. List of relations to fetch as query, separated by commas e.g. orders,user]. Returns the customer id along with the relations only
     * @param customerId
     * @param sendAllCustomerFieldsAlong Set to true, if you want the whole customer fields to be carried along i.e. send-all-customer-fields-along=true. Else, don't add the query at all
     * @param relations List of relations to fetch separated by commas e.g. orders,user]. Returns the customer id along with the relations only
     * @param accessToken
     * @returns any Relations successfully gotten.
     * @throws ApiError
     */
    public static customersControllerGetRelationsByName(
        customerId: string,
        sendAllCustomerFieldsAlong: any,
        relations: any,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/customers/{customerId}/get-relations-by-name',
            path: {
                'customerId': customerId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'send-all-customer-fields-along': sendAllCustomerFieldsAlong,
                'relations': relations,
            },
            errors: {
                400: `Bad request: likely, customer does not exist`,
                500: `Internal server error`,
            },
        });
    }

}
