/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CarouselItem } from '../models/CarouselItem';
import type { CarouselItemsAndCount } from '../models/CarouselItemsAndCount';
import type { CreateCarouselItemDto } from '../models/CreateCarouselItemDto';
import type { UpdateCarouselItemDto } from '../models/UpdateCarouselItemDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

import { generalVersionUrlPrefix } from '../../../components/app.settings';

export class CarouselItemsService {

    /**
     * Post carousel item
     * @param accessToken
     * @param requestBody
     * @returns CarouselItem :Carousel item created
     * @throws ApiError
     */
    public static carouselItemsControllerCreate(
        accessToken: string | undefined = undefined,
        requestBody: CreateCarouselItemDto,
    ): CancelablePromise<CarouselItem> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/carousel-items',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get all carousel items
     * @param accessToken
     * @param findOptions encodeURI(JSON.stringify({select?: string[]; relations?: string[]; skip?: number; take?: number;cache?: boolean; where?: {}[] | {}; order?: {};}))
     * @returns CarouselItemsAndCount Get all carousel items that meet the criteria specified in query options, if any. Returns array of carousel items found, and count
     * @throws ApiError
     */
    public static carouselItemsControllerFindAll(
        accessToken: string | undefined = undefined,
        findOptions?: any,
    ): CancelablePromise<CarouselItemsAndCount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/carousel-items',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'findOptions': findOptions,
            },
        });
    }

    /**
     * Get a carousel item
     * @param id
     * @param accessToken
     * @returns CarouselItem :Carousel item found
     * @throws ApiError
     */
    public static carouselItemsControllerFindOne(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<CarouselItem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/carousel-items/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Update a carousel item
     * @param id
     * @param accessToken
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static carouselItemsControllerUpdate(
        id: string,
        accessToken: string | undefined = undefined,
        requestBody: UpdateCarouselItemDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: generalVersionUrlPrefix + '/carousel-items/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete a carousel item
     * @param id
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static carouselItemsControllerDelete(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/carousel-items/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

}
