/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BankCreditFacilitiesAndCount } from '../models/BankCreditFacilitiesAndCount';
import type { BankCreditFacility } from '../models/BankCreditFacility';
import type { CreateBankCreditFacilityDto } from '../models/CreateBankCreditFacilityDto';
import type { UpdateBankCreditFacilityDto } from '../models/UpdateBankCreditFacilityDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

import { generalVersionUrlPrefix } from '../../../components/app.settings';

export class BankCreditFacilitiesService {

    /**
     * Create a new bankCreditFacility
     * @param accessToken
     * @param requestBody
     * @returns BankCreditFacility Bank credit facility created
     * @throws ApiError
     */
    public static bankCreditFacilitiesControllerCreate(
        accessToken: string | undefined = undefined,
        requestBody: CreateBankCreditFacilityDto,
    ): CancelablePromise<BankCreditFacility> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/bank-credit-facilities',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a bankCreditFacility. Fully replaces all fields
     * @param accessToken
     * @param requestBody
     * @returns BankCreditFacility Bank credit facility saved
     * @throws ApiError
     */
    public static bankCreditFacilitiesControllerSave(
        accessToken: string | undefined = undefined,
        requestBody: BankCreditFacility,
    ): CancelablePromise<BankCreditFacility> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: generalVersionUrlPrefix + '/bank-credit-facilities',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all bankCreditFacilities that meet the criteria specified in query options, if any.
     * @param accessToken
     * @param findOptions encodeURI(JSON.stringify({select?: string[]; relations?: string[]; skip?: number; take?: number;cache?: boolean; where?: {}[] | {}; order?: {};}))
     * @returns BankCreditFacilitiesAndCount Get all bank credit facilities that meet the criteria specified in query options, if any. Returns array of items found, and count
     * @throws ApiError
     */
    public static bankCreditFacilitiesControllerFindAll(
        accessToken: string | undefined = undefined,
        findOptions?: any,
    ): CancelablePromise<BankCreditFacilitiesAndCount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/bank-credit-facilities',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'findOptions': findOptions,
            },
            errors: {
                400: `Bad request: likely incorrect options sent`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create one or more new bankCreditFacilities in one go
     * @param accessToken
     * @param requestBody
     * @returns any BankCreditFacilitys have been successfully created.
     * @throws ApiError
     */
    public static bankCreditFacilitiesControllerInsert(
        accessToken: string | undefined = undefined,
        requestBody: Array<string>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/bank-credit-facilities/insert',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a bankCreditFacility. Only the fields sent from client will be updated
     * @param id
     * @param accessToken
     * @param requestBody
     * @returns any BankCreditFacility has been successfully updated.
     * @throws ApiError
     */
    public static bankCreditFacilitiesControllerUpdate(
        id: string,
        accessToken: string | undefined = undefined,
        requestBody: UpdateBankCreditFacilityDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/bank-credit-facilities/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete a bankCreditFacility.
     * @param id
     * @param accessToken
     * @returns any BankCreditFacility has been successfully deleted.
     * @throws ApiError
     */
    public static bankCreditFacilitiesControllerDelete(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/bank-credit-facilities/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                400: `Bad request: likely bankCreditFacility does not exist`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a bankCreditFacility with the id sent as param
     * @param id
     * @param accessToken
     * @returns BankCreditFacility Bank credit facility found
     * @throws ApiError
     */
    public static bankCreditFacilitiesControllerFindOne(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<BankCreditFacility> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/bank-credit-facilities/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * Set bank of bank credit facility
     * @param bankCreditFacilityId
     * @param bankId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static bankCreditFacilitiesControllerSetBankById(
        bankCreditFacilityId: string,
        bankId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/bank-credit-facilities/{bankCreditFacilityId}/bank/{bankId}',
            path: {
                'bankCreditFacilityId': bankCreditFacilityId,
                'bankId': bankId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Unset bank of bank credit facility
     * @param bankCreditFacilityId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static bankCreditFacilitiesControllerUnsetBankById(
        bankCreditFacilityId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/bank-credit-facilities/{bankCreditFacilityId}/bank',
            path: {
                'bankCreditFacilityId': bankCreditFacilityId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Set loyalty status of bank credit facility
     * @param bankCreditFacilityId
     * @param loyaltyStatusId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static bankCreditFacilitiesControllerAddLoyaltyStatusById(
        bankCreditFacilityId: string,
        loyaltyStatusId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/bank-credit-facilities/{bankCreditFacilityId}/loyalty-status/{loyaltyStatusId}',
            path: {
                'bankCreditFacilityId': bankCreditFacilityId,
                'loyaltyStatusId': loyaltyStatusId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * @param bankCreditFacilityId
     * @param loyaltyStatusId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static bankCreditFacilitiesControllerRemoveLoyaltyStatusById(
        bankCreditFacilityId: string,
        loyaltyStatusId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/bank-credit-facilities/{bankCreditFacilityId}/loyalty-status/{loyaltyStatusId}',
            path: {
                'bankCreditFacilityId': bankCreditFacilityId,
                'loyaltyStatusId': loyaltyStatusId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

}
