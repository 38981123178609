/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateLoyaltyBenefitDto } from '../models/CreateLoyaltyBenefitDto';
import type { LoyaltyBenefit } from '../models/LoyaltyBenefit';
import type { LoyaltyBenefitsAndCount } from '../models/LoyaltyBenefitsAndCount';
import type { UpdateLoyaltyBenefitDto } from '../models/UpdateLoyaltyBenefitDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

import { generalVersionUrlPrefix } from '../../../components/app.settings';

export class LoyaltyBenefitsService {

    /**
     * Create a new loyaltyBenefit
     * @param accessToken
     * @param requestBody
     * @returns LoyaltyBenefit Loyalty benefit created
     * @throws ApiError
     */
    public static loyaltyBenefitsControllerCreateByMerchant(
        accessToken: string | undefined = undefined,
        requestBody: CreateLoyaltyBenefitDto,
    ): CancelablePromise<LoyaltyBenefit> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/loyalty-benefits/create-by-merchant',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create a new loyaltyBenefit
     * @param accessToken
     * @param requestBody
     * @returns LoyaltyBenefit Loyalty benefit created
     * @throws ApiError
     */
    public static loyaltyBenefitsControllerCreate(
        accessToken: string | undefined = undefined,
        requestBody: CreateLoyaltyBenefitDto,
    ): CancelablePromise<LoyaltyBenefit> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/loyalty-benefits',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a loyaltyBenefit. Fully replaces all fields
     * @param accessToken
     * @param requestBody
     * @returns LoyaltyBenefit Loyalty benefit saved
     * @throws ApiError
     */
    public static loyaltyBenefitsControllerSave(
        accessToken: string | undefined = undefined,
        requestBody: LoyaltyBenefit,
    ): CancelablePromise<LoyaltyBenefit> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: generalVersionUrlPrefix + '/loyalty-benefits',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all loyaltyBenefits that meet the criteria specified in query options, if any.
     * @param accessToken
     * @param findOptions encodeURI(JSON.stringify({select?: string[]; relations?: string[]; skip?: number; take?: number;cache?: boolean; where?: {}[] | {}; order?: {};}))
     * @returns LoyaltyBenefitsAndCount Get all loyalty benefits that meet the criteria specified in query options, if any. Returns array of items found, and count
     * @throws ApiError
     */
    public static loyaltyBenefitsControllerFindAll(
        accessToken: string | undefined = undefined,
        findOptions?: any,
    ): CancelablePromise<LoyaltyBenefitsAndCount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/loyalty-benefits',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'findOptions': findOptions,
            },
            errors: {
                400: `Bad request: likely incorrect options sent`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create one or more new loyaltyBenefits in one go
     * @param accessToken
     * @param requestBody
     * @returns any LoyaltyBenefits have been successfully created.
     * @throws ApiError
     */
    public static loyaltyBenefitsControllerInsert(
        accessToken: string | undefined = undefined,
        requestBody: Array<string>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/loyalty-benefits/insert',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a loyaltyBenefit. Only the fields sent from client will be updated
     * @param id
     * @param accessToken
     * @param requestBody
     * @returns any LoyaltyBenefit has been successfully updated.
     * @throws ApiError
     */
    public static loyaltyBenefitsControllerUpdate(
        id: string,
        accessToken: string | undefined = undefined,
        requestBody: UpdateLoyaltyBenefitDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/loyalty-benefits/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete a loyaltyBenefit.
     * @param id
     * @param accessToken
     * @returns any LoyaltyBenefit has been successfully deleted.
     * @throws ApiError
     */
    public static loyaltyBenefitsControllerDelete(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/loyalty-benefits/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                400: `Bad request: likely loyaltyBenefit does not exist`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a loyaltyBenefit with the id sent as param
     * @param id
     * @param accessToken
     * @returns LoyaltyBenefit Loyalty benefit found
     * @throws ApiError
     */
    public static loyaltyBenefitsControllerFindOne(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<LoyaltyBenefit> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/loyalty-benefits/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * Set automatically determined loyalty status for loyalty benefit
     * @param loyaltyBenefitId
     * @param loyaltyStatusId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static loyaltyBenefitsControllerSetAutomaticallyDeterminedLoyaltyStatusById(
        loyaltyBenefitId: string,
        loyaltyStatusId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/loyalty-benefits/{loyaltyBenefitId}/automatically-determined-loyalty-status/{loyaltyStatusId}',
            path: {
                'loyaltyBenefitId': loyaltyBenefitId,
                'loyaltyStatusId': loyaltyStatusId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Unset automatically determined loyalty status for loyalty benefit
     * @param loyaltyBenefitId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static loyaltyBenefitsControllerUnsetAutomaticallyDeterminedLoyaltyStatusById(
        loyaltyBenefitId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/loyalty-benefits/{loyaltyBenefitId}/automatically-determined-loyalty-status',
            path: {
                'loyaltyBenefitId': loyaltyBenefitId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Set merchant manual loyalty status override for loyalty benefit
     * @param loyaltyBenefitId
     * @param loyaltyStatusId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static loyaltyBenefitsControllerSetMerchantManualLoyaltyStatusOverrideById(
        loyaltyBenefitId: string,
        loyaltyStatusId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/loyalty-benefits/{loyaltyBenefitId}/merchant-manual-loyalty-status-override/{loyaltyStatusId}',
            path: {
                'loyaltyBenefitId': loyaltyBenefitId,
                'loyaltyStatusId': loyaltyStatusId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Unset merchant manual loyalty status override for loyalty benefit
     * @param loyaltyBenefitId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static loyaltyBenefitsControllerUnsetMerchantManualLoyaltyStatusOverrideById(
        loyaltyBenefitId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/loyalty-benefits/{loyaltyBenefitId}/merchant-manual-loyalty-status-override',
            path: {
                'loyaltyBenefitId': loyaltyBenefitId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Set triggering transaction for loyalty benefit
     * @param loyaltyBenefitId
     * @param transactionId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static loyaltyBenefitsControllerSetTriggeringTransactionById(
        loyaltyBenefitId: string,
        transactionId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/loyalty-benefits/{loyaltyBenefitId}/triggering-transaction/{transactionId}',
            path: {
                'loyaltyBenefitId': loyaltyBenefitId,
                'transactionId': transactionId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Unset triggering transaction for loyalty benefit
     * @param loyaltyBenefitId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static loyaltyBenefitsControllerUnsetTriggeringTransactionById(
        loyaltyBenefitId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/loyalty-benefits/{loyaltyBenefitId}/triggering-transaction',
            path: {
                'loyaltyBenefitId': loyaltyBenefitId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Get active loyaltyBenefits for a given customer with id sent as param
     * @param customerId
     * @param accessToken
     * @returns LoyaltyBenefit Active loyalty benefits found
     * @throws ApiError
     */
    public static loyaltyBenefitsControllerGetActiveLoyaltyBenefitsByCustomerId(
        customerId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<Array<LoyaltyBenefit>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/loyalty-benefits/active-loyalty-benefits-by-customer-id/{customerId}',
            path: {
                'customerId': customerId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get loyaltyBenefits for a given customer with id sent as param
     * @param customerId
     * @param accessToken
     * @returns LoyaltyBenefit Loyalty benefits found
     * @throws ApiError
     */
    public static loyaltyBenefitsControllerGetLoyaltyBenefitsByCustomerId(
        customerId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<Array<LoyaltyBenefit>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/loyalty-benefits/loyalty-benefits-by-customer-id/{customerId}',
            path: {
                'customerId': customerId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

}
