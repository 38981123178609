/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CreateCarouselItemDto } from './CreateCarouselItemDto';
import type { CreateHotBoxByCategoryDto } from './CreateHotBoxByCategoryDto';
import type { CreateHotBoxDto } from './CreateHotBoxDto';

export type UpdateHomePageDto = {
    name?: string;
    description?: string;
    showCarousel?: boolean;
    showHotBoxes?: boolean;
    showHotBoxByCategories?: boolean;
    showFlexSection?: boolean;
    showFooterSection?: boolean;
    bulmaCarouselProperties: {
        initialSlide?: number;
        slidesToScroll?: number;
        slidesToShow?: number;
        navigation?: boolean;
        navigationKeys?: boolean;
        navigationSwipe?: boolean;
        pagination?: boolean;
        loop?: boolean;
        infinite?: boolean;
        effect?: string;
        duration?: number;
        timing?: string;
        autoplay?: boolean;
        autoplaySpeed?: number;
        pauseOnHover?: boolean;
        breakpoints?: string;
    };
    reactResponsiveCarouselProperties: {
        axis?: any;
        autoFocus?: boolean;
        autoPlay?: boolean;
        centerMode?: boolean;
        centerSlidePercentage?: number;
        dynamicHeight?: boolean;
        emulateTouch?: boolean;
        infiniteLoop?: boolean;
        interval?: number;
        labels?: {
            leftArrow?: string;
            rightArrow?: string;
            item?: string;
        };
        selectedItem?: number;
        showArrows?: boolean;
        showStatus?: boolean;
        showIndicators?: boolean;
        showThumbs?: boolean;
        stopOnHover?: boolean;
        swipeable?: boolean;
        swipeScrollTolerance?: number;
        thumbWidth?: number;
        transitionTime?: number;
        useKeyboardArrows?: boolean;
        verticalSwipe?: any;
        width?: any;
    };
    carouselItems?: Array<CreateCarouselItemDto>;
    hotBoxes?: Array<CreateHotBoxDto>;
    hotBoxByCategories?: Array<CreateHotBoxByCategoryDto>;
    flexSections?: Array<string>;
    footerSection?: string;
    is_default?: boolean;
    genderMatch?: UpdateHomePageDto.genderMatch;
    ageRangeMatch?: UpdateHomePageDto.ageRangeMatch;
};

export namespace UpdateHomePageDto {

    export enum genderMatch {
        MALE = 'male',
        FEMALE = 'female',
        _ = '*',
    }

    export enum ageRangeMatch {
        TEENAGER = 'teenager',
        YOUNG_ADULT = 'young adult',
        ADULT = 'adult',
        MIDDLE_AGE = 'middle age',
        GERIATRICS = 'geriatrics',
        TEENAGER_AND_YOUNG_ADULT = 'teenager and young adult',
        MIDDLE_AGE_AND_GERIATRICS = 'middle age and geriatrics',
        _ = '*',
    }


}

