/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { User } from './User';

export type Role = {
    name: string;
    description: string;
    functionalArea: Role.functionalArea;
    users: Array<User>;
    id: number;
    dateCreated?: string;
    dateLastModified?: string;
};

export namespace Role {

    export enum functionalArea {
        HUMAN_RESOURCE = 'Human Resource',
        E_COMMERCE = 'eCommerce',
        LOYALTY_MANAGEMENT_SYSTEM = 'Loyalty Management System',
    }


}

