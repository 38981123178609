/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateRoleDto } from '../models/CreateRoleDto';
import type { CreateRoleDtos } from '../models/CreateRoleDtos';
import type { Role } from '../models/Role';
import type { RolesAndCount } from '../models/RolesAndCount';
import type { UpdateRoleDto } from '../models/UpdateRoleDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

import { generalVersionUrlPrefix } from '../../../components/app.settings';

export class RolesService {

    /**
     * Create a new role
     * @param accessToken
     * @param requestBody
     * @returns Role Role successfully created
     * @throws ApiError
     */
    public static rolesControllerCreate(
        accessToken: string | undefined = undefined,
        requestBody: CreateRoleDto,
    ): CancelablePromise<Role> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/roles',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: likely constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a role. Fully replaces all fields
     * @param accessToken
     * @param requestBody
     * @returns Role Role successfully saved
     * @throws ApiError
     */
    public static rolesControllerSave(
        accessToken: string | undefined = undefined,
        requestBody: Role,
    ): CancelablePromise<Role> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: generalVersionUrlPrefix + '/roles',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all roles that meet the criteria specified in query options, if any.
     * @param accessToken
     * @param findOptions encodeURI(JSON.stringify({select?: string[]; relations?: string[]; skip?: number; take?: number;cache?: boolean; where?: {}[] | {}; order?: {};))
     * @returns RolesAndCount Get all roles that meet the criteria specified in query options, if any. Returns array of items found, and count
     * @throws ApiError
     */
    public static rolesControllerFindAll(
        accessToken: string | undefined = undefined,
        findOptions?: any,
    ): CancelablePromise<RolesAndCount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/roles',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'findOptions': findOptions,
            },
            errors: {
                400: `Bad request: likely incorrect options sent`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create one or more new roles in one go
     * @param accessToken
     * @param requestBody
     * @returns any Roles have been successfully created.
     * @throws ApiError
     */
    public static rolesControllerInsert(
        accessToken: string | undefined = undefined,
        requestBody: CreateRoleDtos,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/roles/insert',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: likely a constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a role. Only the fields sent from client will be updated
     * @param id
     * @param accessToken
     * @param requestBody
     * @returns any Role has been successfully updated.
     * @throws ApiError
     */
    public static rolesControllerUpdate(
        id: number,
        accessToken: string | undefined = undefined,
        requestBody: UpdateRoleDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/roles/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: likely a constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete a role.
     * @param id
     * @param accessToken
     * @returns any Role has been successfully deleted.
     * @throws ApiError
     */
    public static rolesControllerDelete(
        id: number,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/roles/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                400: `Bad request: likely role does not exist`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a role with the id sent as param
     * @param id
     * @param accessToken
     * @returns Role Role found
     * @throws ApiError
     */
    public static rolesControllerFindOne(
        id: number,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<Role> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/roles/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * Assign role with roleId to user with userId.
     * @param roleId
     * @param userId
     * @param accessToken
     * @returns any Role assigned to user successfully.
     * @throws ApiError
     */
    public static rolesControllerAddUserById(
        roleId: string,
        userId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/roles/{roleId}/users/{userId}',
            path: {
                'roleId': roleId,
                'userId': userId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                400: `Bad request: likely, user or role does not exist`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Remove role with roleId from user with userId
     * @param roleId
     * @param userId
     * @param accessToken
     * @returns any Role removed from user successfully.
     * @throws ApiError
     */
    public static rolesControllerRemoveUserById(
        roleId: string,
        userId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/roles/{roleId}/users/{userId}',
            path: {
                'roleId': roleId,
                'userId': userId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                400: `Bad request: likely, user or role does not exist`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Assign multiple users to role with roleId. The query should contain an array of userIds in query key named userid e.g. ?userid=1&userid=2&userid=3...
     * @param roleId
     * @param accessToken
     * @returns any Role assigned to users successfully.
     * @throws ApiError
     */
    public static rolesControllerAddUsersById(
        roleId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/roles/{roleId}/users',
            path: {
                'roleId': roleId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                400: `Bad request: likely, user(s) or role does not exist`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Remove multiple users from role with roleId. The query should contain an array of userIds in query key named userid e.g. ?userid=1&userid=2&userid=3...
     * @param roleId
     * @param accessToken
     * @returns any Role removed from users successfully.
     * @throws ApiError
     */
    public static rolesControllerRemoveUsersById(
        roleId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/roles/{roleId}/users',
            path: {
                'roleId': roleId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                400: `Bad request: likely, users or role does not exist`,
                500: `Internal server error`,
            },
        });
    }

}
