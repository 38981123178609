/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateTokenCreditAndDebitDto = {
    transactionDate?: string;
    type?: UpdateTokenCreditAndDebitDto.type;
    description?: string;
    loyaltyBenefitId?: number;
};

export namespace UpdateTokenCreditAndDebitDto {

    export enum type {
        CREDIT = 'credit',
        DEBIT = 'debit',
    }


}

