/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatePaymentMethodDto } from '../models/CreatePaymentMethodDto';
import type { PaymentMethod } from '../models/PaymentMethod';
import type { PaymentMethodsAndCount } from '../models/PaymentMethodsAndCount';
import type { UpdatePaymentMethodDto } from '../models/UpdatePaymentMethodDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

import { generalVersionUrlPrefix } from '../../../components/app.settings';

export class PaymentMethodsService {

    /**
     * Create a new payment method
     * @param accessToken
     * @param requestBody
     * @returns PaymentMethod :Payment Method created
     * @throws ApiError
     */
    public static paymentMethodsControllerCreate(
        accessToken: string | undefined = undefined,
        requestBody: CreatePaymentMethodDto,
    ): CancelablePromise<PaymentMethod> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/payment-methods',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a paymentMethod. Fully replaces all fields
     * @param accessToken
     * @param requestBody
     * @returns PaymentMethod :Payment Method saved
     * @throws ApiError
     */
    public static paymentMethodsControllerSave(
        accessToken: string | undefined = undefined,
        requestBody: PaymentMethod,
    ): CancelablePromise<PaymentMethod> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: generalVersionUrlPrefix + '/payment-methods',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all paymentMethods that meet the criteria specified in query options, if any.
     * @param accessToken
     * @param findOptions encodeURI(JSON.stringify({select?: string[]; relations?: string[]; skip?: number; take?: number;cache?: boolean; where?: {}[] | {}; order?: {};))
     * @returns PaymentMethodsAndCount Get all payment methods that meet the criteria specified in query options, if any. Returns array of items found, and count
     * @throws ApiError
     */
    public static paymentMethodsControllerFindAll(
        accessToken: string | undefined = undefined,
        findOptions?: any,
    ): CancelablePromise<PaymentMethodsAndCount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/payment-methods',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'findOptions': findOptions,
            },
            errors: {
                400: `Bad request: likely incorrect options sent`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create one or more new payment methods in one go
     * @param accessToken
     * @param requestBody
     * @returns any Payment methods have been successfully created.
     * @throws ApiError
     */
    public static paymentMethodsControllerInsert(
        accessToken: string | undefined = undefined,
        requestBody: Array<string>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/payment-methods/insert',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a payment method. Only the fields sent from client will be updated
     * @param id
     * @param accessToken
     * @param requestBody
     * @returns any PaymentMethod has been successfully updated.
     * @throws ApiError
     */
    public static paymentMethodsControllerUpdate(
        id: string,
        accessToken: string | undefined = undefined,
        requestBody: UpdatePaymentMethodDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/payment-methods/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete a paymentMethod.
     * @param id
     * @param accessToken
     * @returns any PaymentMethod has been successfully deleted.
     * @throws ApiError
     */
    public static paymentMethodsControllerDelete(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/payment-methods/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                400: `Bad request: likely paymentMethod does not exist`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a paymentMethod with the id sent as param
     * @param id
     * @param accessToken
     * @returns PaymentMethod :Payment Method found
     * @throws ApiError
     */
    public static paymentMethodsControllerFindOne(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<PaymentMethod> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/payment-methods/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * Add payment to payment method
     * @param paymentMethodId
     * @param paymentId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static paymentMethodsControllerAddPaymentById(
        paymentMethodId: string,
        paymentId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/payment-methods/{paymentMethodId}/payment/{paymentId}',
            path: {
                'paymentMethodId': paymentMethodId,
                'paymentId': paymentId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Remove payment from payment method
     * @param paymentMethodId
     * @param paymentId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static paymentMethodsControllerRemovePaymentById(
        paymentMethodId: string,
        paymentId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/payment-methods/{paymentMethodId}/payment/{paymentId}',
            path: {
                'paymentMethodId': paymentMethodId,
                'paymentId': paymentId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

}
