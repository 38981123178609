import React, { createContext, useContext, useEffect, useState } from 'react';
import {
    QueryClient, QueryClientProvider,
} from 'react-query';
import { AppStateProviderType } from '../features/auth-and-app-annex/provider/types/app.context.type';
import jwt_decode from "jwt-decode";
// @ts-ignore
import getBrowserFingerprint from 'get-browser-fingerprint';
import { AuthStateProviderType } from '../features/auth-and-app-annex/provider/types/auth.context.type';
import loadable from '@loadable/component';
import { useHistory } from 'react-router-dom';
import { DefaultService } from '../../../../services/openapi';
import { generalVersionUrlPrefix } from '../../../app.settings';
import { TenantInfoAndStatus } from '../../../global/app.interfaces';
import Loading from '../../../pluggable-components/loading/Loading';
import ModalLoading from '../../../pluggable-components/loading/ModalLoading';
import { refreshHandler } from '../../auth/app.handlers';
import { AuthTokenPayload } from '../../auth/interfaces';


// Optionally receive base URL as prop. Particularly useful in the case of SSR mount
type Props = {
    baseUrl?: string
}

//create a context to be used to pass content to subcomponents.
//We are also going to pass dispatch returned by useReducer.
export const AppState = createContext<AppStateProviderType>(null);
export const AuthState = createContext<AuthStateProviderType>(null);

export const useAuthState = () => {
    return useContext(AuthState);
};

export const useAppState = () => {
    return useContext(AppState);
}

const Home: React.FC<Props> = ({ baseUrl }) => {
    // Create a client
    const queryClient = new QueryClient();

    // //Get PublicHome and AdminHome as reloadables

    const PublicHome = loadable(() => import('./PublicHome'), {
        fallback: <Loading />
    });

    const AdminHome = loadable(() => import('./AdminHome'), {
        fallback: <Loading />
    })

    //Prepare for all the truth states to be maintained at this level
    const [tokens, setTokens] = useState<{ access_token: string, refresh_token: string } | null>(null);
    const [authTokenPayload, setAuthTokenPayload] = useState<{ payload: AuthTokenPayload | null, authComplete: boolean }>({ payload: null, authComplete: false });
    const [saveTokens, setSaveTokens] = useState(true); //to be set with a toggle button and info kept in localstorage
    const [clientDeviceFingerPrint, setClientDeviceFingerPrint] = useState<string | null>(null);

    //below will determine if to display Admin group of pages
    const [adminMode, setAdminMode] = useState(false);
    const history = useHistory();

    //pathname below may be influenced by any ssr endpoint.
    const [returnUrl, setReturnUrl] = useState(history.location.pathname);

    //track dark mode
    const [darkMode, setDarkMode] = useState(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? true : false);

    const [modalLoadingIsActive, setModalLoadingIsActive] = useState<"is-active" | null>(null);
    {/* In this home page, we need search bar will be displayed within navigation 
                while the search criteria will be in a side bar. Hence the need to maintain state at this level for search criteria*/}

    const [online, setOnline] = useState<0 | 1>(!navigator.onLine ? 0 : 1); //1 for online and 0 for offline

    const [tenantInfoAndStatus, setTenantInfoAndStatus] = useState<TenantInfoAndStatus | null>(null);

    //Below works for checking if Internet is accessible
    const isOnline = async (): Promise<0 | 1> => {

        //first check if the browser window has connection in the first place
        if (!window.navigator.onLine) return 0;

        //Browser window has connect, next is to check whether network visibility also implies internet access
        // avoid CORS errors with a request to your own origin
        const url = new URL(window.location.origin + generalVersionUrlPrefix + '/echo')

        // random value to prevent cached responses
        url.searchParams.set('rand', String(new Date().getTime()))//date here is to introduce variation in Url to avoid browser caching

        try {
            const response = await fetch(
                url.toString(),
                { method: 'GET' },
            )
            return !response.ok ? 0 : 1; //Return 1 if response is OK
        } catch {
            return 0;
        }
    }

    const getTenantInfoAndStatus = async () => {
        try {
            /*const response = await fetch(`${generalVersionUrlPrefix}/tenant-info-and-status`);
            if (!response.ok) throw new Error(response.statusText);
            const data = await response.json();
            //set in state  */
            const data: TenantInfoAndStatus = await DefaultService.appControllerGetTenantInfoAndStatus();
            document.title = data.name;
            setTenantInfoAndStatus(data);
        } catch (error: any) {
            setTenantInfoAndStatus(null);
        }
    }

    useEffect(() => {
        getTenantInfoAndStatus();
    }, [])

    useEffect(() => {
        const timerId = setInterval(async () => {
            const online: 0 | 1 = await isOnline();
            setOnline(online);
            return (() => clearInterval(timerId))
        }, 30000)//Run every 30 seconds.
    }, [])

    useEffect(() => { //Run this once when the component mounts

        //check if there is a refresh_token saved on client machine. Use it to login
        //This could be done with the remember me consent. Better not to even allow remember me in security sensitive solutions
        const refreshToken = localStorage.getItem('refresh_token');
        const userId = localStorage.getItem('user_id');

        //attempt login with refreshToken if not expired
        if (refreshToken && userId) {

            const decodedRefreshToken: AuthTokenPayload = jwt_decode(refreshToken!);//I ran npm install jwt-decode
            //need to know if token has expired by comparing exp with now. Harmonize both to seconds as token exp is in seconds and getTime is in ms
            const now = (new Date().getTime()) / 1000; //the 1000 is to convert from millisec to sec
            const expirationTime = parseInt(decodedRefreshToken.exp); //exp is in seconds

            //if not expired, act
            if (expirationTime > now) {
                //token has not expired. Login via refresh
                //console.log('not expired')
                const clientDeviceFingerPrint = getBrowserFingerprint({ enableWebgl: true });
                refreshHandler({
                    refreshToken, userId: +userId, clientDeviceFingerPrint,
                    clientLoginInfo: {}
                }, returnUrl, history, setTokens, setAuthTokenPayload, saveTokens, baseUrl!, setModalLoadingIsActive);
                setClientDeviceFingerPrint(clientDeviceFingerPrint);

            } else {
                //even the refresh token itself has expired. Redirect to login page
                setAuthTokenPayload({ payload: null, authComplete: true });
                if (history.location.pathname.includes('/admin')) {
                    history.push(`${baseUrl}/admin/login`);
                } else {
                    history.push(`${baseUrl}/login`);
                }

            }
            //console.log('refresh token and user id in local storage')
        }

    }, []);

    useEffect(() => { //for decision as to whether to show admin navigation bar
        //if in login mode, setAdminMode to previous state.
        if (returnUrl.includes('/admin') || returnUrl.includes('/merchant') || returnUrl.includes('/bank')) {
            setAdminMode(true);
        } else {
            setAdminMode(false);
        }

    }, [])

    //listen for mode change
    useEffect(() => {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
            setDarkMode(event.matches ? true : false)
        });
    }, [])


    return (
        <QueryClientProvider client={queryClient}>
            <div style={{ flex: 1 }}> {/* flex: 1 here to ensure that footer is at the bottom even when main body is scanty. Use this along with style={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }} at the higher order layer in index.tsx or server-side rendering embedd */}

                <AuthState.Provider value={{ returnUrl, tokens, setTokens, clientDeviceFingerPrint, authTokenPayload, setAuthTokenPayload, saveTokens }}>
                    <AppState.Provider value={{ returnUrl, adminMode, setAdminMode, baseUrl, darkMode, online, tenantInfoAndStatus, setModalLoadingIsActive, modalLoadingIsActive }}>
                        <ModalLoading isActive={modalLoadingIsActive} />
                        {adminMode && <AdminHome />}

                        {!adminMode && <PublicHome />}

                    </AppState.Provider>
                </AuthState.Provider>

            </div>
        </QueryClientProvider>
    )
}

export default Home;

Home.defaultProps = {
    baseUrl: ''
}