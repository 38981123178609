/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GeneralUploadSearchBody = {
    id: number;
    name: string;
    description: string;
    tags: Array<string>;
    isPublished: boolean;
    category?: GeneralUploadSearchBody.category;
    tenantUniquePrefix: string;
    generalInfo?: string;
    suggestGeneralInfo: any;
};

export namespace GeneralUploadSearchBody {

    export enum category {
        HOME_PAGE = 'home page',
        PRODUCT_CATEGORY = 'product category',
        GENERIC = 'generic',
        MISCELLANEOUS = 'miscellaneous',
    }


}

