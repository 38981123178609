/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateHotBoxByCategoryDto } from '../models/CreateHotBoxByCategoryDto';
import type { HotBoxByCategoriesAndCount } from '../models/HotBoxByCategoriesAndCount';
import type { HotBoxByCategory } from '../models/HotBoxByCategory';
import type { UpdateHotBoxByCategoryDto } from '../models/UpdateHotBoxByCategoryDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

import { generalVersionUrlPrefix } from '../../../components/app.settings';

export class HotBoxByCategoriesService {

    /**
     * Post a hot box by category
     * @param accessToken
     * @param requestBody
     * @returns HotBoxByCategory :Hotbox by category created
     * @throws ApiError
     */
    public static hotBoxByCategoriesControllerCreate(
        accessToken: string | undefined = undefined,
        requestBody: CreateHotBoxByCategoryDto,
    ): CancelablePromise<HotBoxByCategory> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/hot-box-by-categories',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get all hot box by categories
     * @param accessToken
     * @param findOptions encodeURI(JSON.stringify({select?: string[]; relations?: string[]; skip?: number; take?: number;cache?: boolean; where?: {}[] | {}; order?: {};}))
     * @returns HotBoxByCategoriesAndCount Get all hot box by categories that meet the criteria specified in query options, if any. Returns array of items found, and count
     * @throws ApiError
     */
    public static hotBoxByCategoriesControllerFindAll(
        accessToken: string | undefined = undefined,
        findOptions?: any,
    ): CancelablePromise<HotBoxByCategoriesAndCount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/hot-box-by-categories',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'findOptions': findOptions,
            },
        });
    }

    /**
     * Get one hot box by category
     * @param id
     * @param accessToken
     * @returns HotBoxByCategory :Hotbox by category found
     * @throws ApiError
     */
    public static hotBoxByCategoriesControllerFindOne(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<HotBoxByCategory> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/hot-box-by-categories/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Update a hot box by category
     * @param id
     * @param accessToken
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static hotBoxByCategoriesControllerUpdate(
        id: string,
        accessToken: string | undefined = undefined,
        requestBody: UpdateHotBoxByCategoryDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: generalVersionUrlPrefix + '/hot-box-by-categories/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete a hot box by category
     * @param id
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static hotBoxByCategoriesControllerDelete(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/hot-box-by-categories/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

}
