/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateCustomerIssueDto } from '../models/CreateCustomerIssueDto';
import type { CustomerIssue } from '../models/CustomerIssue';
import type { CustomerIssuesAndCount } from '../models/CustomerIssuesAndCount';
import type { CustomerIssueSearchResult } from '../models/CustomerIssueSearchResult';
import type { CustomerIssueSearchResultWithEntity } from '../models/CustomerIssueSearchResultWithEntity';
import type { Suggestion } from '../models/Suggestion';
import type { UpdateCustomerIssueDto } from '../models/UpdateCustomerIssueDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

import { generalVersionUrlPrefix } from '../../../components/app.settings';

export class CustomerIssuesService {

    /**
     * Create a new customer issue. Issue will immediately be associated with customer or product if their respective ids and included in the dto
     * @param accessToken
     * @param requestBody
     * @returns CustomerIssue :Customer issue created
     * @throws ApiError
     */
    public static customerIssuesControllerCreate(
        accessToken: string | undefined = undefined,
        requestBody: CreateCustomerIssueDto,
    ): CancelablePromise<CustomerIssue> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/customer-issues',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get all customer issues that meet the criteria specified in query options, if any.
     * @param accessToken
     * @param findOptions encodeURI(JSON.stringify({select?: string[]; relations?: string[]; skip?: number; take?: number;cache?: boolean; where?: {}[] | {}; order?: {};))
     * @returns CustomerIssuesAndCount Get all customer issue that meet the criteria specified in query options, if any. Returns array of customer issues found, and count
     * @throws ApiError
     */
    public static customerIssuesControllerFindAll(
        accessToken: string | undefined = undefined,
        findOptions?: any,
    ): CancelablePromise<CustomerIssuesAndCount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/customer-issues',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'findOptions': findOptions,
            },
            errors: {
                400: `Bad request: likely incorrect options sent`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Find a customer issue by id
     * @param id
     * @param accessToken
     * @returns CustomerIssue :Customer issue found
     * @throws ApiError
     */
    public static customerIssuesControllerFindOne(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<CustomerIssue> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/customer-issues/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Update a customer issue by id
     * @param id
     * @param accessToken
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static customerIssuesControllerUpdate(
        id: string,
        accessToken: string | undefined = undefined,
        requestBody: UpdateCustomerIssueDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: generalVersionUrlPrefix + '/customer-issues/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete a customer issue by id
     * @param id
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static customerIssuesControllerDelete(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/customer-issues/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Set the customer for a customer issue
     * @param customerIssueId
     * @param customerId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static customerIssuesControllerSetCustomerById(
        customerIssueId: string,
        customerId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/customer-issues/{customerIssueId}/customer/{customerId}',
            path: {
                'customerIssueId': customerIssueId,
                'customerId': customerId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Unset a customer for a customer issue
     * @param customerIssueId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static customerIssuesControllerUnsetCustomerById(
        customerIssueId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/customer-issues/{customerIssueId}/customer',
            path: {
                'customerIssueId': customerIssueId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * work on search
     * Search for customerIssues and return found customerIssue objects
     * @param accessToken
     * @param searchString
     * @returns CustomerIssueSearchResultWithEntity :Customer Issues found
     * @throws ApiError
     */
    public static customerIssuesControllerSearchAndGetCustomerIssues(
        accessToken: string | undefined = undefined,
        searchString: any,
    ): CancelablePromise<CustomerIssueSearchResultWithEntity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/customer-issues/search-and-get-customer-issues',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'search-string': searchString,
            },
        });
    }

    /**
     * Search for customerIssues and return the search hits with minimal info sufficient to differentiate the search results. The client can fetch more details afterwards leveraging on the id field.
     * @param accessToken
     * @param searchString
     * @returns CustomerIssueSearchResult :Customer Issues found
     * @throws ApiError
     */
    public static customerIssuesControllerSearch(
        accessToken: string | undefined = undefined,
        searchString: any,
    ): CancelablePromise<CustomerIssueSearchResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/customer-issues/search',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'search-string': searchString,
            },
        });
    }

    /**
     * Search for customerIssues and return found customerIssue objects
     * @param operator and | or. Defaults to and
     * @param accessToken
     * @param categoryNames
     * @param searchString
     * @param issuesUserType customer, general user, merchant, ... Defaults to customer
     * @param searchAfter search-after=34242&search-after=4
     * @param sortOrder asc or desc
     * @param sortBy Sort by relevance, date, etc. Select from option
     * @param resultsPerPage Number of results per page
     * @returns CustomerIssueSearchResultWithEntity :Customer Issues found by category
     * @throws ApiError
     */
    public static customerIssuesControllerSearchByCategoryAndGetCustomerIssues(
        operator: any,
        accessToken: string | undefined = undefined,
        categoryNames: any,
        searchString: any,
        issuesUserType?: 'customer' | 'merchant' | 'bank' | 'others',
        searchAfter?: any,
        sortOrder?: 'asc' | 'desc',
        sortBy?: '_score' | 'date',
        resultsPerPage?: any,
    ): CancelablePromise<CustomerIssueSearchResultWithEntity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/customer-issues/search-by-category-and-get-customer-issues',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'operator': operator,
                'issues-user-type': issuesUserType,
                'search-after': searchAfter,
                'sort-order': sortOrder,
                'sort-by': sortBy,
                'results-per-page': resultsPerPage,
                'category-names': categoryNames,
                'search-string': searchString,
            },
        });
    }

    /**
     * Search for customerIssues by category and return the search hits with minimal info sufficient to differentiate the search results. The client can fetch more details afterwards leveraging on the id field.
     * @param operator and | or. Defaults to and
     * @param accessToken
     * @param categoryNames One or more category names separated by space
     * @param searchString
     * @param issuesUserType customer, general user, merchant, ... Defaults to customer
     * @param searchAfter search-after=34242&search-after=4
     * @param sortOrder asc or desc
     * @param sortBy Sort by relevance, date, etc. Select from option
     * @param resultsPerPage Number of results per page
     * @returns CustomerIssueSearchResult :Customer Issues found
     * @throws ApiError
     */
    public static customerIssuesControllerSearchByCategory(
        operator: any,
        accessToken: string | undefined = undefined,
        categoryNames: any,
        searchString: any,
        issuesUserType?: 'customer' | 'merchant' | 'bank' | 'others',
        searchAfter?: any,
        sortOrder?: 'asc' | 'desc',
        sortBy?: '_score' | 'date',
        resultsPerPage?: any,
    ): CancelablePromise<CustomerIssueSearchResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/customer-issues/search-by-category',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'operator': operator,
                'issues-user-type': issuesUserType,
                'search-after': searchAfter,
                'sort-order': sortOrder,
                'sort-by': sortBy,
                'results-per-page': resultsPerPage,
                'category-names': categoryNames,
                'search-string': searchString,
            },
        });
    }

    /**
     * @param operator and | or. Defaults to and
     * @param accessToken
     * @param merchantAffiliateId id of merchant to whom the customer is affiliated, if any
     * @param categoryNames One or more category names separated by space
     * @param searchString
     * @param issuesUserType customer, general user, merchant, ... Defaults to customer
     * @param searchAfter search-after=34242&search-after=4
     * @param sortOrder asc or desc
     * @param sortBy Sort by relevance, date, etc. Select from option
     * @param resultsPerPage Number of results per page
     * @returns CustomerIssueSearchResult :Customer Issues found
     * @throws ApiError
     */
    public static customerIssuesControllerSearchByCategoryAndMerchantAffiliateId(
        operator: any,
        accessToken: string | undefined = undefined,
        merchantAffiliateId: any,
        categoryNames: any,
        searchString: any,
        issuesUserType?: 'customer' | 'merchant' | 'bank' | 'others',
        searchAfter?: any,
        sortOrder?: 'asc' | 'desc',
        sortBy?: '_score' | 'date',
        resultsPerPage?: any,
    ): CancelablePromise<CustomerIssueSearchResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/customer-issues/search-by-category-and-merchant-affiliate-id',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'operator': operator,
                'issues-user-type': issuesUserType,
                'search-after': searchAfter,
                'sort-order': sortOrder,
                'sort-by': sortBy,
                'results-per-page': resultsPerPage,
                'merchant-affiliate-id': merchantAffiliateId,
                'category-names': categoryNames,
                'search-string': searchString,
            },
        });
    }

    /**
     * Run query against index optimised for autosuggest.
     * @param accessToken
     * @param searchString
     * @returns Suggestion
     * @throws ApiError
     */
    public static customerIssuesControllerSuggest(
        accessToken: string | undefined = undefined,
        searchString: any,
    ): CancelablePromise<Array<Suggestion>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/customer-issues/suggest',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'search-string': searchString,
            },
        });
    }

    /**
     * Run query by category against index optimised for autosuggest.
     * @param accessToken
     * @param operator and | or. Defaults to and
     * @param categoryNames One or more category names separated by space
     * @param searchString
     * @returns Suggestion :Customer Issues suggestions
     * @throws ApiError
     */
    public static customerIssuesControllerSuggestByCategory(
        accessToken: string | undefined = undefined,
        operator: any,
        categoryNames: any,
        searchString: any,
    ): CancelablePromise<Array<Suggestion>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/customer-issues/suggest-by-category',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'operator': operator,
                'category-names': categoryNames,
                'search-string': searchString,
            },
        });
    }

    /**
     * Run query by category against index optimised for autosuggest.
     * @param accessToken
     * @param operator and | or. Defaults to and
     * @param merchantAffiliateId id of merchant to whom the customer is affiliated, if any
     * @param categoryNames One or more category names separated by space
     * @param searchString
     * @returns Suggestion :Customer Issues suggestions
     * @throws ApiError
     */
    public static customerIssuesControllerSuggestByCategoryAndMerchantAffiliateId(
        accessToken: string | undefined = undefined,
        operator: any,
        merchantAffiliateId: any,
        categoryNames: any,
        searchString: any,
    ): CancelablePromise<Array<Suggestion>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/customer-issues/suggest-by-category-and-merchant-affiliate-id',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'operator': operator,
                'merchant-affiliate-id': merchantAffiliateId,
                'category-names': categoryNames,
                'search-string': searchString,
            },
        });
    }

}
