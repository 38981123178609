/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateShipmentDto } from '../models/CreateShipmentDto';
import type { Shipment } from '../models/Shipment';
import type { ShipmentsAndCount } from '../models/ShipmentsAndCount';
import type { UpdateShipmentDto } from '../models/UpdateShipmentDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

import { generalVersionUrlPrefix } from '../../../components/app.settings';

export class ShipmentsService {

    /**
     * Create a new shipment
     * @param accessToken
     * @param requestBody
     * @returns Shipment :Shipment created
     * @throws ApiError
     */
    public static shipmentsControllerCreate(
        accessToken: string | undefined = undefined,
        requestBody: CreateShipmentDto,
    ): CancelablePromise<Shipment> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/shipments',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a shipment. Fully replaces all fields
     * @param accessToken
     * @param requestBody
     * @returns Shipment :Shipment saved
     * @throws ApiError
     */
    public static shipmentsControllerSave(
        accessToken: string | undefined = undefined,
        requestBody: Shipment,
    ): CancelablePromise<Shipment> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: generalVersionUrlPrefix + '/shipments',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all shipments that meet the criteria specified in query options, if any.
     * @param accessToken
     * @param findOptions encodeURI(JSON.stringify({select?: string[]; relations?: string[]; skip?: number; take?: number;cache?: boolean; where?: {}[] | {}; order?: {};))
     * @returns ShipmentsAndCount Get all shipments that meet the criteria specified in query options, if any. Returns array of items found, and count
     * @throws ApiError
     */
    public static shipmentsControllerFindAll(
        accessToken: string | undefined = undefined,
        findOptions?: any,
    ): CancelablePromise<ShipmentsAndCount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/shipments',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'findOptions': findOptions,
            },
            errors: {
                400: `Bad request: likely incorrect options sent`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create one or more new shipments in one go
     * @param accessToken
     * @param requestBody
     * @returns any Shipments have been successfully created.
     * @throws ApiError
     */
    public static shipmentsControllerInsert(
        accessToken: string | undefined = undefined,
        requestBody: Array<string>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/shipments/insert',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a shipment. Only the fields sent from client will be updated
     * @param id
     * @param accessToken
     * @param requestBody
     * @returns any Shipment has been successfully updated.
     * @throws ApiError
     */
    public static shipmentsControllerUpdate(
        id: string,
        accessToken: string | undefined = undefined,
        requestBody: UpdateShipmentDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/shipments/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete a shipment.
     * @param id
     * @param accessToken
     * @returns any Shipment has been successfully deleted.
     * @throws ApiError
     */
    public static shipmentsControllerDelete(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/shipments/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                400: `Bad request: likely shipment does not exist`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a shipment with the id sent as param
     * @param id
     * @param accessToken
     * @returns Shipment :Shipment found
     * @throws ApiError
     */
    public static shipmentsControllerFindOne(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<Shipment> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/shipments/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * Set shipper for shipment
     * @param shipmentId
     * @param shipperId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static shipmentsControllerSetShipperById(
        shipmentId: string,
        shipperId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/shipments/{shipmentId}/shipper/{shipperId}',
            path: {
                'shipmentId': shipmentId,
                'shipperId': shipperId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Unset shipper for shipment
     * @param shipmentId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static shipmentsControllerUnsetShipperById(
        shipmentId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/shipments/{shipmentId}/shipper',
            path: {
                'shipmentId': shipmentId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Add order detail to shipment
     * @param shipmentId
     * @param orderDetailId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static shipmentsControllerAddOrderDetailById(
        shipmentId: string,
        orderDetailId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/shipments/{shipmentId}/order-detail/{orderDetailId}',
            path: {
                'shipmentId': shipmentId,
                'orderDetailId': orderDetailId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Remove order detail from shipment
     * @param shipmentId
     * @param orderDetailId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static shipmentsControllerRemoveOrderDetailById(
        shipmentId: string,
        orderDetailId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/shipments/{shipmentId}/order-detail/{orderDetailId}',
            path: {
                'shipmentId': shipmentId,
                'orderDetailId': orderDetailId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

}
