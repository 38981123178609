/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Product } from './Product';

export type ProductCategory = {
    name: string;
    description: string;
    photo: string;
    photoMimeType: string;
    isApproved: boolean;
    approvedBy: number;
    natureOfCategory: ProductCategory.natureOfCategory;
    isLocked: boolean;
    parentCategory: ProductCategory;
    childCategories: Array<ProductCategory>;
    products: Array<Product>;
    id: number;
    dateCreated?: string;
    dateLastModified?: string;
};

export namespace ProductCategory {

    export enum natureOfCategory {
        SIZE = 'size',
        BRAND = 'brand',
        GENDER = 'gender',
        AGE_GROUP = 'age group',
        MACHINE = 'machine',
        PARTS = 'parts',
        ORGANIC = 'organic',
        DIGITAL = 'digital',
        MEDICAL = 'medical',
        GENERIC = 'generic',
    }


}

