/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateTaxPaymentDto = {
    /**
     * Amount paid
     */
    amount: number;
    /**
     * Currency enum.
     */
    currency: CreateTaxPaymentDto.currency;
    orderId?: number;
    taxRegionId?: number;
    /**
     * Defaults to false
     */
    remitted?: boolean;
    dateRemitted?: string;
    remittanceReference?: string;
};

export namespace CreateTaxPaymentDto {

    /**
     * Currency enum.
     */
    export enum currency {
        NAIRA = 'Naira',
        US_DOLLAR = 'US Dollar',
        GREAT_BRITAIN_POUND = 'Great Britain Pound',
    }


}

