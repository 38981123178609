/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateCustomerIssueDto = {
    raisedBy?: string;
    title: string;
    description: string;
    category?: CreateCustomerIssueDto.category;
    priority?: CreateCustomerIssueDto.priority;
    assignedTo?: string;
    status?: CreateCustomerIssueDto.status;
    customerId?: number;
    userType?: CreateCustomerIssueDto.userType;
};

export namespace CreateCustomerIssueDto {

    export enum category {
        GENERAL = 'general',
        PRODUCT = 'product',
        PAYMENT = 'payment',
    }

    export enum priority {
        HIGH = 'high',
        MEDIUM = 'medium',
        LOW = 'low',
    }

    export enum status {
        OPEN = 'open',
        CLOSED = 'closed',
    }

    export enum userType {
        CUSTOMER = 'customer',
        MERCHANT = 'merchant',
        BANK = 'bank',
        OTHERS = 'others',
    }


}

