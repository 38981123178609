/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Coupon } from '../models/Coupon';
import type { CouponsAndCount } from '../models/CouponsAndCount';
import type { CreateCouponDto } from '../models/CreateCouponDto';
import type { UpdateCouponDto } from '../models/UpdateCouponDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

import { generalVersionUrlPrefix } from '../../../components/app.settings';

export class CouponsService {

    /**
     * Create one or more new coupons
     * @param accessToken
     * @param requestBody
     * @returns Coupon Coupons created
     * @throws ApiError
     */
    public static couponsControllerCreate(
        accessToken: string | undefined = undefined,
        requestBody: CreateCouponDto,
    ): CancelablePromise<Array<Coupon>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/coupons',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a coupon. Fully replaces all fields
     * @param accessToken
     * @param requestBody
     * @returns Coupon Coupon saved
     * @throws ApiError
     */
    public static couponsControllerSave(
        accessToken: string | undefined = undefined,
        requestBody: Coupon,
    ): CancelablePromise<Coupon> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: generalVersionUrlPrefix + '/coupons',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all coupons that meet the criteria specified in query options, if any.
     * @param accessToken
     * @param findOptions encodeURI(JSON.stringify({select?: string[]; relations?: string[]; skip?: number; take?: number;cache?: boolean; where?: {}[] | {}; order?: {};}))
     * @returns CouponsAndCount Get all coupons that meet the criteria specified in query options, if any. Returns array of items found, and count
     * @throws ApiError
     */
    public static couponsControllerFindAll(
        accessToken: string | undefined = undefined,
        findOptions?: any,
    ): CancelablePromise<CouponsAndCount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/coupons',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'findOptions': findOptions,
            },
            errors: {
                400: `Bad request: likely incorrect options sent`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create one or more new coupons
     * @param accessToken
     * @param requestBody
     * @returns Coupon Coupons created
     * @throws ApiError
     */
    public static couponsControllerCreateByMerchant(
        accessToken: string | undefined = undefined,
        requestBody: CreateCouponDto,
    ): CancelablePromise<Array<Coupon>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/coupons/create-by-merchant',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create one or more new coupons in one go
     * @param accessToken
     * @param requestBody
     * @returns any Coupons have been successfully created.
     * @throws ApiError
     */
    public static couponsControllerInsert(
        accessToken: string | undefined = undefined,
        requestBody: Array<string>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/coupons/insert',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a coupon. Only the fields sent from client will be updated
     * @param id
     * @param accessToken
     * @param requestBody
     * @returns any Coupon has been successfully updated.
     * @throws ApiError
     */
    public static couponsControllerUpdate(
        id: string,
        accessToken: string | undefined = undefined,
        requestBody: UpdateCouponDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/coupons/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request: constraint problem`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete a coupon.
     * @param id
     * @param accessToken
     * @returns any Coupon has been successfully deleted.
     * @throws ApiError
     */
    public static couponsControllerDelete(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/coupons/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                400: `Bad request: likely coupon does not exist`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a coupon with the id sent as param
     * @param id
     * @param accessToken
     * @returns Coupon Coupon found
     * @throws ApiError
     */
    public static couponsControllerFindOne(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<Coupon> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/coupons/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get unused coupons for a given customer with id sent as param
     * @param customerId
     * @param accessToken
     * @returns Coupon Unused coupons found
     * @throws ApiError
     */
    public static couponsControllerGetUnusedCouponsByCustomerId(
        customerId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<Array<Coupon>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/coupons/get-unused-coupons-by-customer-id/{customerId}',
            path: {
                'customerId': customerId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get coupons for a given customer with id sent as param
     * @param customerId
     * @param accessToken
     * @returns Coupon Coupons found
     * @throws ApiError
     */
    public static couponsControllerGetCouponsByCustomerId(
        customerId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<Array<Coupon>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/coupons/get-coupons-by-customer-id/{customerId}',
            path: {
                'customerId': customerId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get unused coupons by code sent as param
     * @param code
     * @param accessToken
     * @returns Coupon Unused coupons found
     * @throws ApiError
     */
    public static couponsControllerGetUnusedCouponByCode(
        code: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<Coupon> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/coupons/get-unused-coupons-by-code/{code}',
            path: {
                'code': code,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get unused coupons for a given merchant with id sent as param
     * @param merchantId
     * @param accessToken
     * @returns Coupon Unused coupons found
     * @throws ApiError
     */
    public static couponsControllerGetUnusedCouponsByMerchantId(
        merchantId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<Array<Coupon>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/coupons/get-unused-coupons-by-merchant-id/{merchantId}',
            path: {
                'merchantId': merchantId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get coupons for a given merchant with id sent as param
     * @param merchantId
     * @param accessToken
     * @returns Coupon Coupons found
     * @throws ApiError
     */
    public static couponsControllerGetCouponsByMerchantId(
        merchantId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<Array<Coupon>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/coupons/get-coupons-by-merchant-id/{merchantId}',
            path: {
                'merchantId': merchantId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get unused coupons for a given loyalty benefit with id sent as param
     * @param loyaltyBenefitId
     * @param accessToken
     * @returns Coupon Unused coupons found
     * @throws ApiError
     */
    public static couponsControllerGetUnusedCouponsByLoyaltyBenefitId(
        loyaltyBenefitId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<Array<Coupon>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/coupons/get-unused-coupons-by-loyalty-benefit-id/{loyaltyBenefitId}',
            path: {
                'loyaltyBenefitId': loyaltyBenefitId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get coupons for a given loyalty benefit with id sent as param
     * @param loyaltyBenefitId
     * @param accessToken
     * @returns Coupon Coupons found
     * @throws ApiError
     */
    public static couponsControllerGetCouponsByLoyaltyBenefitId(
        loyaltyBenefitId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<Array<Coupon>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/coupons/get-coupons-by-loyalty-benefit-id/{loyaltyBenefitId}',
            path: {
                'loyaltyBenefitId': loyaltyBenefitId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

    /**
     * Set Loyalty benefit by id
     * @param couponId
     * @param loyaltyBenefitId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static couponsControllerSetloyaltyBenefitById(
        couponId: string,
        loyaltyBenefitId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/coupons/{couponId}/set-loyalty-benefit-by-id/{loyaltyBenefitId}',
            path: {
                'couponId': couponId,
                'loyaltyBenefitId': loyaltyBenefitId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Unset Loyalty benefit by id
     * @param couponId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static couponsControllerUnsetloyaltyBenefitById(
        couponId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/coupons/{couponId}/unset-loyalty-benefit',
            path: {
                'couponId': couponId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Set issue to customer by id
     * @param couponId
     * @param customerId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static couponsControllerSetIssuedToById(
        couponId: string,
        customerId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/coupons/{couponId}/set-issue-to-customer-by-id/{customerId}',
            path: {
                'couponId': couponId,
                'customerId': customerId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Unset issued to customer by id
     * @param couponId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static couponsControllerUnsetIssuedToById(
        couponId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/coupons/{couponId}/unset-issue-to-customer',
            path: {
                'couponId': couponId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Set creating merchant by Id
     * @param couponId
     * @param merchantId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static couponsControllerSetCreatingMerchantById(
        couponId: string,
        merchantId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/coupons/{couponId}/set-creating-merchant-by-id/{merchantId}',
            path: {
                'couponId': couponId,
                'merchantId': merchantId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Unset creating merchant by id
     * @param couponId
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static couponsControllerUnsetCreatingMerchantById(
        couponId: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/coupons/{couponId}/unset-creating-merchant',
            path: {
                'couponId': couponId,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

}
