/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateHotBoxDto } from '../models/CreateHotBoxDto';
import type { HotBox } from '../models/HotBox';
import type { HotBoxesAndCount } from '../models/HotBoxesAndCount';
import type { UpdateHotBoxDto } from '../models/UpdateHotBoxDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

import { generalVersionUrlPrefix } from '../../../components/app.settings';

export class HotBoxesService {

    /**
     * Post a hot box
     * @param accessToken
     * @param requestBody
     * @returns HotBox :Hotbox  created
     * @throws ApiError
     */
    public static hotBoxesControllerCreate(
        accessToken: string | undefined = undefined,
        requestBody: CreateHotBoxDto,
    ): CancelablePromise<HotBox> {
        return __request(OpenAPI, {
            method: 'POST',
            url: generalVersionUrlPrefix + '/hot-boxes',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get all hot boxes
     * @param accessToken
     * @param findOptions encodeURI(JSON.stringify({select?: string[]; relations?: string[]; skip?: number; take?: number;cache?: boolean; where?: {}[] | {}; order?: {};}))
     * @returns HotBoxesAndCount Get all hot boxes that meet the criteria specified in query options, if any. Returns array of items found, and count
     * @throws ApiError
     */
    public static hotBoxesControllerFindAll(
        accessToken: string | undefined = undefined,
        findOptions?: any,
    ): CancelablePromise<HotBoxesAndCount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/hot-boxes',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            query: {
                'findOptions': findOptions,
            },
        });
    }

    /**
     * Get one hot box
     * @param id
     * @param accessToken
     * @returns HotBox :Hotbox found
     * @throws ApiError
     */
    public static hotBoxesControllerFindOne(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<HotBox> {
        return __request(OpenAPI, {
            method: 'GET',
            url: generalVersionUrlPrefix + '/hot-boxes/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

    /**
     * Update a hot box
     * @param id
     * @param accessToken
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static hotBoxesControllerUpdate(
        id: string,
        accessToken: string | undefined = undefined,
        requestBody: UpdateHotBoxDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: generalVersionUrlPrefix + '/hot-boxes/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete a hot box
     * @param id
     * @param accessToken
     * @returns any
     * @throws ApiError
     */
    public static hotBoxesControllerDelete(
        id: string,
        accessToken: string | undefined = undefined,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: generalVersionUrlPrefix + '/hot-boxes/{id}',
            path: {
                'id': id,
                },
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        });
    }

}
